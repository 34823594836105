import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import queryKeys from 'src/common/queryKeys';

import stateCreator from '../data/stateCreator';
import useCustomerCourses from './useCustomerCourses';
import {
  fetchCustomers
} from '../../api';

const useInstructors = () => {
  const { id } = useParams();
  const customerCourses = useCustomerCourses();

  const [queryParams, setQueryParams] = useState(stateCreator.createEmptyQuery());

  const fetchCustomersQuery = useQuery([queryKeys.FETCH_CUSTOMERS, queryParams], () => fetchCustomers(queryParams));

  return {
    isNewRecord: !id,
    dataFetching: {
      fetchCustomersQuery,
      queryParams,
      setQueryParams
    },
    customerCourses
  };
};

export default useInstructors;
