import CourseForm from './ui/pages/CourseForm/ui';
import CoursesList from './ui/pages/CoursesList/ui';

import HRCoursesRoutes from './ui/pages/HRCourses/ui';

const coursesRoutes = [
  { path: '/courses', element: <CoursesList /> },
  { path: '/courses/form', element: <CourseForm /> },
  { path: '/courses/form/:id', element: <CourseForm /> },
  ...HRCoursesRoutes
];

export default coursesRoutes;
