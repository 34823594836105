export const promoCodeTypes = {
  ALL: 'all',
  INSTRUCTOR_SPECIFIC: 'instructor-specific',
  COURSE_SPECIFIC: 'course-specific'
};

export const promoCodeTypesList = [
  {
    id: promoCodeTypes.ALL,
    name: promoCodeTypes.ALL
  },
  {
    id: promoCodeTypes.INSTRUCTOR_SPECIFIC,
    name: promoCodeTypes.INSTRUCTOR_SPECIFIC
  },
  {
    id: promoCodeTypes.COURSE_SPECIFIC,
    name: promoCodeTypes.COURSE_SPECIFIC
  }
];
