import { useContext } from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { BlogsContext } from 'src/views/Blogs/common/context';
import BlogsListToolBar from '../blocks/BlogsListToolBar';
import BlogsListResults from '../blocks/BlogsListResults';

const BlogsListBody = ({ isLoading }) => {
  const {
    dataFetching: { fetchAllBlogsQuery }
  } = useContext(BlogsContext);

  return (
    <View isLoading={fetchAllBlogsQuery.isLoading || isLoading}>
      <BlogsListToolBar />
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={null}>
          <BlogsListResults data={fetchAllBlogsQuery.data} />
        </ErrorBoundary>
      </Box>
    </View>
  );
};

BlogsListBody.defaultProps = {
  isLoading: false
};

BlogsListBody.propTypes = {
  isLoading: pt.bool
};

export default BlogsListBody;
