import pt from 'prop-types';

import Body from 'src/views/Courses/ui/partials/Body';

import { HRCoursesProvider } from '../../../common/context';

const HRCoursesBody = ({ title, children }) => {
  return (
    <Body title={title}>
      <HRCoursesProvider>
        {children}
      </HRCoursesProvider>
    </Body>
  );
};

HRCoursesBody.defaultProps = {
  title: ''
};

HRCoursesBody.propTypes = {
  title: pt.string.isRequired
};

export default HRCoursesBody;
