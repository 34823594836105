import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';

export const fetchHRCoursesFlow = (queryParams) =>
  axiosInstance.get(apiRoutes.FETCH_HR_COURSES_FLOW(), {params: queryParams}).then(res => res.data);

export const fetchHRCourseDetail = (id) =>
  axiosInstance.get(apiRoutes.FETCH_COURSE_DETAIL(id)).then(res => res.data);

export const fetchHRCourseUsersFlow = (id,limit=10,page=1) =>
  axiosInstance.get(apiRoutes.FETCH_HR_COURSE_USERS(id,limit=10,page=1)).then(res => res.data);
