import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import axiosInstance from 'src/common/config/api';
import Snackbar from '@mui/material/Snackbar';
import InfluencerAdd from './InfluencerAdd';
import IconButton from '@material-ui/core/IconButton';
import {
  Edit as EditIcon,
  Trash as TrashIcon,
  Copy as CopyIcon
} from 'react-feather';
import { Popconfirm, message } from 'antd';
export default function Influencer() {
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState();
  const [addVisible, setAddVisible] = useState(false);
  const [hrId, setHrId] = useState();
  // const [message, setMessage] = useState();
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  useEffect(() => {
    getInfluencer();
  }, [addVisible]);
  const edit = (item) => {
    console.log(item);
    setEditData(item);
    setAddVisible(true);
  };
  const getInfluencer = () => {
    axiosInstance.get('customers/influencer').then((res) => {
      setData(res?.data?.data);
    });
  };
  const addInfluencer = () => {
    setEditData();
    setAddVisible(true);
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;
  const copyToClipBoard = (item) => {
    let url = `https://safavy.org/influencer?token=${item}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        message.success('İnfluencer url-i kopyalandı: ', url);
      })
      .catch((err) => {
        console.error('Xəta: ', err);
      });
  };
  const deleteHandler = (item) => {
    axiosInstance
      .get('customers/influencer/delete/' + item?.id)
      .then((res) => {
        getInfluencer();
      });
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          marginTop: '3%'
        }}
      >
        <Button style={{ marginLeft: 'auto' }} onClick={addInfluencer}>
          Add Influencer
        </Button>
      </div>
      {addVisible && (
        <InfluencerAdd
          addVisible={addVisible}
          setAddVisible={setAddVisible}
          editData={editData}
          getInfluencer={getInfluencer}
        ></InfluencerAdd>
      )}
      <Box sx={{ minWidth: 1050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ad, Soyad</TableCell>
              <TableCell>Faiz</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Url</TableCell>
              <TableCell>Əməliyyatlar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell>{item?.fullname}</TableCell>

                <TableCell>{item?.percent}</TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell>
                  <a
                    href={'https://safavy.org/influencer?token=' + item?.token}
                  >
                    Influencer url
                  </a>
                </TableCell>
                <TableCell>
                  {/* <Link to={`${privateRoutes.INSTRUCTORS_FORM}/${instructor.id}`}> */}
                  <IconButton aria-label="edit" onClick={() => edit(item)}>
                    <EditIcon strokeWidth={1.5} size={18} />
                  </IconButton>
                  {/* </Link> */}
                  <IconButton
                    // onClick={() => setInstructorForDelete(instructor.id)}
                    // disabled={deleteInstructorMutation.isLoading}
                    aria-label="delete"
                  >
                    <Popconfirm
                      onConfirm={() => deleteHandler(item)}
                      title={`Influencer silinsin?`}
                      okText="Bəli"
                      cancelText="Xeyr"
                      className="ant-icon-background"
                      // disabled={!selectedTreeElement.isLeaf}
                    >
                      <TrashIcon strokeWidth={1.5} size={18} />
                    </Popconfirm>
                  </IconButton>
                  <IconButton
                    onClick={() => copyToClipBoard(item?.token)}
                    // disabled={deleteInstructorMutation.isLoading}
                    aria-label="copy"
                  >
                    <CopyIcon strokeWidth={1.5} size={18} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  );
}
