import { useContext, useEffect } from 'react';
import { Autocomplete, CardHeader, Divider, Grid, TextField } from '@material-ui/core';

import { CoursesContext } from '../../../../../../common/context';
import LearningsBlock from './blocks/LearningsBlock';
import RequirementsBlock from './blocks/RequirementsBlock';
import BenefitsBlock from './blocks/BenefitsBlock';
import SectionsBlock from './blocks/SectionsBlock/SectionsBlock';
import { steps } from '../../../common/constants';

const CourseContentStep = () => {
  const {
    additionallyQueries: { fetchCategories },
    create: { stepsForm, stepsFormErrors, stepsFormController }
  } = useContext(CoursesContext);

  const categoriesProps = {
    options: fetchCategories.data || [],
    getOptionLabel: (option) => option.title,
    value: stepsForm[steps.COURSE_CONTENT].categories,
    onChange: (e, T) => stepsFormController({
      stepName: steps.COURSE_CONTENT,
      fieldObject: {
        fieldName: 'categories',
        fieldValue: T
      }
    })
  };

  const formErrors = stepsFormErrors[steps.COURSE_CONTENT];

  return (
    <>
      <CardHeader subheader='Course contents definition' title='Create course content' />
      <Divider />
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <Autocomplete
            {...categoriesProps}
            debug='true'
            fullWidth
            multiple
            loading={fetchCategories.isLoading}
            disabled={fetchCategories.isLoading}
            loadingText={'Loading...'}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Choose categories'
                margin='normal'
                error={formErrors.categories.error}
                helperText={formErrors.categories.message}
              />
            )}
          />
        </Grid>

        {/*Learnings Block*/}
        <LearningsBlock />
        {/*/Learnings Block*/}

        {/*Requirements Block*/}
        <RequirementsBlock />
        {/*/Requirements Block*/}

        {/*Benefits Block*/}
        <BenefitsBlock />
        {/*/Benefits Block*/}

        {/*Sections Block*/}
        <SectionsBlock />
        {/* /Sections Block*/}
      </Grid>
    </>
  );
};

export default CourseContentStep;
