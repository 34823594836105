import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';

export const fetchCustomers = (requestData) =>
  axiosInstance.get(apiRoutes.FETCH_CUSTOMERS(), { params: requestData }).then(res => res.data);

export const fetchAllCustomers = () =>
  axiosInstance.get(apiRoutes.FETCH_ALL_CUSTOMERS()).then(res => res.data);

export const fetchCustomerCourses = (id, requestData) =>
  axiosInstance.get(apiRoutes.FETCH_CUSTOMER_COURSES(id), { params: requestData }).then(res => res.data);
