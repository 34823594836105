export default {
  createEmptyQuery: () => ({
    page: 0,
    limit: 10,
    orderBy: 'id',
    order: 'desc'
  }),
  createEmptySocialAccounts: () => ({
    facebook: '',
    twitter: '',
    github: '',
    youtube: '',
    behance: '',
    dribble: '',
    linkedin: '',
    instagram: ''
  })
};
