import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import pt from 'prop-types';
import { Alert, Box, Card, CardContent, Grid } from '@material-ui/core';

import queryKeys from 'src/common/queryKeys';
import View from 'src/components/view';
import WithCondition from 'src/components/WithCondition';

import { fetchAllCoursesTitle } from 'src/views/Courses/common/api';
import { fetchAllCustomers } from 'src/views/Customers/common/api';
import { HRContext } from 'src/views/HR/common/context';

import { HRFormToolBar, HRFormActions, HRFormAssignment, HRFormIntroBlock } from '../blocks';

const HRFormBody = ({ isLoading: isRbacLoading }) => {
  const {
    isNewRecord,
    form: {
      formValues,
      setFormValues,
      formErrors,
      formController
    },
    dataFetching: {
      fetchHRDetailQuery
    },
    create: {
      saveHRHandler,
      createHRMutation
    },
    update: {
      updateHRMutation
    }
  } = useContext(HRContext);

  const fetchCoursesQuery = useQuery({
    queryKey: queryKeys.FETCH_ALL_COURSES_TITLE,
    queryFn: () => fetchAllCoursesTitle()
  });

  const fetchCustomersQuery = useQuery({
    queryKey: queryKeys.FETCH_ALL_CUSTOMERS,
    queryFn: () => fetchAllCustomers()
  });

  const saveHRForm = (operation) => {
    saveHRHandler({ operation, reset: () => null });
  };

  const assignmentBlockIsDisplay =
    formValues.max_user_count > 0 &&
    !!fetchCoursesQuery.data && !!fetchCustomersQuery.data &&
    formValues.courses.length > 0;

  return (
    <View isLoading={fetchHRDetailQuery.isLoading || updateHRMutation.isLoading || isRbacLoading}>
      <HRFormToolBar />
      <Box sx={{ pt: 3 }}>
        <form>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <HRFormIntroBlock
                  isNewRecord={isNewRecord}
                  formController={formController}
                  formValues={formValues}
                  formErrors={formErrors}
                  fetchCoursesQuery={fetchCoursesQuery}
                />
                <Grid
                  item
                  xs={12}
                >
                  <Alert severity='warning'>You can assign courses to students while creating Human Resources! In this time, the
                    number of students to be selected cannot be more than <strong>`Max user count`</strong>!</Alert>
                  <WithCondition isDisplay={formErrors.assignments.error}>
                    <Alert style={{ marginTop: '15px' }} severity='error'>{formErrors.assignments.message}</Alert>
                  </WithCondition>
                </Grid>
                <WithCondition
                  isDisplay={assignmentBlockIsDisplay}>
                  <HRFormAssignment
                    courses={fetchCoursesQuery.data}
                    customers={fetchCustomersQuery.data}
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
                </WithCondition>
                <HRFormActions
                  saveHRForm={saveHRForm}
                  isLoading={createHRMutation.isLoading}
                  isNewRecord={isNewRecord}
                />
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Box>
    </View>
  );
};

HRFormBody.defaultProps = {
  isLoading: false
};

HRFormBody.propTypes = {
  isLoading: pt.bool
};

export default HRFormBody;
