import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { stepNames, steps, stepsIndexes } from '../../../common/constants';

import { useStyles } from './styles';

const CourseFormStepper = ({
                             step,
                             setStep,
                             createCourse,
                             createCourseMutation,
                             stepsFormValidationFactory,
                             stepsForm,
                             children,
                             addCourseLecturesHandler,
                             addCourseLecturesMutation,
                             addCourseQuizzesHandler,
                             addCourseQuizzesMutation,
                             updateCourseInformationMutation
                           }) => {
  const classes = useStyles();

  const handleNext = () => {
    if (!stepsFormValidationFactory()) return false;

    if (step === stepsIndexes.COURSE_CONTENT) {
      const courseCreationSuccessCallback = () => setStep((prevActiveStep) => prevActiveStep + 1);
      createCourse(courseCreationSuccessCallback);
    } else if (step === stepsIndexes.CREATE_VIDEOS) {
      const addCourseLecturesSuccessCallback = () => setStep((prevActiveStep) => prevActiveStep + 1);
      addCourseLecturesHandler(addCourseLecturesSuccessCallback);
    } else if (step === stepsIndexes.ADD_QUIZ) {
      addCourseQuizzesHandler();
    } else {
      setStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setStep(0);
  };

  const stepNameFactory = (step) => {
    switch (step) {
      case stepsIndexes.COURSE_INFORMATION:
        return 'next';
      case stepsIndexes.COURSE_CONTENT:
        return 'Save and continue with videos';
      case stepsIndexes.CREATE_VIDEOS:
        return 'Save and continue with quiz';
      case stepsIndexes.ADD_QUIZ:
        return 'Save and exit';
    }
  };

  const isDisableNextButton = createCourseMutation.isLoading || addCourseQuizzesMutation.isLoading || addCourseLecturesMutation.isLoading || updateCourseInformationMutation.isLoading || (step === stepsIndexes.CREATE_VIDEOS && stepsForm[steps.CREATE_VIDEOS].sections.length === 0) || (step === stepsIndexes.ADD_QUIZ && stepsForm[steps.ADD_QUIZ].quizzes.length === 0);
  const isDisablePrevButton = step === stepsIndexes.COURSE_INFORMATION || (step === stepsIndexes.CREATE_VIDEOS && !!stepsForm[steps.CREATE_VIDEOS].courseId);

  return (
    <div className={classes.root}>
      <Stepper className={classes.stepper} activeStep={step} alternativeLabel>
        {stepNames.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {step === stepNames.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {children}
            <div className={classes.stepperActions}>
              <Button
                disabled={isDisablePrevButton}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={isDisableNextButton}
                onClick={handleNext}
              >
                {stepNameFactory(step)}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseFormStepper;
