import { NavLink } from 'react-router-dom';
import { Grid, TablePagination } from '@material-ui/core';

import WithCondition from 'src/components/WithCondition';
import { privateRoutes } from 'src/common/mainRoutes';

import { HRCourseCard } from 'src/views/Courses/ui/pages/HRCourses/ui/components';

const HRCoursesListResults = ({ data, queryParams, setQueryParams }) => {

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Grid
      container
      spacing={3}
    >
      {
        data.courses.map(course => (
          <Grid
            item
            key={course.id}
            lg={4}
            md={6}
            xs={12}
          >
            <NavLink to={`${privateRoutes.HR_COURSES}/${course.id}`}>
              <HRCourseCard course={course} />
            </NavLink>
          </Grid>
        ))
      }
      <WithCondition isDisplay={!!data?.courses && data?.courses.length > 0}>
        <Grid
          item
          key={'pagination'}
          lg={12}
          md={12}
          xs={12}
        >
          <TablePagination
            component='div'
            page={queryParams.page}
            rowsPerPage={queryParams.limit}
            count={data.count}
            rowsPerPageOptions={[5, 10, 25]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
          />
        </Grid>
      </WithCondition>
    </Grid>
  );
};

export default HRCoursesListResults;
