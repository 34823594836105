import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import FileDownloadIcon from '@material-ui/icons/FileDownload';

import {
  Edit as EditIcon,
  Trash as TrashIcon
} from 'react-feather';


import { privateRoutes } from 'src/common/mainRoutes';
import WithCondition from 'src/components/WithCondition';

import { HRContext } from 'src/views/HR/common/context';
import { DeleteHRModal } from '../../components';

const HRListResults = ({ data = { count: 0, hrList: [] }, ...rest }) => {
  const {
    dataFetching: { queryParams, setQueryParams },
    delete: {
      deleteHRHandler,
      deleteHRMutation
    }
  } = useContext(HRContext);

  const [selectedHRIds, setSelectedHRIds] = useState([]);
  const [HRForDelete, setHRForDelete] = useState(null);

  const handleSelectAll = (event) => {
    let newSelectedHRIds;

    if (event.target.checked) {
      newSelectedHRIds = data.hrList.map(HRItem => HRItem.id);
    } else {
      newSelectedHRIds = [];
    }

    setSelectedHRIds(newSelectedHRIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedHRIds.indexOf(id);
    let newSelectedHRIds = [];

    if (selectedIndex === -1) {
      newSelectedHRIds = newSelectedHRIds.concat(selectedHRIds, id);
    } else if (selectedIndex === 0) {
      newSelectedHRIds = newSelectedHRIds.concat(selectedHRIds.slice(1));
    } else if (selectedIndex === selectedHRIds.length - 1) {
      newSelectedHRIds = newSelectedHRIds.concat(selectedHRIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedHRIds = newSelectedHRIds.concat(
        selectedHRIds.slice(0, selectedIndex),
        selectedHRIds.slice(selectedIndex + 1)
      );
    }

    setSelectedHRIds(newSelectedHRIds);
  };

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedHRIds.length === data.hrList.length}
                    color='primary'
                    indeterminate={
                      selectedHRIds.length > 0
                      && selectedHRIds.length < data.hrList.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Ad
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Telefon
                </TableCell>
                <TableCell>
                  Şirkət (Müəssisə)
                </TableCell>
                <TableCell align='center'>
                  Tələbə sayı
                </TableCell>
                <TableCell>
                  Üzvolma tarixi
                </TableCell>
                <TableCell>
                  Ümumi məbləğ
                </TableCell>
                <TableCell align='center'>
                  Möhür
                </TableCell>
                <TableCell>
                  Əməliyyatlar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.hrList.slice(0, queryParams.limit).map((hr) => (
                <TableRow
                  hover
                  key={hr.id}
                  selected={selectedHRIds.indexOf(hr.id) !== -1}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedHRIds.indexOf(hr.id) !== -1}
                      onChange={(event) => handleSelectOne(event, hr.id)}
                      value='true'
                    />
                  </TableCell>
                  <TableCell>
                    {hr.fullname}
                  </TableCell>
                  <TableCell>
                    {hr.email}
                  </TableCell>
                  <TableCell>
                    {hr.phone_number}
                  </TableCell>
                  <TableCell>
                    {hr.company_name}
                  </TableCell>
                  <TableCell align='center'>
                    <strong>{hr.max_user_count}</strong>
                  </TableCell>
                  <TableCell>
                    {moment(hr.created_at).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {hr.total_payment} AZN
                  </TableCell>
                  <TableCell align='center'>
                    <Button disabled={!hr.signature}>
                      <a style={{ display: 'flex' }} target='_blank' download href={hr.signature}>
                        <FileDownloadIcon />
                      </a>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Link to={`${privateRoutes.HR_FORM}/${hr.id}`}>
                      <IconButton aria-label='edit'>
                        <EditIcon strokeWidth={1.5} size={18} />
                      </IconButton>
                    </Link>
                    <IconButton
                      onClick={() => setHRForDelete(hr.id)}
                      disabled={deleteHRMutation.isLoading}
                      aria-label='delete'
                    >
                      <TrashIcon strokeWidth={1.5} size={18} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <WithCondition isDisplay={!!HRForDelete}>
        <DeleteHRModal
          open={!!HRForDelete}
          closeModal={() => setHRForDelete(null)}
          onDelete={() => deleteHRHandler(HRForDelete)}
        />
      </WithCondition>
    </Card>
  );
};

HRListResults.defaultProps = {
  data: {
    hrList: [],
    count: 0
  }
};

HRListResults.propTypes = {
  data: PropTypes.object.isRequired
};

export default HRListResults;
