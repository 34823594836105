import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import queryKeys from '../queryKeys';
import { publicRoutes } from '../mainRoutes';
import { verifyToken } from '../context/auth/api';
import { AuthContext } from '../context/auth';
import { clearInvalidStorage, parseJWT, setAuthorizationToken } from '../functions/tools';

const useTokenOperations = () => {
  const navigate = useNavigate();
  const { auth: { isAuthenticated, accessToken }, setAuth, logout } = useContext(AuthContext);
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const { isLoading, mutate } = useMutation({
    mutationKey: queryKeys.VERIFY_TOKEN,
    mutationFn: (requestPayload) => verifyToken(requestPayload),
    onSuccess: () => {
      const authUser = parseJWT(accessToken);
      setAuth({ isAuthenticated: true, accessToken, authUser: authUser });

      setTokenIsValid(true);
      setAuthorizationToken(accessToken);
    },
    onError: () => {
      logout();
    }
  });

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(publicRoutes.LOGIN);
      clearInvalidStorage();
      return false;
    }
    mutate({ accessToken });
  }, []);

  return {
    tokenIsValid,
    isLoading
  };
};

export default useTokenOperations;
