import { languages, steps } from '../ui/pages/CourseForm/common/constants';
import stateCreator from './context/data/stateCreator';

export const validateInformationStepForm = (stepsForm, stepsFormErrorController, isNewRecord = true) => {
  const stepsFormErrorsGenerator = stateCreator.createEmptyStepsFormErrors();
  const errorsObject = stepsFormErrorsGenerator[steps.COURSE_INFORMATION];
  const {
    language,
    instructor,
    title,
    subtitle,
    promo_video,
    price,
    cover
  } = stepsForm[steps.COURSE_INFORMATION];

  errorsObject.language.error = !language;
  errorsObject.language.message = errorsObject.language.error ? 'Language can not be blank' : '';

  errorsObject.instructor.error = !instructor;
  errorsObject.instructor.message = errorsObject.instructor.error ? 'Instructor can not be blank' : '';

  errorsObject.title.error = title.trim() === '';
  errorsObject.title.message = errorsObject.title.error ? 'Title can not be blank' : '';

  errorsObject.subtitle.error = subtitle.trim() === '';
  errorsObject.subtitle.message = errorsObject.subtitle.error ? 'Subtitle can not be blank' : '';

  errorsObject.promo_video.error = promo_video.trim() === '';
  errorsObject.promo_video.message = errorsObject.promo_video.error ? 'Promotional video url can not be blank' : '';

  errorsObject.promo_video.error = promo_video.trim() === '';
  errorsObject.promo_video.message = errorsObject.promo_video.error ? 'Promotional video url can not be blank' : '';

  errorsObject.price.error = !price;
  errorsObject.price.message = errorsObject.price.error ? 'Price can not be blank' : '';

  if(isNewRecord){
    errorsObject.cover.error = !cover;
    errorsObject.cover.message = errorsObject.cover.error ? 'Cover can not be blank' : '';
  }

  stepsFormErrorController({ stepName: steps.COURSE_INFORMATION, errorsObject });

  return Object.values(errorsObject).find(field => field.error === true) === undefined;
};

export const validateContentStepForm = (stepsForm, stepsFormErrorController) => {
  const stepsFormErrorsGenerator = stateCreator.createEmptyStepsFormErrors();
  const errorsObject = stepsFormErrorsGenerator[steps.COURSE_CONTENT];
  const {
    categories,
    learnings,
    requirements,
    benefits,
    sections
  } = stepsForm[steps.COURSE_CONTENT];

  errorsObject.categories.error = !categories || categories.length === 0;
  errorsObject.categories.message = errorsObject.categories.error ? 'Categories can not be blank' : '';

  errorsObject.learnings.error = !learnings || learnings.length === 0;
  errorsObject.learnings.message = errorsObject.learnings.error ? 'Learnings can not be blank' : '';

  errorsObject.requirements.error = !requirements || requirements.length === 0;
  errorsObject.requirements.message = errorsObject.requirements.error ? 'Requirements can not be blank' : '';

  errorsObject.benefits.error = !benefits || benefits.length === 0;
  errorsObject.benefits.message = errorsObject.benefits.error ? 'Benefits can not be blank' : '';

  errorsObject.sections.error = !sections || sections.length === 0;
  errorsObject.sections.message = errorsObject.benefits.error ? 'Sections can not be blank' : '';

  stepsFormErrorController({ stepName: steps.COURSE_CONTENT, errorsObject });

  return Object.values(errorsObject).find(field => field.error === true) === undefined;
};

export const validateVideoStepForm = (stepsForm, stepsFormErrorController) => {
  return true;
};

export const validateAddQuizzesStepForm = (stepsForm, stepsFormErrorController) => {
  const quizzes = stepsForm[steps.ADD_QUIZ].quizzes;

  return quizzes.length !== 0;
};

export const loadCourseInformationForm = ({ data, stepsForm, setStepsForm }) => {
  if (!data) return false;

  const {
    language,
    cover,
    categories,
    learnings,
    requirements,
    benefits,
    sections,
    ...rest
  } = data;

  setStepsForm({
    ...stepsForm,
    [steps.COURSE_INFORMATION]: {
      ...stepsForm[steps.COURSE_INFORMATION],
      ...rest,
      uploadedCover: cover,
      language: languages.find(langItem => langItem.name === language)
    },
    [steps.COURSE_CONTENT]: {
      ...stepsForm[steps.COURSE_CONTENT],
      categories,
      learnings,
      requirements,
      benefits,
      sections
    }
  });
};
