import pt from 'prop-types';

import Loader from '../loader';

const View = ({ isLoading, children }) => {
  if (isLoading) return <Loader />;

  return children;
};

View.defaultProps = {
  isLoading: false
};

View.propTypes = {
  isLoading: pt.bool,
  children: pt.oneOfType([
    pt.arrayOf(pt.node),
    pt.node
  ]).isRequired
};

export default View;
