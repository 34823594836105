import React from 'react';
import { v4 as uuid } from 'uuid';
import { Grid, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { steps } from '../../../../../common/constants';
import WithCondition from '../../../../../../../../../../components/WithCondition';

const VariantsListFooterBlock = ({
                                   stepsForm,
                                   stepsFormController,
                                   variants,
                                   questionTitle,
                                   selectedSection,
                                   resetAfterAddQuestion,
                                   selectedQuestionForEdit,
                                   quizTitle,
                                 }) => {
  const { enqueueSnackbar } = useSnackbar();
  const quizzes = stepsForm[steps.ADD_QUIZ].quizzes;

  const updateQuestion = () => {
    const hasQuiz = quizzes.find(quiz => quiz.sectionId === selectedSection.id);

    const checkExistsCorrectVariant = variants.find(variant => variant.is_correct === true) !== undefined;
    if (!checkExistsCorrectVariant) {
      enqueueSnackbar('You may have forgotten to enter the correct answer.', { variant: 'error' });
      return false;
    }
    if (!!hasQuiz) {
      const extendedHasQuiz = {
        ...hasQuiz,
        questions: hasQuiz.questions.map(question => {
          if (question.id === selectedQuestionForEdit.id) {
            const updatedQuestion = {
              ...question,
              variants,
              title: questionTitle
            };
            return updatedQuestion;
          }
          return question;
        })
      };

      const extendedQuizzes = quizzes.map(quiz => quiz.id === hasQuiz.id ? extendedHasQuiz : quiz);
      stepsFormController({ stepName: steps.ADD_QUIZ, fieldObject: { quizzes: extendedQuizzes } }, true);
    }

    resetAfterAddQuestion();

    return false;
  };


  const addQuestion = () => {
    const hasQuiz = quizzes.find(quiz => quiz.sectionId === selectedSection.id);

    const checkExistsCorrectVariant = variants.find(variant => variant.is_correct === true) !== undefined;

    if (!checkExistsCorrectVariant) {
      enqueueSnackbar('You may have forgotten to enter the correct answer.', { variant: 'error' });
      return false;
    }

    if (!!hasQuiz) {
      const extendedHasQuiz = {
        ...hasQuiz,
        questions: [...hasQuiz.questions, { id: uuid(), title: questionTitle, variants }]
      };
      const extendedQuizzes = quizzes.map(quiz => quiz.sectionId === hasQuiz.sectionId ? extendedHasQuiz : quiz);
      stepsFormController({ stepName: steps.ADD_QUIZ, fieldObject: { quizzes: extendedQuizzes } }, true);
    } else {
      const extendedQuizzes = [...quizzes, {
        id: uuid(),
        title: quizTitle,
        sectionId: selectedSection.id,
        questions: [{ id: uuid(), title: questionTitle, variants }]
      }];
      stepsFormController({ stepName: steps.ADD_QUIZ, fieldObject: { quizzes: extendedQuizzes } }, true);
    }

    resetAfterAddQuestion();
  };

  return (
    <Grid
      item
      md={12}
      xs={12}
    >
      <WithCondition isDisplay={variants.length !== 0}>
        <Button
          disabled={variants.length === 0}
          variant='outlined'
          onClick={!selectedQuestionForEdit ? addQuestion : updateQuestion}
        >Save question and variants</Button>
      </WithCondition>
    </Grid>
  );
};

export default VariantsListFooterBlock;
