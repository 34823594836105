import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import stateCreator from 'src/views/PromoCodes/common/context/data/stateCreator';
import ViewList from './blocks/ViewList';

const UsagesListModal = ({
                           promoCodeUsages: {
                             queryParams,
                             setQueryParams,
                             promoCode,
                             setPromoCode,
                             fetchPromoCodeUsagesQuery
                           }
                         }) => {
  const handleClose = () => {
    setQueryParams(() => stateCreator.createEmptyQuery());
    setPromoCode(null);
  };


  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby='promoCodeUsagesListModal'
      aria-describedby='promoCodeUsagesListModalDesc'
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle
        id='promoCodeUsagesListModal'>{`Usages statistics of ${promoCode?.promoCode}` || 'Promo code usage statistics'}</DialogTitle>
      <DialogContent dividers={true}>
        <ViewList
          query={fetchPromoCodeUsagesQuery}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UsagesListModal;
