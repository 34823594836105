import pt from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';

const InstructorRequestsListToolbar = ({ queryParams, setQueryParams, ...rest }) => {

  return (
    (
      <Box {...rest}>
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SvgIcon
                          fontSize='small'
                          color='action'
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder='Search instructor request'
                  variant='outlined'
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    )
  );
};

InstructorRequestsListToolbar.propTypes = {
  queryParams: pt.object.isRequired,
  setQueryParams: pt.func.isRequired
};

export default InstructorRequestsListToolbar;
