import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

import { privateRoutes } from 'src/common/mainRoutes';

const BlogsFormToolBar = (props) => (
  <Box {...props}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <Link to={privateRoutes.BLOGS}>
        <Button
          color='primary'
          variant='contained'
        >
          Go Back
        </Button>
      </Link>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>
            <Typography variant='h2'>Create Blog</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
);

export default BlogsFormToolBar;
