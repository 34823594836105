import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import Body from '../../../partials/Body';
import PromoCodesFormBody from './partials/PromoCodesFormBody';

const PromoCodesForm = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Create Promo Code'>
      <PromoCodesFormBody isLoading={isLoading} />
    </Body>
  );
};

export default PromoCodesForm;
