import React, { useState } from 'react';
import {
  Card,
  CardHeader,
} from '@material-ui/core';
import apiRoutes from 'src/common/api/routes';
import { Button, Form, Input, Select } from 'antd';
import axiosInstance from 'src/common/config/api';
import { CheckCircleOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
const { TextArea } = Input;

export default function CoursesAddUsers() {
  const [users, setUsers] = useState();
  const [courses, setCourses] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const onFinish = (values) => {
    const item = courses.find(item => item.id === values?.course);
    let temp=[]
     temp[0] = {
        course_id:values?.course,
        student_id:users[0]?.id,
        instructor_id:item?.instructor_id,
        payment_source:values?.note
    }
    axiosInstance
    .post(apiRoutes.FETCH_MODERATOR_ADD_COURSE(), temp)
    .then((res) => {
      console.log(res);
      enqueueSnackbar('Kurs uğurla əlavə edildi.', { variant: 'success' });
    })
    .catch((err) => {
      enqueueSnackbar('Xəta baş verdi.', { variant: 'error' });
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function validateEmail(email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }
  const onSearchUsers = (value) => {
  
    if (validateEmail(value?.target?.value)) {
      axiosInstance
        .get(apiRoutes.FETCH_MODERATOR_SEARCH_USERS() + value?.target?.value)
        .then((res) => {
          console.log(res?.data);
          setUsers(res?.data);
          // enqueueSnackbar('Banner uğurla dəyişdirildi.', { variant: 'success' });
        })
        .catch((err) => {
          // enqueueSnackbar('Xəta baş verdi.', { variant: 'error' });
        });
    }
  };
  const onSearchCourses = (value) => {
    axiosInstance
      .get(apiRoutes.FETCH_MODERATOR_SEARCH_COURSE() + value)
      .then((res) => {
        console.log(res?.data);
        setCourses(res?.data);
        // enqueueSnackbar('Banner uğurla dəyişdirildi.', { variant: 'success' });
      })
      .catch((err) => {
        // enqueueSnackbar('Xəta baş verdi.', { variant: 'error' });
      });
  };
  return (
    <Card style={{ padding: '24px', margin: '24px' }}>
      <CardHeader
        subheader="Emailə görə userlərə kurs əlavə etmə"
        title="Userlərə kurs əlavə etmə"
      />

      <Form
      style={{marginTop:"40px"}}
        name="basic"
        labelCol={{
          span: 6
        }}
        layout="horizontal"
        wrapperCol={{
          span: 12
        }}
        // style={{
        //   maxWidth: 600
        // }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="İstifadəçi"
          name="user"
          rules={[
            {
              required: true,
              message: 'İstifadəçi seçimi edin!'
            }
          ]}
        >
          <Input.Search
            showSearch
            onChange={(e) => onSearchUsers(e)}
            suffix={
              users?.length > 0 ? (
                <CheckCircleOutlined style={{ color: 'green' }} />
              ) : (
                <CloseOutlined style={{ color: 'red' }} />
              )
            }
          />
        </Form.Item>

        <Form.Item
          label="Kurs adı"
          name="course"
          rules={[
            {
              required: true,
              message: 'Əlavə etmək istədiyiniz kursu seçin!'
            }
          ]}
        >
          <Select
            showSearch
            onSearch={onSearchCourses}
            defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
            options={(courses || []).map((d) => ({
                value: d.id,
                label: d.title,
              }))}
          >
            
          </Select>
        </Form.Item>

        <Form.Item
          label="Qeyd"
          name="note"
          rules={[
            {
              required: true,
              message: 'Qeydləri daxil edin!'
            }
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ float: 'right' }}
            type="primary"
            variant="contained"
            htmlType="submit"
          >
            Əlavə et
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
