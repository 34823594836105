import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';

import { AuthContext } from 'src/common/context/auth';
import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import AccountProfile from 'src/components/account/AccountProfile';
import AccountProfileDetails from 'src/components/account/AccountProfileDetails';

const Account = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR, rights.INSTRUCTOR, rights.HR] });

  const { auth, setAuth } = useContext(AuthContext);
  const { authUser } = auth;

  return (
    <>
      <Helmet>
        <title>Account | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth='lg'>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <AccountProfile authUser={authUser} />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <AccountProfileDetails auth={auth} setAuth={setAuth} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Account;
