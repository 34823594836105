import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';

export const fetchHRList = (queryParams) =>
  axiosInstance.get(apiRoutes.FETCH_HR_LIST(), { params: queryParams }).then(res => res.data);

export const createHR = (formData) =>
  axiosInstance.post(apiRoutes.CREATE_HR(), formData).then(res => res.data);

export const fetchHRDetail = (id) =>
  axiosInstance.get(apiRoutes.FETCH_HR_DETAIL(id)).then(res => res.data);

export const updateHR = ({id, formData}) =>
  axiosInstance.put(apiRoutes.UPDATE_HR(id), formData).then(res => res.data);

export const deleteHR = (id) =>
  axiosInstance.delete(apiRoutes.DELETE_HR(id)).then(res => res.data);
