import pt from 'prop-types';
import { useContext } from 'react';
import { fetchHRCourseUsersFlow } from 'src/views/Courses/ui/pages/HRCourses/common/api';
import { HRCoursesContext } from 'src/views/Courses/ui/pages/HRCourses/common/context';
import { Table } from '../../components';

const HRCourseUsersTable = ({ data }) => {
  const {
    changeStatus,
    dataFetching: { queryParams, setQueryParams },
    // delete: { deleteCourseMutation, deleteCourseHandler }
  } = useContext(HRCoursesContext);
  return (
    <Table
     users={data} />
  );
};

HRCourseUsersTable.defaultProps = {
  data: []
};

HRCourseUsersTable.propTypes = {
  data: pt.arrayOf(pt.shape({
    id: pt.oneOfType([pt.string, pt.number]).isRequired,
    fullname: pt.string.isRequired,
    email: pt.string.isRequired,
    started_at: pt.string.isRequired,
    course_progress: pt.number.isRequired,
    quizzes: pt.arrayOf(pt.shape({
      quiz_name: pt.string.isRequired,
      question_count: pt.number.isRequired,
      correct_answers: pt.number.isRequired
    })),
    certificate_url: pt.string.isRequired,
    finished_at: pt.string
  }))
};

export default HRCourseUsersTable;
