import { Fab, FormControl, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import WithCondition from '../../../../../../../../../../components/WithCondition';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import stateCreator from '../../../../../../../../common/context/data/stateCreator';

const VariantFormBlock = ({
                            variantForm,
                            onChangeHandler,
                            selectedVariantForEdit,
                            variants,
                            setVariants,
                            setVariantForm,
                            setSelectedVariantForEdit,
                            questionTitle
                          }) => {

  const isDisableAddVariantButton = !questionTitle || !variantForm.title;

  const addVariant = () => {
    const variantForAdd = { id: uuidv4(), title: variantForm.title, is_correct: variantForm.is_correct };
    setVariants([...variants, variantForAdd]);
    setVariantForm(() => stateCreator.createEmptyVariantForm());
  };

  const updateVariant = () => {
    setVariants(variants.map(variant => variant.id === selectedVariantForEdit.id ? { ...variant, ...variantForm } : variant));
    resetVariantForm();
  };

  const resetVariantForm = () => {
    setVariantForm(() => stateCreator.createEmptyVariantForm());
    setSelectedVariantForEdit(null);
  };

  return (
    <>
      <Grid
        item
        md={3}
        xs={3}
      >
        <FormControl fullWidth={true} variant='outlined'>
          <Select
            labelId='isCorrect'
            id='isCorrect'
            name='is_correct'
            value={variantForm.is_correct}
            fullWidth={true}
            onChange={({ target }) => onChangeHandler({ [target.name]: target.value })}
          >
            <MenuItem value={false}>Yanlış</MenuItem>
            <MenuItem value={true}>Doğru</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        md={9}
        xs={9}
      >
        <TextField
          id='variant'
          label='Add question variants'
          variant='outlined'
          fullWidth
          name='title'
          value={variantForm.title}
          onChange={({ target }) => onChangeHandler({ [target.name]: target.value })}
          InputProps={{
            endAdornment: (
              <>
                <WithCondition isDisplay={!selectedVariantForEdit}>
                  <InputAdornment>
                    <Fab
                      disabled={isDisableAddVariantButton}
                      size='small'
                      color='primary'
                      aria-label='add'
                      onClick={addVariant}
                    >
                      <AddIcon />
                    </Fab>
                  </InputAdornment>
                </WithCondition>
                <WithCondition isDisplay={!!selectedVariantForEdit}>
                  <InputAdornment>
                    <Fab
                      disabled={isDisableAddVariantButton}
                      size='small'
                      color='primary'
                      aria-label='done'
                      style={{ marginRight: '5px' }}
                      onClick={updateVariant}
                    >
                      <DoneIcon />
                    </Fab>
                  </InputAdornment>
                  <InputAdornment>
                    <Fab
                      disabled={isDisableAddVariantButton}
                      size='small'
                      color='primary'
                      aria-label='clear'
                      onClick={resetVariantForm}
                    >
                      <ClearIcon />
                    </Fab>
                  </InputAdornment>
                </WithCondition>
              </>
            )
          }}
        />
      </Grid>
    </>
  );
};

export default VariantFormBlock;
