import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { BookOpen as BookOpenIcon } from 'react-feather';

import getInitials from 'src/common/functions/getInitials';
import WithCondition from 'src/components/WithCondition';

import { CustomersContext } from 'src/views/Customers/common/context';
import { CoursesListModal } from '../../components';

const CustomersListResults = ({ data = { count: 0, customers: [] }, ...rest }) => {
  const {
    dataFetching: { queryParams, setQueryParams },
    customerCourses
  } = useContext(CustomersContext);

  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = data.customers.map(customerItem => customerItem.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedCustomerIds.length === data.customers.length}
                    color='primary'
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < data.customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Ad
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Telefon
                </TableCell>
                <TableCell>
                  Balans
                </TableCell>
                <TableCell>
                  Tarix
                </TableCell>
                <TableCell>
                  Kurslar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.customers.slice(0, queryParams.limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.id}
                  selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.id) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.id)}
                      value='true'
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={customer.photo}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(customer.fullname)}
                      </Avatar>
                      <Typography
                        color='textPrimary'
                        variant='body1'
                      >
                        {customer.fullname}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {customer.email}
                  </TableCell>
                  <TableCell>
                    {customer.phone_number}
                  </TableCell>
                  <TableCell>
                    {customer.balance}
                  </TableCell>
                  <TableCell>
                    {moment(customer.created_at).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => customerCourses.setCustomer(customer)}
                      disabled={customerCourses.fetchCustomerCoursesQuery.isLoading}
                    >
                      <BookOpenIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <WithCondition isDisplay={!!customerCourses.customer}>
        <CoursesListModal customerCourses={customerCourses} />
      </WithCondition>
    </Card>
  );
};

CustomersListResults.defaultProps = {
  data: {
    customers: [],
    count: 0
  }
};

CustomersListResults.propTypes = {
  data: PropTypes.object.isRequired
};

export default CustomersListResults;
