import { useContext, useState } from 'react';
import { Autocomplete, CardHeader, Divider, Grid, TextField } from '@material-ui/core';

import WithCondition from '../../../../../../../../components/WithCondition';
import { CoursesContext } from '../../../../../../common/context';
import { steps } from '../../../common/constants';
import FormFactory from './blocks/FormFactory';

const AddQuizzesStep = () => {
  const { create: { stepsForm } } = useContext(CoursesContext);
  const videoStepData = stepsForm[steps.CREATE_VIDEOS];

  const [selectedSection, setSelectedSection] = useState(null);

  const [quizTitle, setQuizTitle] = useState('');

  const courseSectionsProps = {
    options: videoStepData.sectionNames,
    getOptionLabel: (option) => option.title,
    loadingText: 'Loading...',
    debug: 'true',
    onChange: (event, section) => {
      setSelectedSection(section);
      const hasQuiz = section && stepsForm[steps.ADD_QUIZ].quizzes.find(quiz => quiz.sectionId === section.id);
      setQuizTitle(hasQuiz ? hasQuiz.title : '');
    },
    value: selectedSection
  };

  return (
    <>
      <CardHeader subheader='Add created course quizzes' title='Add course quizzes' />
      <Divider />
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <Autocomplete
            {...courseSectionsProps}
            renderInput={(params) => <TextField {...params} label='Choose course section' margin='normal' />}
          />
        </Grid>
        <WithCondition isDisplay={!!stepsForm[steps.CREATE_VIDEOS].courseId && !!selectedSection}>
          <FormFactory
            quizTitle={quizTitle}
            setQuizTitle={setQuizTitle}
            selectedSection={selectedSection}
          />
        </WithCondition>
      </Grid>
    </>
  );
};

export default AddQuizzesStep;
