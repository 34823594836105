export const steps = {
  COURSE_INFORMATION: 'COURSE_INFORMATION',
  COURSE_CONTENT: 'COURSE_CONTENT',
  CREATE_VIDEOS: 'CREATE_VIDEOS',
  ADD_QUIZ: 'ADD_QUIZ'
};

export const stepsIndexes = {
  COURSE_INFORMATION: 0,
  COURSE_CONTENT: 1,
  CREATE_VIDEOS: 2,
  ADD_QUIZ: 3
};

export const stepNames = ['Course Information', 'Course Content', 'Video Creation', 'Add quizzes'];

export const languages = [
  {
    id: 1,
    name: 'English'
  },
  {
    id: 2,
    name: 'Azərbaycan'
  },
  {
    id: 3,
    name: 'Русский'
  }
];
