import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

const QuestionHeaderBlock = ({ questionTitle, setQuestionTitle }) => {
  return (
    <Grid
      item
      md={12}
      xs={12}
    >
      <Typography variant='h4' margin='15px' textAlign='center'>Assign questions to selected section</Typography>
      <TextField
        id='questionTitle'
        label='Question title'
        variant='outlined'
        fullWidth
        name='question_title'
        value={questionTitle}
        onChange={({ target }) => setQuestionTitle(target.value)}
      />
    </Grid>
  );
};

export default QuestionHeaderBlock;
