import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar';

export default function UserAdd() {
  const [data, setData] = useState([]);
  const [hrList, setHrList] = useState();
  const [hrId, setHrId] = useState();
  const [message, setMessage] = useState();
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  useEffect(() => {
    axiosInstance.get('hr/hr-all').then((res) => {
      setHrList(res?.data?.hrList);
    });
  }, []);

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const addUsers = () => {
    
    if(data?.length>0 && hrId)
    {
      let obj={
        hr_id:hrId,
        users:data
      }
      console.log(obj)
     
      axiosInstance.post('hr/import-users',obj).then((res) => {
        console.log(res);
        setData([])
        setMessage("Userlər əlavə edildi")
        setState({ ...state, open: true });
        window.location.reload()
      })
      .catch(err=>{
        setState({ ...state, open: true });
        setMessage("Userlər əlavə edilərkən problem baş verdi")
      });
    }
    else{
      if(data.length==0)
      {
        setState({ ...state, open: true });
        setMessage("Excell faylı seçməlisiniz")
      }
      else{
        setState({ ...state, open: true });
        setMessage("Hr listindən hər hansı bir seçim etməlisiniz")
      }
    }
  };
  const handleChange = (event) => {
    setHrId(event.target.value);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;

  return (
    <div>
        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          marginTop: '3%'
        }}
      >
        <h3>
          {' '}
          <label htmlFor="upload">Upload File</label>
        </h3>
        <input
          type="file"
          name="upload"
          id="upload"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={readUploadFile}
        />
        {data.length > 0 ? (
          <div>Faylda &nbsp;{data.length} &nbsp; user mövcuddur</div>
        ) : null}
        {data.length > 0 ? (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel style={{marginTop:"-20px"}} id="demo-select-small">Hr seçin</InputLabel>

          <Select
                    labelId="demo-select-small"

            id="demo-multiple-name"
            style={{minWidth:"100px",marginTop:"-20px"}}
            label="Hr seçin"
            // value={personName}
            onChange={handleChange}
            // input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {hrList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.fullname}
              </MenuItem>
            ))}
          </Select>
          </FormControl>
        ) : null}
        {data.length > 0 ? (
          <Button
            style={{ display: 'flex', marginTop: '-7px', marginLeft: ' auto' }}
            onClick={addUsers}
          >
            Əlavə et
          </Button>
        ) : null}
      </div>

      <Box sx={{ minWidth: 1050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ad, Soyad</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Şifrə</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell>{item?.fullname}</TableCell>

                <TableCell>{item?.phone}</TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell>{item?.password}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  );
}
