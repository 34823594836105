import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import queryKeys from 'src/common/queryKeys';
import { privateRoutes } from 'src/common/mainRoutes';
import { toCapitalize } from 'src/common/functions/tools';

import { steps, stepsIndexes } from '../../../ui/pages/CourseForm/common/constants';
import stateCreator from '../data/stateCreator';
import {
  loadCourseInformationForm,
  validateAddQuizzesStepForm,
  validateContentStepForm,
  validateInformationStepForm,
  validateVideoStepForm
} from '../../utils';

import {
  changeCourseStatus,
  fetchCourses,
  fetchInstructors,
  fetchCategories,
  createCourse,
  addCourseLectures,
  addCourseQuizzes,
  fetchCourseInformation,
  updateCourseInformation,
  deleteCourse
} from '../../api';

const useCourses = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [step, setStep] = useState(stepsIndexes.COURSE_INFORMATION);
  const [stepsForm, setStepsForm] = useState(() => stateCreator.createEmptyStepsForm());
  const [stepsFormErrors, setStepsFormErrors] = useState(() => stateCreator.createEmptyStepsFormErrors());

  const [queryParams, setQueryParams] = useState(stateCreator.createEmptyQuery());
  const fetchCoursesQuery = useQuery([queryKeys.FETCH_COURSES, queryParams], () => fetchCourses(queryParams));
  const fetchCourseInformationQuery = useQuery(
    {
      queryKey: [queryKeys.FETCH_COURSE_INFORMATION, id],
      queryFn: () => fetchCourseInformation(id),
      enabled: !!id,
      onSuccess: data => {
        loadCourseInformationForm({ data, stepsForm, setStepsForm });
      },
      cacheTime: 0,
      staleTime: 0
    }
  );

  const deleteCourseMutation = useMutation(deleteCourse, {
    onSuccess: () => {
      enqueueSnackbar('Course successfully deleted', { variant: 'success' });
      return queryClient.invalidateQueries(queryKeys.FETCH_COURSES);
    },
    onError: () => {
      enqueueSnackbar('Something was wrong! Retry again, pls', { variant: 'error' });
      return queryClient.invalidateQueries(queryKeys.FETCH_COURSES);
    }
  });

  const deleteCourseHandler = (id) => deleteCourseMutation.mutate(id);

  const changeRequestStatusMutation = useMutation(changeCourseStatus, {
    onSuccess: () => {
      enqueueSnackbar('Course status changed was successfully', { variant: 'success' });
      return queryClient.invalidateQueries(queryKeys.FETCH_COURSES);
    },
    onError: () => {
      enqueueSnackbar('Something was wrong! Retry again, pls', { variant: 'error' });
      return queryClient.invalidateQueries(queryKeys.FETCH_COURSES);
    }
  });

  const fetchInstructorsQuery = useQuery(queryKeys.FETCH_INSTRUCTORS, () => fetchInstructors());

  const fetchCategoriesQuery = useQuery(queryKeys.FETCH_CATEGORIES, () => fetchCategories());
  const addCourseLecturesMutation = useMutation({
    mutationKey: queryKeys.ADD_COURSE_LECTURES,
    mutationFn: addCourseLectures,
    onSuccess: (data) => {
      if (!!id) {
        stepsFormController({ stepName: steps.ADD_QUIZ, fieldObject: { quizzes: data.quizzes } }, true);
      }

      enqueueSnackbar('Lectures added was successfully', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Something was wrong! Retry again, pls', { variant: 'error' });
    }
  });

  const addCourseQuizzesMutation = useMutation({
    mutationKey: queryKeys.ADD_COURSE_QUIZZES,
    mutationFn: addCourseQuizzes,
    onSuccess: () => {
      enqueueSnackbar('Quizzes added was successfully', { variant: 'success' });
      setTimeout(() => navigate(privateRoutes.COURSES), 500);
    },
    onError: () => {
      enqueueSnackbar('Something was wrong! Retry again, pls', { variant: 'error' });
    }
  });

  const createCourseMutation = useMutation({
    mutationKey: queryKeys.CREATE_COURSE,
    mutationFn: createCourse,
    onSuccess: () => {
      enqueueSnackbar('Course created was successfully', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Something was wrong! Retry again, pls', { variant: 'error' });
    }
  });

  const updateCourseInformationMutation = useMutation({
    mutationKey: queryKeys.UPDATE_COURSE_INFORMATION,
    mutationFn: updateCourseInformation,
    onSuccess: () => {
      enqueueSnackbar('Course updated was successfully', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Something was wrong! Retry again, pls', { variant: 'error' });
    }
  });


  const stepsFormErrorController = ({ stepName, errorsObject }) => {
    setStepsFormErrors({
      ...stepsFormErrors,
      [stepName]: {
        ...stepsFormErrors[stepName],
        ...errorsObject
      }
    });
  };

  const stepsFormValidationFactory = () => {
    switch (step) {
      case stepsIndexes.COURSE_INFORMATION:
        return validateInformationStepForm(stepsForm, stepsFormErrorController, !id);
      case stepsIndexes.COURSE_CONTENT:
        return validateContentStepForm(stepsForm, stepsFormErrorController);
      case stepsIndexes.CREATE_VIDEOS:
        return validateVideoStepForm(stepsForm, stepsFormErrorController);
      case stepsIndexes.ADD_QUIZ:
        return validateAddQuizzesStepForm(stepsForm, stepsFormErrorController);
      default:
        return false;
    }
  };

  const addCourseQuizzesHandler = (callback = null) => {
    if (!stepsFormValidationFactory()) return false;

    const addQuizzesStepData = stepsForm[steps.ADD_QUIZ];
    addCourseQuizzesMutation.mutate({
      requestData: {
        quizzes: addQuizzesStepData.quizzes,
        isNewRecord: !id
      },
      courseId: stepsForm[steps.CREATE_VIDEOS].courseId
    }, {
      onSuccess: () => {
        if (!!callback) callback();
      }
    });
  };

  const addCourseLecturesHandler = (callback = null) => {
    if (!stepsFormValidationFactory()) return false;

    const videoCreationStepData = stepsForm[steps.CREATE_VIDEOS];
    const videCreationStepDataSections = videoCreationStepData.sections.map(sectionItem => {
      return {
        ...sectionItem,
        lectures: sectionItem.lectures.map(lectureItem => {
          return {
            ...lectureItem,
            type: lectureItem.type.id
          };
        })
      };
    });

    addCourseLecturesMutation.mutate({
      course_id: videoCreationStepData.courseId,
      sections: videCreationStepDataSections,
      isNewRecord: !id
    }, {
      onSuccess: () => {
        if (!!callback) callback();
      }
    });

  };

  const createCourseHandler = (callback = null) => {
    if (!stepsFormValidationFactory()) return false;

    const {
      language, instructor, title,
      subtitle, promo_video, price,
      discount, description, cover
    } = stepsForm[steps.COURSE_INFORMATION];

    const { categories, learnings, requirements, benefits, sections } = stepsForm[steps.COURSE_CONTENT];
    const formData = new FormData();

    const courseCreationData = {
      title, subtitle, promo_video,
      price, discount, description, cover,
      language: language.name,
      instructor_id: instructor.id,
      learnings: learnings.map(learning => learning.value),
      requirements: requirements.map(requirement => requirement.value),
      benefits: benefits.map(benefit => benefit.value),
      categories: categories.map(category => category.id),
      sections: sections.map(section => ({ id: section.id, title: section.title, sort: section.sort }))
    };

    formData.append('title', courseCreationData.title);
    formData.append('subtitle', courseCreationData.subtitle);
    formData.append('promo_video', courseCreationData.promo_video);
    formData.append('price', courseCreationData.price);
    formData.append('discount', courseCreationData.discount);
    formData.append('description', courseCreationData.description);
    formData.append('cover', courseCreationData.cover);
    formData.append('language', courseCreationData.language);
    formData.append('instructor_id', courseCreationData.instructor_id);
    formData.append('learnings', JSON.stringify(courseCreationData.learnings));
    formData.append('requirements', JSON.stringify(courseCreationData.requirements));
    formData.append('benefits', JSON.stringify(courseCreationData.benefits));
    formData.append('categories', JSON.stringify(courseCreationData.categories));
    formData.append('sections', JSON.stringify(courseCreationData.sections));

    if (!!id) {
      updateCourseInformationMutation.mutate({ id, requestData: formData }, {
        onSuccess: (data) => {
          stepsFormController({
            stepName: steps.CREATE_VIDEOS,
            fieldObject: {
              courseId: id,
              sectionNames: data.map(({ id, title }) => ({ id, title })),
              sections: data.map(sectionItem => {
                return {
                  id: sectionItem.id,
                  sectionId: sectionItem.id,
                  lectures: sectionItem.lectures.map(({
                                                        section_id,
                                                        duration,
                                                        volume,
                                                        video_id,
                                                        type,
                                                        sort,
                                                        ...rest
                                                      }) => ({
                    ...rest,
                    sectionId: section_id,
                    orderNum: sort,
                    duration: duration.toString(),
                    type: {
                      id: type,
                      name: toCapitalize(type)
                    }
                  }))
                };
              })
            }
          }, true);
          if (callback) callback();
        }
      });
    } else {
      createCourseMutation.mutate(formData, {
        onSuccess: (data) => {
          stepsFormController({
            stepName: steps.CREATE_VIDEOS,
            fieldObject: { courseId: data.courseId, sectionNames: data.sections }
          }, true);

          if (callback) callback();
        }
      });
    }
  };

  const stepsFormController = ({ stepName, fieldObject }, multiple = false) => {
    const mutableObject = multiple ? fieldObject : { [fieldObject.fieldName]: fieldObject.fieldValue };
    setStepsForm({
      ...stepsForm,
      [stepName]: {
        ...stepsForm[stepName],
        ...mutableObject
      }
    });
  };

  return {
    isNewRecord: !id,
    dataFetching: {
      isLoading: fetchCoursesQuery.isLoading,
      error: fetchCoursesQuery.error,
      data: fetchCoursesQuery.data,
      queryParams,
      setQueryParams,
      fetchCourseInformationQuery
    },
    changeStatus: {
      isLoading: changeRequestStatusMutation.isLoading,
      error: changeRequestStatusMutation.error,
      data: changeRequestStatusMutation.data,
      mutate: changeRequestStatusMutation.mutate
    },
    create: {
      step,
      setStep,
      stepsForm,
      setStepsForm,
      stepsFormController,
      stepsFormErrors,
      stepsFormValidationFactory,
      createCourse: createCourseHandler,
      createCourseMutation,
      addCourseLecturesHandler,
      addCourseLecturesMutation,
      addCourseQuizzesHandler,
      addCourseQuizzesMutation
    },
    update: {
      updateCourseInformationMutation
    },
    delete: {
      deleteCourseHandler,
      deleteCourseMutation
    },
    additionallyQueries: {
      fetchInstructors: fetchInstructorsQuery,
      fetchCategories: fetchCategoriesQuery
    }
  };
};

export default useCourses;
