import { useEffect, useContext } from 'react';
import _ from 'lodash';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  BookOpen as BookOpenIcon,
  UserX as UserXIcon,
  DollarSign as DollarIcon,
  GitPullRequest as GitPullRequestIcon,
  Repeat as RepeatIcon,
  Code as CodeIcon,
  Book as BookIcon,
  UserCheck as UserCheckIcon,
  plus as UserPlusIcon,
} from 'react-feather';
import * as Icon from 'react-feather';

import NavItem from './NavItem';

import { AuthContext } from 'src/common/context/auth';
import { rights } from 'src/common/constants';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/courses',
    icon: BookOpenIcon,
    title: 'Courses',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/hr/courses',
    icon: UsersIcon,
    title: 'HR Courses',
    rights: [rights.HR]
  },
  {
    href: '/app/user/add',
    icon: Icon.UserPlus,
    title: 'Add user',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/influencer',
    icon: Icon.UserPlus,
    title: 'Add influencer',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/influencer/statistics',
    icon: Icon.UserPlus,
    title: 'Influencer statistics',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/instructors',
    icon: UserXIcon,
    title: 'Instructors',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/hr',
    icon: UserCheckIcon,
    title: 'Human Resources',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/sales',
    icon: DollarIcon,
    title: 'Sales',
    rights: [rights.MODERATOR, rights.INSTRUCTOR]
  },
  {
    href: '/app/instructor-requests',
    icon: GitPullRequestIcon,
    title: 'Instructor Requests',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/feedbacks',
    icon: RepeatIcon,
    title: 'Feedbacks',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/promo-codes',
    icon: CodeIcon,
    title: 'Promo codes',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/blogs',
    icon: BookIcon,
    title: 'Blogs',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings',
    rights: [rights.MODERATOR, rights.INSTRUCTOR]
  },
  {
    href: '/app/statistics',
    icon: DollarIcon,
    title: 'Statistics',
    rights: [rights.PARTNER]
  },
  {
    href: '/app/banner',
    icon: Icon.Edit,
    title: 'Banner update',
    rights: [rights.MODERATOR]
  },
  {
    href: '/app/course-add-user',
    icon: Icon.FilePlus,
    title: 'Courses add users',
    rights: [rights.MODERATOR]
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { auth } = useContext(AuthContext);
  const { authUser } = auth;

  const allowedMenuItems = items.filter(menuItem => _.intersection(menuItem.rights, authUser.role).length > 0);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={authUser.photo}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to='/app/account'
        />
        <Typography
          color='textPrimary'
          variant='h5'
        >
          {authUser.fullname}
        </Typography>
        <Typography
          color='textSecondary'
          align='center'
          variant='body2'
        >
          {authUser.professional}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {allowedMenuItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor='left'
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
