import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import Budget from 'src/components/dashboard//Budget';
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestProducts from 'src/components/dashboard//LatestProducts';
import Sales from 'src/components/dashboard//Sales';
import TasksProgress from 'src/components/dashboard//TasksProgress';
import TotalCustomers from 'src/components/dashboard//TotalCustomers';
import TotalProfit from 'src/components/dashboard//TotalProfit';
import TrafficByDevice from 'src/components/dashboard//TrafficByDevice';
import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';
import HRDashboard from 'src/views/HRDashboard/Dashboard';
import { useEffect, useState } from 'react';
import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';
import CourseProgress from 'src/components/dashboard/CourseProgress';
import ActiveUsers from 'src/components/dashboard/ActiveUsers';
import BoughtCourseCount from 'src/components/dashboard/BoughtCourseCount';
import BoughtWithPromocode from 'src/components/dashboard/BoughtWithPromocode';
import CompareDataForMod from 'src/components/dashboard/CompareDataForMod';
import SalesToday from 'src/components/dashboard/SalesToday';
import SalesMonthly from 'src/components/dashboard/SalesMonthly';
import DetailSalesForInstructorsAndCourses from 'src/components/dashboard/DetailSalesForInstructorsAndCourses';
import DetailSalesMonthlyForCourses from 'src/components/dashboard/DetailSalesMonthlyForCourses';
import SalesForCategory from 'src/components/dashboard/SalesForCategory';
import SalesCategoryMonthly from 'src/components/dashboard/SalesCategoryMonthly';

const Dashboard = () => {
  const { isLoading, hasRole } = useRBAC({
    rights: [rights.MODERATOR, rights.INSTRUCTOR, rights.HR, rights.PARTNER]
  });
  const [data, setData] = useState([]);
  const [dailySales, setDailySales] = useState([]);
  const [salesMonthly, setSalesMonthly] = useState();
  if (!!hasRole(rights.HR)) {
    return <HRDashboard />;
  }
  useEffect(() => {
    if (hasRole(rights.MODERATOR)) {
      axiosInstance.get(apiRoutes.FETCH_MODERATOR_ALLDETAIL()).then((res) => {
        setData(res?.data);
      });
      axiosInstance.get(apiRoutes.FETCH_MODERATOR_DAILY_SALES()).then((res) => {
        setDailySales(res?.data[0]);
      });
     
    } else if (hasRole(rights.INSTRUCTOR)) {
      axiosInstance.get(apiRoutes.FETCH_INSTRUCTOR_ALLDETAIL()).then((res) => {
        setData(res?.data);
      });
    }
    else if(hasRole(rights.INSTRUCTOR)){
      alert()
    }
  }, []);

  return (
    <>
      <Helmet>{/* <title>Dashboard | Safavy.org</title> */}</Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
            style={{ display: hasRole(rights.INSTRUCTOR) ? '' : 'none' }}
          >
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit sx={{ height: '100%' }} data={data} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Budget sx={{ height: '100%' }} data={data} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalCustomers sx={{ height: '100%' }} data={data} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TasksProgress data={data} />
            </Grid>

            {/* <Grid item lg={8} md={12} xl={9} xs={12}>
              <Sales />
            </Grid> */}
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <TrafficByDevice sx={{ height: '100%' }} data={data} />
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <CourseProgress data={data} />
            </Grid>
            {/* <Grid item lg={8} md={12} xl={9} xs={12}>
              <LatestOrders />
            </Grid> */}
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ display: hasRole(rights.MODERATOR) ? '' : 'none' }}
          >
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ActiveUsers sx={{ height: '100%' }} data={data} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <BoughtCourseCount sx={{ height: '100%' }} data={data} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <BoughtWithPromocode sx={{ height: '100%' }} data={data} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={6}>
              <SalesToday sx={{ height: '100%' }} data={dailySales} />
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <SalesMonthly sx={{ height: '100%' }}  />
            </Grid>
            <Grid item lg={8} md={12} xl={6} xs={12}>
              <CompareDataForMod sx={{ height: '100%' }} data={data} />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <DetailSalesForInstructorsAndCourses sx={{ height: '100%' }} data={data} />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <SalesForCategory sx={{ height: '100%' }}  />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <SalesCategoryMonthly sx={{ height: '100%' }}  />
            </Grid>
            {/* <Grid item lg={12} md={12} xl={12} xs={12}>
              <DetailSalesMonthlyForCourses sx={{ height: '100%' }}/>
            </Grid> */}
            {/* <Grid item lg={4} md={6} xl={3} xs={12}>
              <CourseProgress data={data} />
            </Grid>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <LatestOrders />
            </Grid> */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
