import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { convertArrayToObject } from 'src/views/Instructors/common/utils';
import { InstructorContext } from 'src/views/Instructors/common/context';

const SocialAccountsModal = ({ onClose, isReadOnly = false, readOnlyData }) => {
  const { create: { socialAccounts, setSocialAccounts } } = useContext(InstructorContext);

  const formData = !isReadOnly ? socialAccounts : convertArrayToObject(readOnlyData);

  const changeFormHandler = (e) => {
    if (isReadOnly) return false;

    setSocialAccounts({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Dialog maxWidth='md' fullWidth={true} open={true} onClose={onClose} aria-labelledby='socialAccountsModalTitle'>
      <DialogTitle id='socialAccountsModalTitle'>Social accounts form</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`You can ${isReadOnly ? 'see' : 'add'} the user's social media accounts in the form below.`}
        </DialogContentText>
        <TextField
          autoFocus
          name='facebook'
          margin='dense'
          id='facebook'
          label='Facebook'
          fullWidth
          value={formData.facebook}
          onChange={changeFormHandler}
          disabled={isReadOnly}
        />
        <TextField
          name='twitter'
          margin='dense'
          id='twitter'
          label='Twitter'
          fullWidth
          value={formData.twitter}
          onChange={changeFormHandler}
          disabled={isReadOnly}
        />
        <TextField
          name='github'
          margin='dense'
          id='github'
          label='Github'
          fullWidth
          value={formData.github}
          onChange={changeFormHandler}
          disabled={isReadOnly}
        />
        <TextField
          name='youtube'
          margin='dense'
          id='youtube'
          label='Youtube'
          fullWidth
          value={formData.youtube}
          onChange={changeFormHandler}
          disabled={isReadOnly}
        />
        <TextField
          name='behance'
          margin='dense'
          id='behance'
          label='Behance'
          fullWidth
          value={formData.behance}
          onChange={changeFormHandler}
          disabled={isReadOnly}
        />
        <TextField
          name='dribble'
          margin='dense'
          id='dribble'
          label='Dribble'
          fullWidth
          value={formData.dribble}
          onChange={changeFormHandler}
          disabled={isReadOnly}
        />
        <TextField
          name='linkedin'
          margin='dense'
          id='linkedin'
          label='Linkedin'
          fullWidth
          value={formData.linkedin}
          onChange={changeFormHandler}
          disabled={isReadOnly}
        />
        <TextField
          name='instagram'
          margin='dense'
          id='instagram'
          label='Instagram'
          fullWidth
          value={formData.instagram}
          onChange={changeFormHandler}
          disabled={isReadOnly}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {isReadOnly ? 'Close' : 'Add and close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SocialAccountsModal;
