import pt from 'prop-types';
import { Link } from 'react-router-dom';

import { privateRoutes } from '../../common/mainRoutes';

import './ErrorBoundary.scss';

const ErrorBoundary = ({ error, children }) => {

  if (!error) return children;

  return (
    <div className='container'>
      <img src='https://i.imgur.com/qIufhof.png' />

      <h1>
        <span>500</span> <br />
        Internal server error
      </h1>
      <p>We are currently trying to fix the problem.</p>
      <p className='info'>
        Illustration taken from
        <Link to={privateRoutes.DASHBOARD}>Go to Home Page</Link
        >
      </p>
    </div>
  );
};

ErrorBoundary.defaultProps = {
  error: false
};

ErrorBoundary.propTypes = {
  error: pt.any,
  children: pt.any.isRequired
};

export default ErrorBoundary;
