import { Divider, Grid } from '@material-ui/core';

import { QuestionsListTable } from '../../components';
import { steps } from '../../../common/constants';
import Typography from '@material-ui/core/Typography';

const QuestionsListBlock = ({ selectedSection, stepsForm, deleteQuestion, passQuestionForUpdate }) => {
  const hasQuiz = stepsForm[steps.ADD_QUIZ].quizzes.find(quiz => quiz.sectionId === selectedSection.id);
  const questions = hasQuiz ? hasQuiz.questions : [];

  return (
    <Grid
      item
      xs={12}
      md={12}
    >
      <Typography textAlign='center' margin='15px' variant='h4'>{`Questions of ${selectedSection.title}`}</Typography>
      <Divider />
      <QuestionsListTable
        questions={questions}
        deleteQuestion={deleteQuestion}
        passQuestionForUpdate={passQuestionForUpdate}
      />
    </Grid>
  );
};

export default QuestionsListBlock;
