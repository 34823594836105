import useInstructors from './hooks/useInstructors';

import InstructorContext from './InstructorContext';

const InstructorsProvider = ({ children }) => {
  const contextData = useInstructors();

  return (
    <InstructorContext.Provider value={contextData}>{children}</InstructorContext.Provider>
  );
};

export default InstructorsProvider;
