import pt from 'prop-types';
import { Helmet } from 'react-helmet';
import { SnackbarProvider } from 'notistack';
import { Box, Container } from '@material-ui/core';

import WithCondition from 'src/components/WithCondition';

import { HRProvider } from '../../common/context';

const Body = ({ children, title }) => (
  <SnackbarProvider maxSnack={10}>
    <HRProvider>
      <WithCondition isDisplay={!!title}>
        <Helmet>
          <title>{title} | Material Kit</title>
        </Helmet>
      </WithCondition>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          display: 'flex',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {
            children
          }
        </Container>
      </Box>
    </HRProvider>
  </SnackbarProvider>
);

Body.propTypes = {
  title: pt.string,
  children: pt.any.isRequired
};

export default Body;
