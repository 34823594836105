import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import pt from 'prop-types';
import { useNavigate } from 'react-router';

import { publicRoutes } from 'src/common/mainRoutes';
import { AuthContext } from 'src/common/context/auth';

const useRBAC = ({ rights }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { auth: { authUser } } = useContext(AuthContext);

  const rightsIntersection = _.intersection(rights, authUser.role);

  const hasRole = (role) => authUser.role.indexOf(role) !== -1;

  useEffect(() => {
    if (!rightsIntersection || rightsIntersection.length === 0) {
      navigate(publicRoutes.PAGE_404);
    } else {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, hasRole, authUser };
};

useRBAC.propTypes = {
  rights: pt.array.isRequired
};

export default useRBAC;
