import { rights } from 'src/common/constants';
import useRBAC from 'src/common/hooks/useRBAC';

import Body from '../../../partials/Body';

import CoursesListBody from './partials/CoursesListBody';

const CoursesList = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Courses'>
      <CoursesListBody isLoading={isLoading} />
    </Body>
  );
};

export default CoursesList;
