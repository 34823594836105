import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Fab, Grid, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';

import WithCondition from 'src/components/WithCondition';

import { CoursesContext } from 'src/views/Courses/common/context';
import { steps } from 'src/views/Courses/ui/pages/CourseForm/common/constants';

const BenefitsBlock = () => {
  const { create: { stepsForm, setStepsForm, stepsFormErrors } } = useContext(CoursesContext);
  const [benefit, setBenefit] = useState('');
  const [selectedBenefitForEdit, setSelectedBenefitForEdit] = useState(null);

  const courseContentStep = stepsForm[steps.COURSE_CONTENT];

  const setBenefits = (benefits) => {
    setStepsForm({
      ...stepsForm,
      [steps.COURSE_CONTENT]: { ...stepsForm[steps.COURSE_CONTENT], benefits }
    });
  };

  const addBenefit = (benefit) => {
    setBenefits([...stepsForm[steps.COURSE_CONTENT].benefits, { id: uuidv4(), value: benefit }]);
    resetBenefitInput();
  };

  const updateBenefit = (benefit) => {
    setBenefits([...stepsForm[steps.COURSE_CONTENT].benefits].map(benefitItem => benefitItem.id === selectedBenefitForEdit.id ? {
      id: selectedBenefitForEdit.id,
      value: benefit
    } : benefitItem));
    resetBenefitInput();
  };

  const deleteBenefit = (benefit) => {
    setBenefits(stepsForm[steps.COURSE_CONTENT].benefits.filter(benefitItem => benefitItem.id !== benefit.id));
    resetBenefitInput();
  };

  const resetBenefitInput = () => {
    setBenefit('');
    setSelectedBenefitForEdit(null);
  };

  const formErrors = stepsFormErrors[steps.COURSE_CONTENT];

  return (
    <Grid
      item
      md={6}
      xs={12}
    >
      <TextField
        id='benefits'
        label='Who can benefit from this course'
        variant='outlined'
        fullWidth
        value={benefit}
        onChange={(e) => setBenefit(e.target.value)}
        error={formErrors.benefits.error}
        helperText={formErrors.benefits.message}
        InputProps={{
          endAdornment: (
            <>
              <WithCondition isDisplay={!selectedBenefitForEdit}>
                <InputAdornment
                  disablePointerEvents={!benefit}
                  onClick={(e) => addBenefit(benefit)}
                  position='end'
                >
                  <Fab disabled={!benefit} size='small' color='primary' aria-label='add'>
                    <AddIcon />
                  </Fab>
                </InputAdornment>
              </WithCondition>
              <WithCondition isDisplay={!!selectedBenefitForEdit}>
                <InputAdornment
                  disablePointerEvents={!benefit}
                  onClick={(e) => updateBenefit(benefit)}
                  position='end'
                >
                  <Fab disabled={!benefit} size='small' color='primary' aria-label='add'>
                    <DoneIcon />
                  </Fab>
                </InputAdornment>
                <InputAdornment
                  disablePointerEvents={!benefit}
                  onClick={(e) => resetBenefitInput()}
                  position='end'
                >
                  <Fab disabled={!benefit} size='small' color='primary' aria-label='add'>
                    <ClearIcon />
                  </Fab>
                </InputAdornment>
              </WithCondition>
            </>
          )
        }}
      />
      {
        courseContentStep.benefits.map(benefit => {
          return (
            <TextField
              key={benefit.id}
              id='benefits'
              label='Who can benefit from this course'
              variant='outlined'
              margin='normal'
              fullWidth
              value={benefit.value}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment
                      disablePointerEvents={!benefit}
                      position='end'
                      onClick={() => {
                        setSelectedBenefitForEdit(benefit);
                        setBenefit(benefit.value);
                      }}
                    >
                      <Fab disabled={!benefit} size='small' color='primary' aria-label='add'>
                        <EditIcon />
                      </Fab>
                    </InputAdornment>
                    <InputAdornment
                      position='end'
                      onClick={() => deleteBenefit(benefit)}
                    >
                      <Fab size='small' color='secondary' aria-label='add'>
                        <RemoveIcon />
                      </Fab>
                    </InputAdornment>
                  </>
                )
              }}
            />
          );
        })
      }
    </Grid>
  );

};

export default BenefitsBlock;
