import { isJson } from '../../../functions/tools';

export default {
  createEmpty: () => {
    const accessToken = localStorage.getItem('accessToken');
    const validatedToken = isJson(accessToken) ? accessToken : null;

    return {
      isAuthenticated: !!JSON.parse(validatedToken),
      accessToken: JSON.parse(validatedToken),
      authUser: {
        id: null,
        fullname: '',
        photo: '',
        professional: '',
        total_instructor_sale: '',
        student_count: '',
        role: []
      }
    };
  },
  formDefaults: {
    DEFAULT_EMAIL: 'superadmin@developer.dev',
    DEFAULT_PASSWORD: 'Developer12345'
  }
};
