import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Button
} from '@material-ui/core';

import WithCondition from 'src/components/WithCondition';

import { FeedBacksContext } from 'src/views/FeeadBacks/common/context';
import { FeedBackModal } from '../../components';

const FeedBacksListResult = ({ data = { count: 0, feedbacks: [] }, ...rest }) => {
  const {
    dataFetching: {
      queryParams,
      setQueryParams
    }
  } = useContext(FeedBacksContext);

  const [selectedFeedBackIds, setSelectedFeedBackIds] = useState([]);

  const [isOpenFeedBackModal, setIsOpenFeedBackModal] = useState(null);

  const handleSelectAll = (event) => {
    let newSelectedFeedBackIds;

    if (event.target.checked) {
      newSelectedFeedBackIds = data.feedbacks.map((feedbackItem) => feedbackItem.id);
    } else {
      newSelectedFeedBackIds = [];
    }

    setSelectedFeedBackIds(newSelectedFeedBackIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedFeedBackIds.indexOf(id);
    let newSelectedFeedBackIds = [];

    if (selectedIndex === -1) {
      newSelectedFeedBackIds = newSelectedFeedBackIds.concat(selectedFeedBackIds, id);
    } else if (selectedIndex === 0) {
      newSelectedFeedBackIds = newSelectedFeedBackIds.concat(selectedFeedBackIds.slice(1));
    } else if (selectedIndex === selectedFeedBackIds.length - 1) {
      newSelectedFeedBackIds = newSelectedFeedBackIds.concat(selectedFeedBackIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedFeedBackIds = newSelectedFeedBackIds.concat(
        selectedFeedBackIds.slice(0, selectedIndex),
        selectedFeedBackIds.slice(selectedIndex + 1)
      );
    }

    setSelectedFeedBackIds(newSelectedFeedBackIds);
  };

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedFeedBackIds.length === data.feedbacks.length}
                    color='primary'
                    indeterminate={
                      selectedFeedBackIds.length > 0
                      && selectedFeedBackIds.length < data.feedbacks.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Ad
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Feedback tipi
                </TableCell>
                <TableCell>
                  Feedback
                </TableCell>
                <TableCell>
                  Tarix
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.feedbacks.slice(0, queryParams.limit).map((feedback) => (
                <TableRow
                  hover
                  key={feedback.id}
                  selected={selectedFeedBackIds.indexOf(feedback.id) !== -1}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedFeedBackIds.indexOf(feedback.id) !== -1}
                      onChange={(event) => handleSelectOne(event, feedback.id)}
                      value='true'
                    />
                  </TableCell>
                  <TableCell>
                    {feedback.sender_name}
                  </TableCell>

                  <TableCell>
                    {feedback.sender_email}
                  </TableCell>
                  <TableCell>
                    {feedback.type}
                  </TableCell>
                  <TableCell>
                    <Tooltip style={{ maxWidth: '300px' }} title={feedback.feedback}>
                      <Button
                        onClick={() => setIsOpenFeedBackModal(feedback.feedback)}
                      >
                        {`${feedback.feedback.slice(0, 20)}...`}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {moment(feedback.created_at).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <WithCondition isDisplay={!!isOpenFeedBackModal}>
        <FeedBackModal
          onClose={() => setIsOpenFeedBackModal(null)}
          feedBack={isOpenFeedBackModal}
        />
      </WithCondition>
    </Card>
  );
};

FeedBacksListResult.defaultProps = {
  data: {
    feedbacks: [],
    count: 0
  }
};

FeedBacksListResult.propTypes = {
  data: PropTypes.object.isRequired
};

export default FeedBacksListResult;
