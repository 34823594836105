import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';

import { AuthContext } from '../common/context/auth';
import WithCondition from './WithCondition';

import Logo from './Logo';

const MainNavbar = (props) => {
  const { logout, auth } = useContext(AuthContext);

  return (
    <AppBar
      elevation={0}
      {...props}
    >
      <Toolbar sx={{ height: 64 }}>
        <RouterLink to='/'>
          <Logo />
        </RouterLink>
        <WithCondition isDisplay={auth.isAuthenticated}>
          <IconButton color='inherit' style={{ position: 'absolute', right: 0 }} onClick={logout}>
            <InputIcon />
          </IconButton>
        </WithCondition>
      </Toolbar>
    </AppBar>
  );
};

export default MainNavbar;
