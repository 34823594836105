import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Divider } from 'antd';
import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';

const SalesMonthly = () => {
  const [salesMonthly, setSalesMonthly] = useState([]);
  const years = [
    'Yanvar',
    'Fevral',
    'Mart',
    'Aprel',
    'May',
    'Iyun',
    'Iyul',
    'Avqust',
    'Sentyabr',
    'Oktyabr',
    'Noyabr',
    'Dekabr'
  ];
  const columns = [
    {
      title: 'Məbləğ',
      dataIndex: 'price',
      key: 'price',
      render: (text) => <Tag color="red">{text}</Tag>
    },
    {
      title: 'Ay',
      dataIndex: 'month',
      key: 'year',
      render: (text) => <Tag color="blue">{years[Number(text) - 1]}</Tag>
    },

    {
      title: 'İl',
      dataIndex: 'year',
      key: 'year',
      render: (text) => <>{text}</>
    }
  ];
  const columns2 = [
    {
      title: 'Say',
      dataIndex: 'count',
      key: 'count',
      render: (text) => <Tag color="red">{text}</Tag>
    },
    {
      title: 'Ay',
      dataIndex: 'month',
      key: 'year',
      render: (text) => <Tag color="blue">{years[Number(text) - 1]}</Tag>
    },

    {
      title: 'İl',
      dataIndex: 'year',
      key: 'year',
      render: (text) => <>{text}</>
    }
  ];
  useEffect(() => {
    axiosInstance.get(apiRoutes.FETCH_MODERATOR_MONTHLY_SALES()).then((res) => {
      console.log(res);
      setSalesMonthly(res?.data);
    });
  }, []);
  useEffect(() => {
    console.log('salesMonthly', salesMonthly);
  }, [salesMonthly]);

  return (
    <>
      <Divider orientation='left'>Aylara görə satılmış kurslar</Divider>
      <Table
        columns={columns}
        pagination={{
          pageSize: 5
        }}
        dataSource={salesMonthly?.months_sales}
      />
      <Divider orientation='left'>Aylara görə pulsuz kurslar</Divider>
      <Table
        columns={columns2}
        pagination={{
          pageSize: 5
        }}
        dataSource={salesMonthly?.free_course_count}
      />
    </>
  );
};
export default SalesMonthly;
