import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import Body from '../../../partials/Body';
import CustomersListBody from './partials/CustomersListBody';

const CustomersList = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Customers'>
      <CustomersListBody isLoading={isLoading}/>
    </Body>
  );
};

export default CustomersList;
