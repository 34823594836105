import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import stateCreator from 'src/views/Instructors/common/context/data/stateCreator';
import ViewList from './blocks/ViewList';

const SalesListModal = ({
                          instructorSales: {
                            queryParams,
                            setQueryParams,
                            instructor,
                            setInstructor,
                            fetchInstructorSalesQuery
                          }
                        }) => {
  const handleClose = () => {
    setQueryParams(() => stateCreator.createEmptyQuery());
    setInstructor(null);
  };


  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby='salesListModal'
      aria-describedby='salesListModalDescription'
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle id='salesListModal'>{`Sales of ${instructor?.fullname}` || 'Instructor Sales'}</DialogTitle>
      <DialogContent dividers={true}>
        <ViewList
          query={fetchInstructorSalesQuery}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SalesListModal;
