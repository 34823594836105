import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DeleteCourseModal = ({ open, closeModal, onDelete }) => {
  const onDeleteHandler = () => {
    onDelete();
    closeModal();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={closeModal}
      aria-labelledby='DeleteCourseModalLabelledby'
      aria-describedby='DeleteCourseModalDesc'
    >
      <DialogTitle id='DeleteCourseModalLabelledby'>Are you sure you want to continue?</DialogTitle>
      <DialogContent>
        <DialogContentText id='DeleteCourseModalDesc'>
          If you continue, the Course will be permanently deleted. You can click the `back` button to cancel, or
          you can click the `esc` key on the keyboard.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color='primary'>
          Back
        </Button>
        <Button
          onClick={onDeleteHandler}
          color='primary'
        >
          Yes, I'm sure to delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCourseModal;
