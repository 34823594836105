import  React, { useContext, useRef, useState } from 'react';
import pt from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { v4 as uuidv4 } from 'uuid';
import { Autocomplete, Fab, Grid, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';

import { CoursesContext } from '../../../../../../../../common/context';
import stateCreator from '../../../../../../../../common/context/data/stateCreator';
import { courseSectionTypes } from '../../../../../common/data';
import { steps } from '../../../../../common/constants';
import WithCondition from '../../../../../../../../../../components/WithCondition';

const FormFactory = ({ selectedSection }) => {
  const { create: { stepsForm, setStepsForm } } = useContext(CoursesContext);
  const [sectionForm, setSectionForm] = useState(() => stateCreator.createEmptyVideoSection());
  const [selectedSectionLectureForEdit, setSelectedSectionLectureForEdit] = useState(null);
  const sections = stepsForm[steps.CREATE_VIDEOS].sections;
  const editorRef = useRef(null);

  const selectedSectionLectures = sections.find(section => section.sectionId === selectedSection?.id)?.lectures || [];
  const maxOrderNum = Math.max(...selectedSectionLectures.map(section => parseInt(section.orderNum)));
  const [orderNum, setOrderNum] = useState(isFinite(maxOrderNum) && !!maxOrderNum ? maxOrderNum + 1 : 1);

  const courseSectionTypeProps = {
    options: courseSectionTypes,
    getOptionLabel: (option) => option.name,
    debug: 'true'
  };

  const changeSectionFormHandler = ({ fieldName, fieldValue }) => {
    setSectionForm({
      ...sectionForm,
      [fieldName]: fieldValue
    });
  };

  const setSections = (sections) => {
    setStepsForm({
      ...stepsForm,
      [steps.CREATE_VIDEOS]: {
        ...stepsForm[steps.CREATE_VIDEOS],
        sections
      }
    });
  };

  const addSection = () => {
    if (!formIsValid()) return false;

    let hasSection = sections.find(section => section.sectionId === selectedSection.id);
    const sectionItemForAdd = {
      id: uuidv4(),
      sectionId: selectedSection.id,
      ...sectionForm,
      orderNum
    };

    if (!!hasSection) {
      const alreadyExistsByOrderNum = selectedSectionLectures.find(sectionLectureItem => parseInt(sectionLectureItem.orderNum) === parseInt(orderNum));

      if (!!alreadyExistsByOrderNum) {
        const sectionItemForAdd = {
          id: uuidv4(),
          sectionId: selectedSection.id,
          ...sectionForm,
          orderNum: alreadyExistsByOrderNum.orderNum
        };
        const autoIncrementedOrderNum = Math.max(...selectedSectionLectures.map(sectionLectureItem => parseInt(sectionLectureItem.orderNum))) + 1;

        const extendedSectionLectures = [...selectedSectionLectures.map(sectionLectureItem => sectionLectureItem.id === alreadyExistsByOrderNum.id ? {
          ...alreadyExistsByOrderNum,
          orderNum: autoIncrementedOrderNum
        } : sectionLectureItem), sectionItemForAdd];

        setSections(sections.map(section => section.sectionId === hasSection.sectionId ? {
          ...hasSection,
          lectures: extendedSectionLectures
        } : section));
        resetSectionForm(autoIncrementedOrderNum, extendedSectionLectures);
      } else {
        hasSection.lectures.push(sectionItemForAdd);
        setSections(sections.map(section => section.sectionId === hasSection.sectionId ? hasSection : section));
        resetSectionForm(orderNum, hasSection.lectures);
      }

    } else {
      hasSection = { id: uuidv4(), sectionId: selectedSection.id, lectures: [sectionItemForAdd] };
      setSections([...stepsForm[steps.CREATE_VIDEOS].sections, hasSection]);
      resetSectionForm(orderNum, hasSection.lectures);
    }

  };

  const updateSection = () => {
    const hasSection = sections.find(section => section.sectionId === selectedSectionLectureForEdit.sectionId);
    const alreadyExistsByOrderNum = selectedSectionLectures.find(sectionLectureItem => parseInt(sectionLectureItem.orderNum) === parseInt(orderNum));
    const findSectionLectureForUpdate = selectedSectionLectures.find(sectionLectureItem => sectionLectureItem.id === selectedSectionLectureForEdit.id);

    if (!!alreadyExistsByOrderNum) {
      const autoIncrementedOrderNum = Math.max(...selectedSectionLectures.map(sectionLectureItem => parseInt(sectionLectureItem.orderNum)));

      const hasSectionLectures = hasSection.lectures.map(sectionLectureItem => {

        if (sectionLectureItem.id === alreadyExistsByOrderNum.id && sectionLectureItem.id !== selectedSectionLectureForEdit.id) {
          return {
            ...sectionLectureItem,
            orderNum: findSectionLectureForUpdate.orderNum
          };
        }

        if (sectionLectureItem.id === findSectionLectureForUpdate.id) {
          return {
            ...sectionLectureItem,
            ...sectionForm,
            orderNum
          };
        }

        return sectionLectureItem;
      });

      setSections(sections.map(section => section.id === hasSection.id ? {
        ...hasSection,
        lectures: hasSectionLectures
      } : section));
      resetSectionForm(autoIncrementedOrderNum, hasSectionLectures);
    } else {
      setSections(sections.map(section => section.id === hasSection.id ? {
        ...hasSection,
        lectures: selectedSectionLectures.map(sectionLectureItem => sectionLectureItem.id === selectedSectionLectureForEdit.id ? {
          ...sectionLectureItem,
          ...sectionForm,
          orderNum
        } : sectionLectureItem)
      } : section));
      resetSectionForm(orderNum, selectedSectionLectures);
    }
  };

  const resetSectionForm = (lastAddedSectionOrderNum, mutatedSectionLectures) => {
    const maxOrderNum = Math.max(...[...mutatedSectionLectures.map(section => parseInt(section.orderNum)), lastAddedSectionOrderNum]);

    setSectionForm(() => stateCreator.createEmptyVideoSection());
    setOrderNum((isFinite(maxOrderNum) && !!maxOrderNum) ? maxOrderNum + 1 : 1);
    setSelectedSectionLectureForEdit(null);
  };
  const deleteLecture = (lectureItem) => {
    const hasSection = sections.find(section => section.sectionId === lectureItem.sectionId);
    const hasSectionLecture = hasSection.lectures.filter(sectionLectureItem => sectionLectureItem.id !== lectureItem.id);
    setSections(sections.map(section => section.id === hasSection.id ? {...hasSection, lectures: hasSectionLecture} : section));

    const autoIncrementedOrderNum = Math.max(...hasSection.lectures.map(sectionLectureItem => parseInt(sectionLectureItem.orderNum)));

    resetSectionForm(autoIncrementedOrderNum, hasSection.lectures);
  };

  const { description, ...rest } = sectionForm;
  const formIsValid = () => (Object.values({ ...rest }).filter(field => field === '' || !field).length === 0 && !!orderNum);
  const getSectionLectures = () => sections.find(sectionItem => sectionItem.sectionId === selectedSection?.id)?.lectures || [];

  return (
    <>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          id='url'
          label='Url'
          variant='outlined'
          fullWidth
          name='url'
          value={sectionForm.url}
          onChange={({ target }) => changeSectionFormHandler({
            fieldName: target.name,
            fieldValue: target.value
          })}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          id='title'
          label='Title'
          variant='outlined'
          fullWidth
          name='title'
          value={sectionForm.title}
          onChange={({ target }) => changeSectionFormHandler({
            fieldName: target.name,
            fieldValue: target.value
          })}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <WithCondition isDisplay={!selectedSectionLectureForEdit}>
          <Fab
            disabled={!formIsValid()}
            size='small'
            color='primary'
            aria-label='add'
            onClick={addSection}
          >
            <AddIcon />
          </Fab>
        </WithCondition>
        <WithCondition isDisplay={!!selectedSectionLectureForEdit}>
          <Fab
            disabled={!formIsValid()}
            size='small'
            color='primary'
            aria-label='add'
            style={{ marginRight: '5px' }}
            onClick={updateSection}
          >
            <DoneIcon />
          </Fab>
          <Fab
            disabled={!formIsValid()}
            size='small'
            color='primary'
            aria-label='add'
            onClick={() => resetSectionForm(maxOrderNum, selectedSectionLectures)}
          >
            <ClearIcon />
          </Fab>
        </WithCondition>
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          id='orderNum'
          label='Order num'
          variant='outlined'
          type='number'
          fullWidth
          name='orderNum'
          value={orderNum}
          onChange={(e) => setOrderNum(e.target.value)}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <Autocomplete
          {...courseSectionTypeProps}
          value={sectionForm.type}
          name='type'
          onChange={(event, type) => changeSectionFormHandler({
            fieldName: 'type',
            fieldValue: type
          })}
          renderInput={(params) => <TextField {...params} label='Choose section type' />}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          id='duration'
          label='Duration'
          variant='outlined'
          type='number'
          fullWidth
          name='duration'
          value={sectionForm.duration}
          onChange={({ target }) => changeSectionFormHandler({
            fieldName: target.name,
            fieldValue: target.value
          })}
        />
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
      >
        <Editor
          onEditorChange={(editorContent) => changeSectionFormHandler({
            fieldName: 'description',
            fieldValue: editorContent
          })}
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue='<p>This is the initial content of the editor.</p>'
          value={sectionForm.description}
          init={{
            menubar: true,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </Grid>

      {/*mapper*/}
      {
        getSectionLectures().map(section => {
          return (
            <React.Fragment key={section.id}>
              <Grid
                item
                md={2}
                xs={2}
              >
                <TextField
                  id='url'
                  label='Url'
                  variant='outlined'
                  disabled={true}
                  fullWidth
                  name='url'
                  value={section.url}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
              >
                <TextField
                  id='title'
                  label='Title'
                  variant='outlined'
                  fullWidth
                  name='title'
                  value={section.title}
                  disabled={true}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
              >
                <TextField
                  id='orderNum'
                  label='Order num'
                  variant='outlined'
                  type='number'
                  fullWidth
                  name='orderNum'
                  value={section.orderNum}
                  disabled={true}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
              >
                <Autocomplete
                  {...courseSectionTypeProps}
                  value={section.type}
                  name='type'
                  disabled={true}
                  renderInput={(params) => <TextField {...params} label='Choose section type' />}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
              >
                <TextField
                  id='duration'
                  label='Duration'
                  variant='outlined'
                  type='number'
                  fullWidth
                  name='duration'
                  value={section.duration}
                  disabled={true}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
              >
                <TextField
                  disabled={true}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment
                          position='end'
                          onClick={() => {
                            setSelectedSectionLectureForEdit(section);
                            setSectionForm(section);
                            setOrderNum(section.orderNum);
                          }}
                        >
                          <Fab size='small' color='primary' aria-label='add'>
                            <EditIcon />
                          </Fab>
                        </InputAdornment>
                        <InputAdornment
                          position='end'
                        >
                          <Fab
                            size='small'
                            color='secondary'
                            aria-label='add'
                            onClick={() => deleteLecture(section)}
                          >
                            <RemoveIcon />
                          </Fab>
                        </InputAdornment>
                      </>
                    )
                  }}
                />
              </Grid>
            </React.Fragment>
          );
        })
      }
      {/*/mapper*/}
    </>
  );
};

FormFactory.propTypes = {
  selectedSection: pt.any
};

export default FormFactory;
