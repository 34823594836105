export default {
  createEmpty: () => ({
    id: '',
    type: null,
    promoCode: '',
    status: true,
    discount: '',
    usageLimit: '',
    expiredAt: '',
    primaryKeys: null
  }),
  createEmptyQuery: () => ({
    page: 0,
    limit: 10,
    orderBy: 'id',
    order: 'desc'
  }),
  createEmptyFormErrors: () => ({
    type: {
      error: false,
      message: ''
    },
    promoCode: {
      error: false,
      message: ''},
    status: {
      error: false,
      message: ''
    },
    discount: {
      error: false,
      message: ''
    },
    expiredAt: {
      error: false,
      message: ''
    },
    primaryKeys: {
      error: false,
      message: ''
    }
  })
};
