import React, { useRef, useContext, useEffect, useState } from 'react';
import pt from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, Grid, TextField } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';

import View from 'src/components/view';
import WithCondition from 'src/components/WithCondition';

import { InstructorContext } from 'src/views/Instructors/common/context';
import { SocialAccountsModal } from 'src/views/Instructors/ui/components';
import { createYupSchema } from 'src/views/Instructors/ui/pages/InstructorForm/common/utils/schema';
import InstructorFormToolBar from '../blocks/InstructorFormToolBar';
import { EDITOR_INITIALIZATION } from '../../common/constants';
import InstructorFormActions from '../blocks/InstructorFormActions';

const InstructorFormBody = ({ isLoading: isRbacLoading }) => {
  const editorRef = useRef(null);
  const [isOpenSocialModal, setIsOpenSocialModal] = useState(false);

  const {
    isNewRecord,
    dataFetching: {
      fetchInstructorDetailQuery
    },
    create: {
      saveInstructorHandler,
      createInstructorMutation,
      setSocialAccounts
    },
    update: {
      updateInstructorMutation
    }
  } = useContext(InstructorContext);

  const { handleSubmit, control, formState: { errors }, reset, register } = useForm({
    resolver: yupResolver(createYupSchema(isNewRecord)),
    defaultValues: {
      fullname: '',
      email: '',
      password: '',
      profession: '',
      photo: null,
      about: ''
    }
  });

  const resetInstructorForm = () => reset({
    fullname: '',
    email: '',
    password: '',
    profession: '',
    photo: null,
    about: ''
  });

  const saveInstructorForm = (data, operation) => {
    saveInstructorHandler({ requestData: data, operation, reset: resetInstructorForm });
  };

  useEffect(() => {
    if (!isNewRecord && !!fetchInstructorDetailQuery.data) {
      const { socialAccounts, ...rest } = fetchInstructorDetailQuery.data;
      const socials = {};

      socialAccounts.forEach(socialAccount => {
        socials[socialAccount.social] = socialAccount.url;
      });
      setSocialAccounts(socials);

      reset({
        ...rest,
        photo: '',
        password: ''
      });
    }
  }, [isNewRecord, fetchInstructorDetailQuery.data]);

  return (
    <View isLoading={fetchInstructorDetailQuery.isLoading || updateInstructorMutation.isLoading || isRbacLoading}>
      <InstructorFormToolBar />
      <Box sx={{ pt: 3 }}>
        <form>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Controller
                    control={control}
                    name='fullname'
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        fullWidth
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.fullname}
                        helperText={errors.fullname?.message}
                        name='fullname'
                        placeholder='Full name'
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Controller
                    control={control}
                    name='email'
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        fullWidth
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        name='email'
                        placeholder='Email'
                      />
                    )}
                  />
                </Grid>
                <WithCondition isDisplay={isNewRecord}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Controller
                      control={control}
                      name='password'
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          fullWidth
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!errors.password}
                          helperText={errors.password?.message}
                          name='password'
                          placeholder='Password'
                        />
                      )}
                    />
                  </Grid>
                </WithCondition>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Controller
                    control={control}
                    name='profession'
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        fullWidth
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.profession}
                        helperText={errors.profession?.message}
                        name='profession'
                        placeholder='Profession'
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Button
                    variant='contained'
                    component='label'
                    color={errors.photo ? 'secondary' : 'primary'}
                    startIcon={<CloudUploadIcon />}
                  >
                    Photo
                    <input
                      type='file'
                      name='photo'
                      {...register('photo')}
                      hidden
                    />
                  </Button>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Button
                    variant='contained'
                    component='label'
                    color={errors.photo ? 'secondary' : 'primary'}
                    onClick={() => setIsOpenSocialModal(true)}
                    startIcon={<AddIcon />}
                  >
                    Social Accounts
                  </Button>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Controller
                    control={control}
                    name='about'
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Editor
                        onEditorChange={onChange}
                        onBlur={onBlur}
                        apiKey='yyh0vrmw7mw24cafluttia9lllkxg6mdq1ywjpffuqee2mwb'
                        value={value}
                        error={true}
                        name='about'
                        onInit={(evt, editor) => editorRef.current = editor}
                        init={EDITOR_INITIALIZATION}
                      />
                    )}
                  />
                </Grid>

                <InstructorFormActions
                  handleSubmit={handleSubmit}
                  saveInstructorForm={saveInstructorForm}
                  isLoading={createInstructorMutation.isLoading}
                  isNewRecord={isNewRecord}
                />
              </Grid>
            </CardContent>
          </Card>
          <WithCondition isDisplay={isOpenSocialModal}>
            <SocialAccountsModal
              onClose={() => setIsOpenSocialModal(false)}
            />
          </WithCondition>
        </form>
      </Box>
    </View>
  );
};

InstructorFormBody.defaultProps = {
  isLoading: false
};

InstructorFormBody.propTypes = {
  isLoading: pt.bool
};

export default InstructorFormBody;
