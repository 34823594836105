import useBlogs from './hooks/useBlogs';

import BlogsContext from './BlogsContext';

const BlogsProvider = ({ children }) => {
  const contextData = useBlogs();

  return (
    <BlogsContext.Provider value={contextData}>{children}</BlogsContext.Provider>
  );
};

export default BlogsProvider;
