import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import queryKeys from 'src/common/queryKeys';

import stateCreator from 'src/views/Courses/ui/pages/HRCourses/common/context/data/stateCreator';
import { fetchHRCourseDetail, fetchHRCoursesFlow, fetchHRCourseUsersFlow } from '../../api';

const useHRCourses = () => {
  const { id: courseId } = useParams();

  const [queryParams, setQueryParams] = useState(() => stateCreator.createEmptyQuery());

  const { isLoading, error, data } = useQuery({
    queryKey: [queryKeys.FETCH_HR_COURSES_FLOW, queryParams],
    queryFn: () => fetchHRCoursesFlow(queryParams)
  });

  const fetchCourseDetailQuery = useQuery({
    queryKey: [queryKeys.FETCH_COURSE_DETAIL, courseId],
    queryFn: () => fetchHRCourseDetail(courseId),
    enabled: !!courseId
  });

  const fetchHRCourseUsersQuery = useQuery({
    queryKey: [queryKeys.FETCH_HR_COURSE_USERS, courseId],
    queryFn: () => fetchHRCourseUsersFlow(courseId),
    enabled: !!courseId && !!fetchCourseDetailQuery.data
  });

  return {
    dataFetching: {
      isLoading,
      error,
      data,
      queryParams,
      setQueryParams,
      fetchCourseDetailQuery,
      fetchHRCourseUsersQuery
    }
  };
};

export default useHRCourses;
