import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const UserQuizzesModalTable = ({ quizzes }) => {
  const classes = useStyles();

  const getCorrectionPercent = (row) =>
    Math.floor(parseInt(row.correct_answers) * 100 / parseInt(row.question_count));

  return (
    <TableContainer>
      <Table className={classes.table} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>Quiz adı</TableCell>
            <TableCell>Ümumi sual sayı</TableCell>
            <TableCell>Doğru cavablar</TableCell>
            <TableCell>Yanlış cavablar</TableCell>
            <TableCell>Nəticə</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quizzes.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell component='th' scope='row'>
                  {row.quiz_name}
                </TableCell>
                <TableCell>{row.question_count}</TableCell>
                <TableCell>{row.correct_answers}</TableCell>
                <TableCell>{`${parseInt(row.question_count) - parseInt(row.correct_answers)}`}</TableCell>
                <TableCell>{getCorrectionPercent(row)} %</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserQuizzesModalTable;
