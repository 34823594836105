import React, { useRef, useContext, useEffect } from 'react';
import pt from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import View from 'src/components/view';

import { BlogsContext } from 'src/views/Blogs/common/context';
import { createYupSchema } from 'src/views/Blogs/ui/pages/BlogsForm/common/utils/schema';
import BlogFormToolBar from '../blocks/BlogsFormToolBar';
import BlogFormActions from '../blocks/BlogsFormActions';
import { EDITOR_INITIALIZATION } from '../../common/constants';

const BlogsFormBody = ({ isLoading: isRbacLoading }) => {
  const editorRef = useRef(null);

  const {
    isNewRecord,
    dataFetching: {
      fetchBlogDetailQuery
    },
    create: {
      saveBlogHandler,
      createBlogMutation
    },
    update: {
      updateBlogMutation
    }
  } = useContext(BlogsContext);

  const { handleSubmit, control, formState: { errors }, reset, register } = useForm({
    resolver: yupResolver(createYupSchema(isNewRecord)),
    defaultValues: {
      title: '',
      p_image: null,
      blog: '',
      status: true
    }
  });

  const resetBlogForm = () => reset({
    title: '',
    p_image: null,
    blog: '',
    status: true
  });

  const saveBlogForm = (data, operation) => {
    saveBlogHandler({ requestData: data, operation, reset: resetBlogForm });
  };

  useEffect(() => {
    if (!isNewRecord && !!fetchBlogDetailQuery.data) {
      const blogDetailData = fetchBlogDetailQuery.data;
      reset({...blogDetailData, p_image: ''});
    }
  }, [isNewRecord, fetchBlogDetailQuery.data]);

  return (
    <View isLoading={fetchBlogDetailQuery.isLoading || updateBlogMutation.isLoading || isRbacLoading}>
      <BlogFormToolBar />
      <Box sx={{ pt: 3 }}>
        <form>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Controller
                    control={control}
                    name='title'
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        fullWidth
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        name='title'
                        placeholder='Title'
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={6}
                >
                  <FormControl fullWidth variant='outlined'>
                    <InputLabel id='demo-simple-select-outlined-label'>Status</InputLabel>
                    <Controller
                      control={control}
                      name='status'
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          labelId='statusSelect'
                          id='statusSelectOutlined'
                          label='Status'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        >
                          <MenuItem value={true}>Active</MenuItem>
                          <MenuItem value={false}>Inactive</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Button
                    variant='contained'
                    component='label'
                    color={errors.p_image ? 'secondary' : 'primary'}
                    startIcon={<CloudUploadIcon />}
                  >
                    Image
                    <input
                      type='file'
                      name='p_image'
                      {...register('p_image')}
                      hidden
                    />
                  </Button>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Controller
                    control={control}
                    name='blog'
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Editor
                        onEditorChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        error={true}
                        name='blog'
                        onInit={(evt, editor) => editorRef.current = editor}
                        init={EDITOR_INITIALIZATION}
                      />
                    )}
                  />
                </Grid>

                <BlogFormActions
                  handleSubmit={handleSubmit}
                  saveBlogForm={saveBlogForm}
                  isLoading={createBlogMutation.isLoading}
                  isNewRecord={isNewRecord}
                />
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Box>
    </View>
  );
};

BlogsFormBody.defaultProps = {
  isLoading: false
};

BlogsFormBody.propTypes = {
  isLoading: pt.bool
};

export default BlogsFormBody;
