import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';

export const fetchPromoCodes = (requestData) =>
  axiosInstance.get(apiRoutes.FETCH_PROMO_CODES(), { params: requestData }).then(res => res.data);

export const fetchPromoCodeDetail = (id) =>
  axiosInstance.get(apiRoutes.FETCH_PROMO_CODE_DETAIL(id)).then(res => res.data);

export const createPromoCode = (requestData) =>
  axiosInstance.post(apiRoutes.CREATE_PROMO_CODE(), requestData).then(res => res.data);

export const changePromoCodeStatus = (promoCodeId) =>
  axiosInstance.post(apiRoutes.CHANGE_PROMO_CODE_STATUS(promoCodeId), {}).then(res => res.data);

export const updatePromoCode = ({ id, requestData }) =>
  axiosInstance.put(apiRoutes.UPDATE_PROMO_CODE(id), requestData).then(res => res.data);

export const deletePromoCode = (id) =>
  axiosInstance.delete(apiRoutes.DELETE_PROMO_CODE(id)).then(res => res.data);

export const fetchPromoCodeCourses = (id, requestData) =>
  axiosInstance.get(apiRoutes.FETCH_PROMO_CODE_COURSES(id), { params: requestData }).then(res => res.data);

export const fetchPromoCodeUsages = (id, requestData) =>
  axiosInstance.get(apiRoutes.FETCH_PROMO_CODE_USAGES(id), { params: requestData }).then(res => res.data);
