import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/views/Account';
import Dashboard from 'src/views/Dashboard/Dashboard';
import Login from 'src/views/Login/Login';
import NotFound from 'src/views/NotFound/NotFound';
import Settings from 'src/views/Settings';

import coursesRoutes from './views/Courses';
import instructorsRoutes from './views/Instructors';
import salesRoutes from './views/Sales';
import instructorRequestsRoutes from './views/InstructorRequests';
import feedBacksRoutes from './views/FeeadBacks';
import customersRoutes from './views/Customers';
import promoCodesRoutes from './views/PromoCodes';
import blogsRoutes from './views/Blogs';
import hrRoutes from './views/HR';
import Statistic from './views/Statistics/Statistic';
import BannerUpdate from './views/Banner/BannerUpdate';
import CoursesAddUsers from './CoursesAddUsers/CoursesAddUsers';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      ...coursesRoutes,
      ...instructorsRoutes,
      ...hrRoutes,
      ...salesRoutes,
      ...instructorRequestsRoutes,
      ...feedBacksRoutes,
      ...customersRoutes,
      ...promoCodesRoutes,
      ...blogsRoutes,
      { path: 'account', element: <Account /> },
      { path: 'statistics', element: <Statistic /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'settings', element: <Settings /> },
      { path: 'course-add-user', element: <CoursesAddUsers /> },
      { path: 'banner', element: <BannerUpdate /> },
      { path: '*', element: <Navigate to='/404' /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'authentication/login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to='/app/dashboard' /> },
      { path: '*', element: <Navigate to='/404' /> }
    ]
  }
];

export default routes;
