import {rights} from 'src/common/constants';
import useRBAC from 'src/common/hooks/useRBAC';

import {HRCoursesBody} from '../../../partials';
import {HRCourseDetailBody} from './partials';

const HRCoursesDetail = () => {
  const {isLoading} = useRBAC({rights: [rights.HR]});

  return (
    <HRCoursesBody title='Human Resource Courses Detail'>
      <HRCourseDetailBody isLoading={isLoading} />
    </HRCoursesBody>
  );
};

export default HRCoursesDetail;
