import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import axiosInstance from 'src/common/config/api';
import Snackbar from '@mui/material/Snackbar';
import InfluencerAdd from './InfluencerAdd';

export default function Influencer() {
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [hrList, setHrList] = useState();
  const [addVisible, setAddVisible] = useState(false);
  const [hrId, setHrId] = useState();
  const [message, setMessage] = useState();
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  useEffect(() => {
    getInfluencer()
    getInfluencerDetail()
  }, [addVisible]);

const getInfluencer = () => {
    axiosInstance.get('customers/influencer/detail').then((res) => {
        setData(res?.data?.data[0])
        // console.log(res?.data?.data[0])
        });
}
const getInfluencerDetail = () => {
  axiosInstance.get('customers/influencer/detail-list').then((res) => {
      setDataDetail(res?.data?.data)
      console.log(res?.data?.data)
      });
}
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;

  return (
    <div>
        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      />
      
      <Box sx={{ minWidth: 1050 }}>
      <h2 style={{color:"#404040", marginLeft:"10px",padding:"10px"}}>Ümumi statistika</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ad, Soyad</TableCell>
              <TableCell>Faiz</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Keçid sayı</TableCell>
              <TableCell>Url</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell>{item?.fullname}</TableCell>

                <TableCell>{item?.percent}</TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell>{item?.click_count}</TableCell>
                <TableCell>
                <a href={"https://safavy.org/influencer?token=" + (item?.token)}>Influencer url</a></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <h2 style={{color:"#404040", marginLeft:"10px",padding:"10px"}}>Satış statistikası</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ad, Soyad</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Alış sayı</TableCell>
              <TableCell>Ümumi gəlir</TableCell>
              <TableCell>İnf. gəliri</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataDetail?.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell>{item?.fullname}</TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell>{item?.count_payment}</TableCell>
                <TableCell>{item?.sum_price}</TableCell>
                <TableCell>{item?.paymnet_benefit}</TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  );
}
