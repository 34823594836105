import Body from '../../../partials/Body';

import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import InstructorRequestsListBody from './partials/InstructorRequestsListBody';

const InstructorRequestsList = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Instructor Requests'>
      <InstructorRequestsListBody isLoading={isLoading} />
    </Body>
  );
};

export default InstructorRequestsList;
