import FeedBacksContext from './FeedBacksContext';

import useFeedBacks from './hooks/useFeedBacks';

const FeedBacksProvider = ({ children }) => {
  const contextData = useFeedBacks();

  return (
    <FeedBacksContext.Provider value={contextData}>
      {children}
    </FeedBacksContext.Provider>
  );
};

export default FeedBacksProvider;
