import * as yup from 'yup';

export const createYupSchema = (isNewRecord) => {
  const schema = {
    title: yup.string().min(4).max(256).required('Title is required'),
    p_image: yup.mixed()
  };

  if (isNewRecord) {
    schema.p_image = yup.mixed().required('Image is required');
  }

  return yup.object().shape(schema);
};
