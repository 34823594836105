import React from 'react';
import pt from 'prop-types';
import { Autocomplete, Button, Grid, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { preventSpecialChars } from 'src/common/functions/tools';
import WithCondition from 'src/components/WithCondition';

import {PasswordField} from '../../components';

const signatureStyles = {
  fontSize: '11px',
  textDecoration: 'underline'
};

const HRFormIntroBlock = ({ formValues, formErrors, formController, fetchCoursesQuery, isNewRecord }) => {
  return (
    <>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          fullWidth
          placeholder='Full name'
          name='fullname'
          value={formValues.fullname}
          onChange={e => formController({ [e.target.name]: preventSpecialChars(e.target.value) })}
          error={formErrors.fullname.error}
          helperText={formErrors.fullname.error ? formErrors.fullname.message : ''}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          fullWidth
          placeholder='Email'
          type='email'
          name='email'
          value={formValues.email}
          onChange={e => formController({ [e.target.name]: e.target.value })}
          error={formErrors.email.error}
          helperText={formErrors.email.error ? formErrors.email.message : ''}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <InputMask
          mask='(99) 999 99 99'
          value={formValues.phone_number}
          disabled={false}
          name='phone_number'
          onChange={e => formController({ [e.target.name]: e.target.value })}
          maskChar='9'
        >
          {
            (inputProps) => (
              <TextField
                {...inputProps}
                fullWidth
                placeholder='Phone number'
                value={formValues.phone_number}
                error={formErrors.phone_number.error}
                helperText={formErrors.phone_number.error ? formErrors.phone_number.message : ''}
              />
            )
          }
        </InputMask>
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          fullWidth
          placeholder='Company name'
          name='company_name'
          value={formValues.company_name}
          onChange={e => formController({ [e.target.name]: e.target.value })}
          error={formErrors.company_name.error}
          helperText={formErrors.company_name.error ? formErrors.company_name.message : ''}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          fullWidth
          placeholder='Max user count'
          type='number'
          InputProps={{ inputProps: { min: 0 } }}
          name='max_user_count'
          value={formValues.max_user_count}
          onChange={e => formController({ [e.target.name]: e.target.value })}
          error={formErrors.max_user_count.error}
          helperText={formErrors.max_user_count.error ? formErrors.max_user_count.message : ''}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          fullWidth
          placeholder='Total payment'
          type='number'
          name='total_payment'
          InputProps={{ inputProps: { min: 0 } }}
          value={formValues.total_payment}
          onChange={e => formController({ [e.target.name]: e.target.value })}
          error={formErrors.total_payment.error}
          helperText={formErrors.total_payment.error ? formErrors.total_payment.message : ''}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <Autocomplete
          multiple
          limitTags={1}
          id='hrCourses'
          loading={fetchCoursesQuery.isLoading}
          options={fetchCoursesQuery.data || []}
          value={formValues.courses}
          getOptionLabel={(option) => `${option.title} / ${option.instructor_name}`}
          onChange={(e, T) => formController({ 'courses': T })}
          renderInput={(params) => (
            <TextField
              {...params}
              label='HR Courses'
              placeholder='HR Courses'
              error={formErrors.courses.error}
              helperText={formErrors.courses.error ? formErrors.courses.message : ''}
            />
          )}
        />
      </Grid>
      <WithCondition isDisplay={isNewRecord}>
        <Grid
          item
          md={4}
          xs={12}
        >
          <PasswordField
            fullWidth
            placeholder='Password'
            name='password'
            InputProps={{ inputProps: { min: 0 } }}
            value={formValues.password}
            onChange={e => formController({ [e.target.name]: e.target.value })}
            error={formErrors.password.error}
            helperText={formErrors.password.error ? formErrors.password.message : ''}
          />
        </Grid>
      </WithCondition>
      <Grid
        item
        md={4}
        xs={12}
      >
        <Button
          variant='contained'
          component='label'
          startIcon={<CloudUploadIcon />}
        >
          Signature
          <input
            type='file'
            name='signature'
            hidden
            onChange={e => formController({ [e.target.name]: e.target.files[0] })}
          />
        </Button>
        <WithCondition isDisplay={!isNewRecord && !!formValues.uploaded_signature}>
          <p style={signatureStyles}>
            <a download target='_blank' href={formValues.uploaded_signature}>Look at signature</a>
          </p>
        </WithCondition>
      </Grid>
    </>
  );
};

HRFormIntroBlock.propTypes = {
  formValues: pt.object.isRequired,
  formErrors: pt.object.isRequired,
  formController: pt.func.isRequired,
  isNewRecord: pt.bool
};

export default HRFormIntroBlock;
