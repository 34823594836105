import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { QueryClientProvider } from 'react-query';
import {SnackbarProvider} from 'notistack';

import 'src/assets/mixins/chartjs';
import theme from 'src/common/config/theme';
import routes from 'src/routes';
import GlobalStyles from 'src/components/GlobalStyles';
import queryClient from './common/config/query-client';
import { AuthProvider } from './common/context/auth';

const App = () => {
  const routing = useRoutes(routes);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={10}>
        <AuthProvider>
            <GlobalStyles />
            {routing}
        </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
