import { useState } from 'react';
import { useQuery } from 'react-query';

import queryKeys from 'src/common/queryKeys';

import stateCreator from '../data/stateCreator';
import { fetchFeedBacks } from '../../api';

const useFeedBacks = () => {
  const [queryParams, setQueryParams] = useState(() => stateCreator.createEmptyQuery());

  const fetchFeedBacksQuery = useQuery({
    queryKey: [queryKeys.FETCH_FEED_BACKS, queryParams],
    queryFn: () => fetchFeedBacks(queryParams),

  });

  return {
    dataFetching: {
      queryParams,
      setQueryParams,
      fetchFeedBacksQuery
    }
  };

};

export default useFeedBacks;
