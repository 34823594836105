import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import queryKeys from 'src/common/queryKeys';
import { privateRoutes } from 'src/common/mainRoutes';
import { formOperations } from 'src/common/constants';

import stateCreator from '../data/stateCreator';
import useInstructorSales from './useInstructorSales';
import {
  createInstructor,
  deleteInstructor,
  fetchAllInstructors,
  fetchInstructorDetail,
  updateInstructor
} from '../../api';

const useInstructors = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const instructorSales = useInstructorSales();

  const [queryParams, setQueryParams] = useState(stateCreator.createEmptyQuery());
  const [socialAccounts, setSocialAccounts] = useState(stateCreator.createEmptySocialAccounts);

  const fetchAllInstructorsQuery = useQuery([queryKeys.FETCH_ALL_INSTRUCTORS, queryParams], () => fetchAllInstructors(queryParams));

  const fetchInstructorDetailQuery = useQuery({
    queryKey: [queryKeys.FETCH_INSTRUCTOR_DETAIL, id],
    queryFn: () => fetchInstructorDetail(id),
    enabled: !!id,
    cacheTime: 0,
    staleTime: 0
  });

  const createInstructorMutation = useMutation({
    mutationKey: queryKeys.CREATE_INSTRUCTOR,
    mutationFn: createInstructor
  });

  const updateInstructorMutation = useMutation({
    mutationKey: queryKeys.UPDATE_INSTRUCTOR,
    mutationFn: updateInstructor
  });

  const saveInstructorFormFactory = ({ operation, data, reset }) => {
    switch (operation) {
      case formOperations.SAVE:
        setTimeout(() => navigate(privateRoutes.INSTRUCTORS), 100);
        break;
      case formOperations.SAVE_AND_CONTINUE_CREATE:
        reset();
        break;
      case formOperations.SAVE_AND_CONTINUE_EDIT:
        setTimeout(() => navigate(`${privateRoutes.INSTRUCTORS_FORM}/${data.id}`), 100);
        break;
      default:
        return false;
    }
  };

  const saveInstructorHandler = ({ requestData, operation, reset, callback = null }) => {
    const { fullname, email, password, profession, photo, about } = requestData;
    const formData = new FormData();

    const socials = JSON.stringify(Object.keys(socialAccounts).map(social => ({
      social,
      url: socialAccounts[social]
    })));

    formData.append('fullname', fullname);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('profession', profession);
    formData.append('photo', photo[0]);
    formData.append('about', about);
    formData.append('socialAccounts', socials);

    if (!!id) {
      formData.append('id', id);
      updateInstructorMutation.mutate(formData, {
        onSuccess: data => {
          enqueueSnackbar('Instructor updated was successfully', { variant: 'success' });
          saveInstructorFormFactory({ operation, data, reset });
          !!callback && callback();
        },
        onError: error => {
          enqueueSnackbar('An error occurred.', { variant: 'error' });
        }
      });
      return false;
    }

    createInstructorMutation.mutate(formData, {
      onSuccess: data => {
        enqueueSnackbar('Instructor created was successfully', { variant: 'success' });
        saveInstructorFormFactory({ operation, data, reset });
        !!callback && callback();
      },
      onError: error => {
        enqueueSnackbar('An error occurred.', { variant: 'error' });
      }
    });
  };

  const deleteInstructorMutation = useMutation({
    mutationKey: queryKeys.DELETE_INSTRUCTOR,
    mutationFn: deleteInstructor,
    onSuccess: data => {
      enqueueSnackbar('Instructor deleted was successfully', { variant: 'success' });
      queryClient.invalidateQueries(queryKeys.FETCH_ALL_INSTRUCTORS);
    },
    onError: error => {
      enqueueSnackbar('An error occurred.', { variant: 'error' });
    }
  });

  const deleteInstructorHandler = (id) => deleteInstructorMutation.mutate({ id });

  return {
    isNewRecord: !id,
    dataFetching: {
      fetchAllInstructorsQuery,
      queryParams,
      setQueryParams,
      fetchInstructorDetailQuery
    },
    create: {
      saveInstructorHandler,
      createInstructorMutation,
      socialAccounts,
      setSocialAccounts
    },
    update: {
      updateInstructorMutation
    },
    delete: {
      deleteInstructorMutation,
      deleteInstructorHandler
    },
    instructorSales
  };
};

export default useInstructors;
