import * as yup from 'yup';

export const createYupSchema = (isNewRecord) => {
  const schema = {
    fullname: yup.string().min(4).max(64).required('Full name is required'),
    profession: yup.string().min(3).max(128).required('Profession is required'),
    email: yup.string().email().required('Email is required'),
    photo: yup.mixed()
  };

  if (isNewRecord) {
    schema.password = yup.string().min(6).max(20).required('Password is required');
    schema.photo = yup.mixed().required('Photo is required');
  }

  return yup.object().shape(schema);
};
