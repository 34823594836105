import { storageKeys } from '../constants';
import axiosInstance from '../config/api';

export const parseJWT = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const isJson = (item) => {
  if (!item) return item;
  return /^[\],:{}\s]*$/.test(item.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
};

export const clearInvalidStorage = () => {
  localStorage.removeItem(storageKeys.AUTH_USER);
  localStorage.removeItem(storageKeys.ACCESS_TOKEN);
};

export const setAuthorizationToken = (token) => {
  axiosInstance.defaults.headers.common = { 'Authorization': `bearer ${token}` };
};

export const deleteAuthorizationToken = () => {
  if (axiosInstance.defaults.headers.common?.Authorization) {
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};


export const toCapitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const preventSpecialChars = (txt = '') => txt.replace(/[`~0-9!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');

export const removeSpecialCharsFromStr = (txt) => txt.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/gi, '');
