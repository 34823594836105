import { useContext } from 'react';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { HRCoursesContext } from 'src/views/Courses/ui/pages/HRCourses/common/context';
import { HRCoursesListToolBar, HRCoursesListResults } from '../../blocks';

const HRCoursesListBody = ({ isLoading: isRbacLoading }) => {
  const { dataFetching: {
    isLoading,
    error,
    data,
    queryParams,
    setQueryParams,
  } } = useContext(HRCoursesContext);

  return (
    <View isLoading={isLoading || isRbacLoading}>
      <HRCoursesListToolBar />
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={error}>
          <HRCoursesListResults queryParams={queryParams} setQueryParams={setQueryParams} data={data} />
        </ErrorBoundary>
      </Box>
    </View>
  );
};

export default HRCoursesListBody;
