import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';

export const fetchCourses = (query) =>
  axiosInstance.get(apiRoutes.FETCH_COURSES(), { params: query }).then(res => res.data);

export const fetchInstructors = () =>
  axiosInstance.get(apiRoutes.FETCH_INSTRUCTORS()).then(res => res.data);

export const fetchCategories = () =>
  axiosInstance.get(apiRoutes.FETCH_CATEGORIES()).then(res => res.data);

export const createCourse = (requestData) =>
  axiosInstance.post(apiRoutes.CREATE_COURSE(), requestData).then(res => res.data);

export const addCourseLectures = (requestData) =>
  axiosInstance.post(apiRoutes.ADD_COURSE_LECTURES(), requestData).then(res => res.data);

export const addCourseQuizzes = ({ courseId, requestData }) =>
  axiosInstance.post(apiRoutes.ADD_COURSE_QUIZZES(courseId), requestData).then(res => res.data);

export const changeCourseStatus = (courseId) =>
  axiosInstance.post(apiRoutes.CHANGE_COURSE_STATUS(courseId), {}).then(res => res.data);

export const deleteCourse = (courseId) =>
  axiosInstance.delete(apiRoutes.DELETE_COURSE(courseId)).then(res => res.data);

export const fetchCourseInformation = (courseId) =>
  axiosInstance.get(apiRoutes.FETCH_COURSE_INFORMATION(courseId)).then(res => res.data);

export const updateCourseInformation = ({ id, requestData }) =>
  axiosInstance.post(apiRoutes.UPDATE_COURSE_INFORMATION(id), requestData).then(res => res.data);

export const fetchAllCoursesTitle = () =>
  axiosInstance.get(apiRoutes.FETCH_ALL_COURSES_TITLE()).then(res => res.data);
