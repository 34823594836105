import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import queryKeys from 'src/common/queryKeys';
import { formOperations } from 'src/common/constants';
import { privateRoutes } from 'src/common/mainRoutes';

import { formLoader, formValidator } from 'src/views/HR/ui/pages/HRForm/common/functions/tools';
import stateCreator from '../data/stateCreator';
import {
  deleteHR,
  fetchHRList,
  createHR,
  updateHR,
  fetchHRDetail
} from '../../api';

const useHR = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [queryParams, setQueryParams] = useState(() => stateCreator.createEmptyQuery());
  const [formValues, setFormValues] = useState(() => stateCreator.createEmptyForm());
  const [formErrors, setFormErrors] = useState(() => stateCreator.createEmptyFormErrors());

  const fetchHRListQuery = useQuery({
    queryKey: [queryParams, queryKeys.FETCH_HR_LIST],
    queryFn: () => fetchHRList(queryParams)
  });

  const deleteHRMutation = useMutation({
    mutationKey: queryKeys.DELETE_HR,
    mutationFn: deleteHR,
    onSuccess: data => {
      enqueueSnackbar('HR successfully deleted', { variant: 'success' });
      queryClient.invalidateQueries(queryKeys.FETCH_HR_LIST);
    },
    onError: error => {
      enqueueSnackbar('An error occurred.', { variant: 'error' });
    }
  });

  const deleteHRHandler = (id) => deleteHRMutation.mutate(id);

  const fetchHRDetailQuery = useQuery({
    queryKey: [queryKeys.FETCH_HR_DETAIL, id],
    queryFn: () => fetchHRDetail(id),
    onSuccess: data => {
      setFormValues(prevState => ({
        ...prevState,
        ...data,
        uploaded_signature: data.signature
      }));
    },
    enabled: !!id,
    cacheTime: 0,
    staleTime: 0
  });

  const saveHRFormFactory = ({ operation, data, reset }) => {
    switch (operation) {
      case formOperations.SAVE:
        setTimeout(() => navigate(privateRoutes.HR), 100);
        break;
      case formOperations.SAVE_AND_CONTINUE_CREATE:
        reset();
        break;
      case formOperations.SAVE_AND_CONTINUE_EDIT:
        setTimeout(() => navigate(`${privateRoutes.HR_FORM}/${data.id}`), 100);
        break;
      default:
        return false;
    }
  };

  const createHRMutation = useMutation({
    mutationKey: queryKeys.CREATE_HR,
    mutationFn: createHR
  });

  const updateHRMutation = useMutation({
    mutationKey: queryKeys.UPDATE_HR,
    mutationFn: updateHR
  });

  const saveHRHandler = ({ operation, reset, callback = null }) => {
    if (!formValidator({ formValues, setFormErrors, isNewRecord: !id })) return false;
    const formData = formLoader(formValues);

    if (!!id) {
      updateHRMutation.mutate({ id, formData }, {
        onSuccess: data => {
          enqueueSnackbar('Human Resource updated was successfully', { variant: 'success' });
          saveHRFormFactory({ operation, data, reset });
          !!callback && callback();
        },
        onError: error => {
          enqueueSnackbar('An error occurred.', { variant: 'error' });
        }
      });
      return false;
    }

    createHRMutation.mutate(formData, {
      onSuccess: data => {
        enqueueSnackbar('Human Resource successfully created', { variant: 'success' });
        saveHRFormFactory({ operation, data, reset });
        !!callback && callback();
      },
      onError: error => {
        enqueueSnackbar('An error occurred.', { variant: 'error' });
      }
    });
  };

  const formController = (updatedFields) => {
    setFormValues(prevState => ({
      ...prevState,
      ...updatedFields
    }));
  };

  return {
    isNewRecord: !id,
    form: {
      formValues,
      setFormValues,
      formErrors,
      setFormErrors,
      formController
    },
    dataFetching: {
      queryParams,
      setQueryParams,
      fetchHRListQuery,
      fetchHRDetailQuery
    },
    create: {
      saveHRHandler,
      createHRMutation
    },
    update: {
      updateHRMutation
    },
    delete: {
      deleteHRMutation,
      deleteHRHandler
    }
  };
};

export default useHR;
