import useHRCourses from './hooks/useHRCourses';
import HRCoursesContext from './HRCoursesContext';

const HRCoursesProvider = ({children}) => {
  const contextData = useHRCourses();

  return (
    <HRCoursesContext.Provider value={contextData}>
      {children}
    </HRCoursesContext.Provider>
  )
};

export default HRCoursesProvider;
