import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Button
} from '@material-ui/core';

import WithCondition from 'src/components/WithCondition';

import { InstructorRequestsContext } from 'src/views/InstructorRequests/common/context';
import { InstructorRequestModal } from '../../../components';

const InstructorRequestsListResults = ({ data = { count: 0, requests: [] }, ...rest }) => {
  const {
    dataFetching: {
      queryParams,
      setQueryParams
    }
  } = useContext(InstructorRequestsContext);

  const [selectedRequestIds, setSelectedRequestIds] = useState([]);
  const [isOpenInstructorRequestModal, setIsOpenInstructorRequestModal] = useState(null);

  const handleSelectAll = (event) => {
    let newSelectedRequestIds;

    if (event.target.checked) {
      newSelectedRequestIds = data.requests.map((requestItem) => requestItem.id);
    } else {
      newSelectedRequestIds = [];
    }

    setSelectedRequestIds(newSelectedRequestIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRequestIds.indexOf(id);
    let newSelectedRequestIds = [];

    if (selectedIndex === -1) {
      newSelectedRequestIds = newSelectedRequestIds.concat(selectedRequestIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRequestIds = newSelectedRequestIds.concat(selectedRequestIds.slice(1));
    } else if (selectedIndex === selectedRequestIds.length - 1) {
      newSelectedRequestIds = newSelectedRequestIds.concat(selectedRequestIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRequestIds = newSelectedRequestIds.concat(
        selectedRequestIds.slice(0, selectedIndex),
        selectedRequestIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRequestIds(newSelectedRequestIds);
  };

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedRequestIds.length === data.requests.length}
                    color='primary'
                    indeterminate={
                      selectedRequestIds.length > 0
                      && selectedRequestIds.length < data.requests.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Ad
                </TableCell>
                <TableCell>
                  Soyad
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Phone
                </TableCell>
                <TableCell>
                  Peşə
                </TableCell>
                <TableCell>
                  Mövzular
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.requests.slice(0, queryParams.limit).map((request) => (
                <TableRow
                  hover
                  key={request.id}
                  selected={selectedRequestIds.indexOf(request.id) !== -1}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedRequestIds.indexOf(request.id) !== -1}
                      onChange={(event) => handleSelectOne(event, request.id)}
                      value='true'
                    />
                  </TableCell>
                  <TableCell>
                    {request.name}
                  </TableCell>

                  <TableCell>
                    {request.surname}
                  </TableCell>
                  <TableCell>
                    {request.email}
                  </TableCell>
                  <TableCell>
                    {request.phoneNumber}
                  </TableCell>
                  <TableCell>
                    {request.profession}
                  </TableCell>
                  <TableCell>
                    <Tooltip style={{ maxWidth: '300px' }} title={request.topics}>
                      <Button onClick={() => setIsOpenInstructorRequestModal(request.topics)}>
                        {`${request.topics.slice(0, 20)}...`}
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <WithCondition isDisplay={!!isOpenInstructorRequestModal}>
        <InstructorRequestModal
          onClose={() => setIsOpenInstructorRequestModal(null)}
          instructorRequest={isOpenInstructorRequestModal}
        />
      </WithCondition>
    </Card>
  );
};

InstructorRequestsListResults.defaultProps = {
  data: {
    requests: [],
    count: 0
  }
};

InstructorRequestsListResults.propTypes = {
  data: PropTypes.object.isRequired
};

export default InstructorRequestsListResults;
