import { useState } from 'react';
import { useQuery } from 'react-query';

import queryKeys from 'src/common/queryKeys';

import { fetchPromoCodeUsages } from '../../api';
import stateCreator from '../data/stateCreator';

const usePromoCodeCourses = () => {
  const [promoCode, setPromoCode] = useState(null);

  const [queryParams, setQueryParams] = useState(() => stateCreator.createEmptyQuery());

  const fetchPromoCodeUsagesQuery = useQuery({
    queryKey: [queryKeys.FETCH_PROMO_CODE_USAGES, promoCode?.id, queryParams],
    queryFn: () => fetchPromoCodeUsages(promoCode?.id, queryParams),
    enabled: !!promoCode
  });

  return {
    queryParams,
    setQueryParams,
    promoCode,
    setPromoCode,
    fetchPromoCodeUsagesQuery
  };

};

export default usePromoCodeCourses;
