import HRContext from './HRContext';
import useHR from './hooks/useHR';

const HRProvider = ({ children }) => {
  const contextData = useHR();

  return (
    <HRContext.Provider value={contextData}>
      {children}
    </HRContext.Provider>
  );
};

export default HRProvider;
