import { useContext } from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { InstructorContext } from 'src/views/Instructors/common/context';
import InstructorsListToolbar from '../blocks/InstructorsListToolBar';
import InstructorsListResults from '../blocks/InstructorsListResults';

const InstructorsListBody = ({ isLoading }) => {
  const {
    dataFetching: { fetchAllInstructorsQuery }
  } = useContext(InstructorContext);

  return (
    <View isLoading={fetchAllInstructorsQuery.isLoading || isLoading}>
      <InstructorsListToolbar />
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={null}>
          <InstructorsListResults data={fetchAllInstructorsQuery.data} />
        </ErrorBoundary>
      </Box>
    </View>
  );
};

InstructorsListBody.defaultProps = {
  isLoading: false
};

InstructorsListBody.propTypes = {
  isLoading: pt.bool
};

export default InstructorsListBody;
