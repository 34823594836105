const queryKeys = {
  /*authentication*/
  LOGIN: 'LOGIN',
  VERIFY_TOKEN: 'VERIFY_TOKEN',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  /*courses*/
  FETCH_COURSES: 'FETCH_COURSES',
  FETCH_COURSE_DETAIL: 'FETCH_COURSE_DETAIL',
  FETCH_COURSE_INFORMATION: 'FETCH_COURSE_INFORMATION',
  CREATE_COURSE: 'CREATE_COURSE',
  ADD_COURSE_QUIZZES: 'ADD_COURSE_QUIZZES',
  ADD_COURSE_LECTURES: 'ADD_COURSE_LECTURES',
  UPDATE_COURSE_INFORMATION: 'UPDATE_COURSE_INFORMATION',
  FETCH_ALL_COURSES_TITLE: 'FETCH_ALL_COURSES_TITLE',
  /*hr courses*/
  FETCH_HR_COURSES_FLOW: 'FETCH_HR_COURSES_FLOW',
  FETCH_HR_COURSE_USERS: 'FETCH_HR_COURSE_USERS',
  FETCH_HR_USER_STATISTICS: 'FETCH_HR_USER_STATISTICS',
  /*HR*/
  FETCH_HR_LIST: 'FETCH_HR_LIST',
  FETCH_HR_DETAIL: 'FETCH_HR_DETAIL',
  CREATE_HR: 'CREATE_HR',
  UPDATE_HR: 'UPDATE_HR',
  DELETE_HR: 'DELETE_HR',
  /*categories*/
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  /*instructors*/
  FETCH_INSTRUCTORS: 'FETCH_INSTRUCTORS',
  FETCH_INSTRUCTOR_DETAIL: 'FETCH_INSTRUCTOR_DETAIL',
  FETCH_ALL_INSTRUCTORS: 'FETCH_ALL_INSTRUCTORS',
  FETCH_INSTRUCTOR_SALES: 'FETCH_INSTRUCTOR_SALES',
  FETCH_INSTRUCTOR_REQUESTS: 'FETCH_INSTRUCTOR_REQUESTS',
  CREATE_INSTRUCTOR: 'CREATE_INSTRUCTOR',
  UPDATE_INSTRUCTOR: 'UPDATE_INSTRUCTOR',
  DELETE_INSTRUCTOR: 'DELETE_INSTRUCTOR',
  /*blogs*/
  FETCH_BLOGS: 'FETCH_BLOGS',
  FETCH_BLOG_DETAIL: 'FETCH_BLOG_DETAIL',
  CREATE_BLOG: 'CREATE_BLOG',
  UPDATE_BLOG: 'UPDATE_BLOG',
  DELETE_BLOG: 'DELETE_BLOG',
  /*sales*/
  FETCH_SALES: 'FETCH_SALES',
  /*feedbacks*/
  FETCH_FEED_BACKS: 'FETCH_FEED_BACKS',
  /*customers*/
  FETCH_CUSTOMERS: 'FETCH_CUSTOMERS',
  FETCH_ALL_CUSTOMERS: 'FETCH_ALL_CUSTOMERS',
  FETCH_CUSTOMER_COURSES: 'FETCH_CUSTOMER_COURSES',
  /*promo codes*/
  FETCH_PROMO_CODES: 'FETCH_PROMO_CODES',
  FETCH_PROMO_CODE_DETAIL: 'FETCH_PROMO_CODE_DETAIL',
  CREATE_PROMO_CODE: 'CREATE_PROMO_CODE',
  UPDATE_PROMO_CODE: 'UPDATE_PROMO_CODE',
  DELETE_PROMO_CODE: 'DELETE_PROMO_CODE',
  FETCH_PROMO_CODE_COURSES: 'FETCH_PROMO_CODE_COURSES',
  FETCH_PROMO_CODE_USAGES: 'FETCH_PROMO_CODE_USAGES',
  CHANGE_PROMO_CODE_STATUS: 'CHANGE_PROMO_CODE_STATUS'
};

export default queryKeys;
