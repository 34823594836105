import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';

export const fetchBlogs = (requestData) =>
  axiosInstance.get(apiRoutes.FETCH_BLOGS(), { params: requestData }).then(res => res.data);

export const fetchBlogDetail = (id) =>
  axiosInstance.get(apiRoutes.FETCH_BLOG_DETAIL(id)).then(res => res.data);

export const createBlog = (requestData) =>
  axiosInstance.post(apiRoutes.CREATE_BLOG(), requestData).then(res => res.data);

export const updateBlog = ({id, requestData}) =>
  axiosInstance.put(apiRoutes.UPDATE_BLOG(id), requestData).then(res => res.data);

export const deleteBlog = ({id, requestData}) =>
  axiosInstance.delete(apiRoutes.DELETE_BLOG(id), requestData).then(res => res.data);
