import {useContext} from 'react';
import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@material-ui/core';


import queryKeys from 'src/common/queryKeys';
import ButtonLoader from 'src/components/ButtonLoader';
import {AuthContext} from '../../common/context/auth';

import { changePassword } from 'src/views/Settings/common/api';

const schema = yup.object({
  password: yup.string().min(6).max(16).required('Password is required'),
  confirmPassword: yup
    .string()
    .min(6)
    .max(16)
    .required('Confirm password is required')
    .test(
      'passwords-match',
      'Passwords must match',
      function(value) {
        return this.parent.password === value;
      }
    )
});


const SettingsPassword = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const {logout} = useContext(AuthContext);

  const changePasswordMutation = useMutation({
    mutationKey: queryKeys.CHANGE_PASSWORD,
    mutationFn: changePassword,
    onSuccess: data => {
      enqueueSnackbar(data.message, { variant: 'success' });
      setTimeout(() => logout(), 250);
    },
    onError: error => {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  });

  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  const onSubmitHandler = (data) => changePasswordMutation.mutate(data);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} {...props}>
      <Card>
        <CardHeader
          subheader='Update password'
          title='Password'
        />
        <Divider />
        <CardContent>
          <Controller
            control={control}
            name='password'
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.password}
                helperText={errors.password?.message}
                fullWidth
                label='Password'
                margin='normal'
                name='password'
                type='password'
                variant='outlined'
              />
            )}
          />
          <Controller
            control={control}
            name='confirmPassword'
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                fullWidth
                label='Confirm password'
                margin='normal'
                name='confirmPassword'
                type='password'
                variant='outlined'
              />
            )}
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <ButtonLoader
            color='primary'
            variant='contained'
            type='submit'
            isDisabled={changePasswordMutation.isLoading}
            isLoading={changePasswordMutation.isLoading}
          >
            Update
          </ButtonLoader>
        </Box>
      </Card>
    </form>
  );
};

export default SettingsPassword;
