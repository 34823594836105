import HRList from './ui/pages/HRList/ui';
import HRForm from './ui/pages/HRForm/ui/HRForm';
import UserAdd from '../HrUserAdd.js/UserAdd';
import Influencer from '../Influencer/Influencer';
import InfluencerStatistics from '../Influencer/InfluencerStatistics';

const hrRoutes = [
  { path: '/hr', element: <HRList /> },
  { path: '/hr/form', element: <HRForm /> },
  { path: '/hr/form/:id', element: <HRForm /> },
  { path: '/user/add', element: <UserAdd /> },
  { path: '/influencer', element: <Influencer/> },
  { path: '/influencer/statistics', element: <InfluencerStatistics/> }
];
 
export default hrRoutes;
