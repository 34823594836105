import usePromoCodes from './hooks/usePromoCodes';

import PromoCodesContext from './PromoCodesContext';

const PromoCodesProvider = ({ children }) => {
  const contextData = usePromoCodes();

  return (
    <PromoCodesContext.Provider value={contextData}>{children}</PromoCodesContext.Provider>
  );
};

export default PromoCodesProvider;
