import { removeSpecialCharsFromStr, validateEmail } from 'src/common/functions/tools';

import stateCreator from 'src/views/HR/common/context/data/stateCreator';
import { stringLimitations } from '../../../../../../../common/constants';

export const formValidator = ({ formValues, setFormErrors, isNewRecord }) => {
  const errorsObject = stateCreator.createEmptyFormErrors();
  const {
    fullname,
    email,
    company_name,
    assignments,
    max_user_count,
    phone_number,
    total_payment,
    courses,
    password
  } = formValues;

  const phoneNumber = removeSpecialCharsFromStr(phone_number);

  errorsObject.fullname.error = !fullname || fullname.trim() === '';
  errorsObject.fullname.message = errorsObject.fullname.error ? 'Full name can not be blank' : '';

  errorsObject.email.error = !email || email.trim() === '';
  errorsObject.email.message = errorsObject.email.error ? 'Email can not be blank' : '';

  if (!errorsObject.email.error) {
    errorsObject.email.error = !validateEmail(email);
    errorsObject.email.message = errorsObject.email.error ? 'Email is invalid' : '';
  }

  errorsObject.company_name.error = !company_name || company_name.trim() === '';
  errorsObject.company_name.message = errorsObject.company_name.error ? 'Company name not be blank' : '';

  errorsObject.courses.error = !courses || courses.length === 0;
  errorsObject.courses.message = errorsObject.courses.error ? 'Courses can not be blank' : '';

  errorsObject.max_user_count.error = !max_user_count || max_user_count <= 0;
  errorsObject.max_user_count.message = errorsObject.max_user_count.error ? 'Max user count not be blank and must be integer' : '';

  errorsObject.phone_number.error = !phoneNumber || phoneNumber.trim() === '';
  errorsObject.phone_number.message = errorsObject.phone_number.error ? 'Phone number not be blank' : '';

  if (!errorsObject.phone_number.error) {
    errorsObject.phone_number.error = (phoneNumber.length !== stringLimitations.PHONE_LIMIT);
    errorsObject.phone_number.message = errorsObject.phone_number.error ? 'Invalid phone number' : '';
  }

  if (isNewRecord) {
    errorsObject.password.error = !password || password.trim() === '';
    errorsObject.password.message = errorsObject.password.error ? 'Password not be blank' : '';

    if (!errorsObject.password.error) {
      const passwordLength = password.length;
      errorsObject.password.error = passwordLength < stringLimitations.PASSWORD_MIN || passwordLength > stringLimitations.PASSWORD_MAX;
      errorsObject.password.message = errorsObject.password.error ? 'Password must be great than 5 and little than 16 symbols' : '';
    }
  }

  errorsObject.total_payment.error = !total_payment || total_payment < 0;
  errorsObject.total_payment.message = errorsObject.total_payment.error ? 'Total payment not be blank and must be integer' : '';

  if (!errorsObject.assignments.error && !errorsObject.max_user_count.error) {
    const selectedUsersCount = assignments.reduce((x, y) => x + y.students.length, 0);
    errorsObject.assignments.error = selectedUsersCount > max_user_count;

    if (errorsObject.assignments.error) {
      errorsObject.assignments.message = errorsObject.assignments.error ?
        `It looks like you have selected more than ${max_user_count} 'Max user count' students.
         You can increase the 'Max number of users' to select more students.
         Otherwise, you need to reduce the number of students.` : '';
    }
  }

  setFormErrors(prevState => ({ ...prevState, ...errorsObject }));
  return Object.values(errorsObject).find(field => field.error === true) === undefined;
};

export const formLoader = (formValues) => {
  const formData = new FormData();
  const {
    fullname,
    email,
    company_name,
    assignments,
    max_user_count,
    phone_number,
    total_payment,
    courses,
    password
  } = formValues;

  formData.append('fullname', fullname);
  formData.append('email', email);
  formData.append('company_name', company_name);
  formData.append('max_user_count', max_user_count);
  formData.append('phone_number', removeSpecialCharsFromStr(phone_number));
  formData.append('total_payment', total_payment);
  formData.append('assignments', JSON.stringify(assignments));
  formData.append('courses', JSON.stringify(courses));
  formData.append('password', JSON.stringify(password));

  return formData;
};
