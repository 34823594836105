import Body from '../../../partials/Body';

import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import FeedBacksListBody from './partials/FeedBacksListBody';

const FeedBacksList = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Feedbacks'>
      <FeedBacksListBody isLoading={isLoading} />
    </Body>
  );
};

export default FeedBacksList;
