import React, { useContext, useEffect } from 'react';
import pt from 'prop-types';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { Autocomplete, Box, Card, CardContent, Grid, TextField } from '@material-ui/core';

import queryKeys from 'src/common/queryKeys';
import View from 'src/components/view';
import WithCondition from 'src/components/WithCondition';

import { fetchAllCoursesTitle, fetchInstructors } from 'src/views/Courses/common/api';
import { PromoCodesContext } from 'src/views/PromoCodes/common/context';
import { promoCodeTypes, promoCodeTypesList } from 'src/views/PromoCodes/common/constants';
import PromoCodesFormToolBar from '../blocks/PromoCodesFormToolBar';
import PromoCodesFormActions from '../blocks/PromoCodesFormActions';

const PromoCodesFormBody = ({ isLoading: isRbacLoading }) => {
  const fetchInstructorsQuery = useQuery(queryKeys.FETCH_INSTRUCTORS, () => fetchInstructors());
  const fetchCoursesQuery = useQuery(queryKeys.FETCH_ALL_COURSES_TITLE, () => fetchAllCoursesTitle());

  const {
    isNewRecord,
    dataFetching: {
      fetchPromoCodeDetailQuery
    },
    create: {
      promoCodesForm,
      promoCodesFormController,
      savePromoCodeFormHandler,
      createPromoCodeMutation,
      promoCodesFormErrors
    },
    update: {
      updatePromoCodeMutation
    }
  } = useContext(PromoCodesContext);


  useEffect(() => {
    if (isNewRecord) {
      promoCodesFormController({ primaryKeys: null });
    }
  }, [promoCodesForm.type]);

  const { promoCode, discount, usageLimit } = promoCodesForm;

  const promoCodeTypesConfig = {
    options: promoCodeTypesList,
    getOptionLabel: (option) => option.name,
    loadingText: 'Loading...',
    debug: 'true',
    onChange: (event, type) => {
      promoCodesFormController({ type, primaryKeys: null });
    },
    value: promoCodesForm.type
  };

  const instructorsConfig = {
    options: fetchInstructorsQuery.data || [],
    getOptionLabel: (option) => option.name,
    loadingText: 'Loading...',
    loading: fetchPromoCodeDetailQuery.isLoading,
    debug: 'true',
    onChange: (event, instructor) => {
      promoCodesFormController({ primaryKeys: instructor });
    },
    value: promoCodesForm.primaryKeys
  };

  const coursesConfig = {
    options: fetchCoursesQuery.data || [],
    getOptionLabel: (option) => option.title,
    loadingText: 'Loading...',
    loading: fetchCoursesQuery.isLoading,
    debug: 'true',
    multiple: true,
    onChange: (event, instructors) => {
      promoCodesFormController({ primaryKeys: instructors });
    },
    value: _.isArray(promoCodesForm.primaryKeys) ? promoCodesForm.primaryKeys : []
  };

  return (
    <View isLoading={fetchPromoCodeDetailQuery.isLoading || updatePromoCodeMutation.isLoading || isRbacLoading}>
      <PromoCodesFormToolBar />
      <Box sx={{ pt: 3 }}>
        <form>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Autocomplete
                    {...promoCodeTypesConfig}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Choose promo code type'
                        margin='normal'
                        error={promoCodesFormErrors.type.error}
                        helperText={promoCodesFormErrors.type.message}
                      />
                    )}
                  />
                </Grid>

                <WithCondition isDisplay={!!promoCodesForm.type}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      name='promoCode'
                      placeholder='Promo code'
                      value={promoCode}
                      error={promoCodesFormErrors.promoCode.error}
                      helperText={promoCodesFormErrors.promoCode.message}
                      onChange={({ target }) => promoCodesFormController({ [target.name]: target.value })}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      name='discount'
                      placeholder='Discount'
                      value={discount}
                      type='number'
                      error={promoCodesFormErrors.discount.error}
                      helperText={promoCodesFormErrors.discount.message}
                      onChange={({ target }) => promoCodesFormController({ [target.name]: target.value })}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      name='usageLimit'
                      placeholder='Usage limit (optionally)'
                      value={usageLimit}
                      type='number'
                      helperText={'Specifies the maximum number of times the promo code will be used.'}
                      onChange={({ target }) => promoCodesFormController({ [target.name]: target.value })}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      id='expiredAt'
                      label='Expired at'
                      type='datetime-local'
                      value={promoCodesForm.expiredAt}
                      error={promoCodesFormErrors.expiredAt.error}
                      helperText={promoCodesFormErrors.expiredAt.message}
                      onChange={(e) => {
                        promoCodesFormController({ expiredAt: e.target.value });
                      }}
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <WithCondition isDisplay={promoCodesForm.type?.id === promoCodeTypes.INSTRUCTOR_SPECIFIC}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Autocomplete
                        {...instructorsConfig}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Choose instructor'
                            margin='normal'
                            error={promoCodesFormErrors.primaryKeys.error}
                            helperText={promoCodesFormErrors.primaryKeys.message}
                          />
                        )}
                      />
                    </Grid>
                  </WithCondition>
                  <WithCondition isDisplay={promoCodesForm.type?.id === promoCodeTypes.COURSE_SPECIFIC}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Autocomplete
                        {...coursesConfig}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Choose courses'
                            margin='normal'
                            error={promoCodesFormErrors.primaryKeys.error}
                            helperText={promoCodesFormErrors.primaryKeys.message}
                          />
                        )}
                      />
                    </Grid>
                  </WithCondition>
                </WithCondition>

                <PromoCodesFormActions
                  savePromoCodeForm={savePromoCodeFormHandler}
                  isLoading={createPromoCodeMutation.isLoading}
                  isDisableActions={!promoCodesForm.type}
                  isNewRecord={isNewRecord}
                />
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Box>
    </View>
  );
};

PromoCodesFormBody.defaultProps = {
  isLoading: false
};

PromoCodesFormBody.propTypes = {
  isLoading: pt.bool
};

export default PromoCodesFormBody;
