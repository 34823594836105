import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Fab, Grid, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';

import WithCondition from '../../../../../../../../../../components/WithCondition';
import { CoursesContext } from '../../../../../../../../common/context';
import { steps } from '../../../../../common/constants';

const RequirementsBlock = () => {
  const { create: { stepsForm, setStepsForm, stepsFormErrors } } = useContext(CoursesContext);
  const [requirement, setRequirement] = useState('');
  const [selectedRequirementForEdit, setSelectedRequirementForEdit] = useState(null);

  const courseContentStep = stepsForm[steps.COURSE_CONTENT];

  const setRequirements = (requirements) => {
    setStepsForm({
      ...stepsForm,
      [steps.COURSE_CONTENT]: { ...stepsForm[steps.COURSE_CONTENT], requirements }
    });
  };

  const addRequirement = (requirement) => {
    setRequirements([...stepsForm[steps.COURSE_CONTENT].requirements, { id: uuidv4(), value: requirement }]);
    resetRequirementInput();
  };

  const updateRequirement = (requirement) => {
    setRequirements([...stepsForm[steps.COURSE_CONTENT].requirements].map(requirementItem => requirementItem.id === selectedRequirementForEdit.id ? {
      id: selectedRequirementForEdit.id,
      value: requirement
    } : requirementItem));
    resetRequirementInput();
  };

  const deleteRequirement = (requirement) => {
    setRequirements(stepsForm[steps.COURSE_CONTENT].requirements.filter(requirementItem => requirementItem.id !== requirement.id));
    resetRequirementInput();
  };

  const resetRequirementInput = () => {
    setRequirement('');
    setSelectedRequirementForEdit(null);
  };

  const formErrors = stepsFormErrors[steps.COURSE_CONTENT];

  return (
    <Grid
      item
      md={6}
      xs={12}
    >
      <TextField
        id='requirements'
        label='Requirements'
        variant='outlined'
        fullWidth
        value={requirement}
        onChange={(e) => setRequirement(e.target.value)}
        error={formErrors.requirements.error}
        helperText={formErrors.requirements.message}
        InputProps={{
          endAdornment: (
            <>
              <WithCondition isDisplay={!selectedRequirementForEdit}>
                <InputAdornment
                  disablePointerEvents={!requirement}
                  onClick={(e) => addRequirement(requirement)}
                  position='end'
                >
                  <Fab disabled={!requirement} size='small' color='primary' aria-label='add'>
                    <AddIcon />
                  </Fab>
                </InputAdornment>
              </WithCondition>
              <WithCondition isDisplay={!!selectedRequirementForEdit}>
                <InputAdornment
                  disablePointerEvents={!requirement}
                  onClick={(e) => updateRequirement(requirement)}
                  position='end'
                >
                  <Fab disabled={!requirement} size='small' color='primary' aria-label='add'>
                    <DoneIcon />
                  </Fab>
                </InputAdornment>
                <InputAdornment
                  disablePointerEvents={!requirement}
                  onClick={(e) => resetRequirementInput()}
                  position='end'
                >
                  <Fab disabled={!requirement} size='small' color='primary' aria-label='add'>
                    <ClearIcon />
                  </Fab>
                </InputAdornment>
              </WithCondition>
            </>
          )
        }}
      />
      {
        courseContentStep.requirements.map(requirement => {
          return (
            <TextField
              key={requirement.id}
              id='requirements'
              label='Requirements'
              variant='outlined'
              margin='normal'
              fullWidth
              value={requirement.value}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment
                      disablePointerEvents={!requirement}
                      position='end'
                      onClick={() => {
                        setSelectedRequirementForEdit(requirement);
                        setRequirement(requirement.value);
                      }}
                    >
                      <Fab disabled={!requirement} size='small' color='primary' aria-label='add'>
                        <EditIcon />
                      </Fab>
                    </InputAdornment>
                    <InputAdornment
                      position='end'
                      onClick={() => deleteRequirement(requirement)}
                    >
                      <Fab size='small' color='secondary' aria-label='add'>
                        <RemoveIcon />
                      </Fab>
                    </InputAdornment>
                  </>
                )
              }}
            />
          );
        })
      }
    </Grid>
  );

};

export default RequirementsBlock;
