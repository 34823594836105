import { useContext, useState } from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { CoursesContext } from 'src/views/Courses/common/context';
import CoursesListResults from '../blocks/CourseListResults';
import CoursesListToolbar from '../blocks/CourseListToolBar';

const CoursesListBody = ({ isLoading: isRbacLoading }) => {
  const { dataFetching: { isLoading, error, data } } = useContext(CoursesContext);

  return (
    <View>
      <CoursesListToolbar/>
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={error}>
          <CoursesListResults  data={data} />
        </ErrorBoundary>
      </Box>
    </View>
  );
};

CoursesListBody.defaultProps = {
  isLoading: false
};

CoursesListBody.propTypes = {
  isLoading: pt.bool
};

export default CoursesListBody;
