import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { inputTypes } from 'src/views/HR/ui/pages/HRForm/common/constants';

const PasswordField = (props) => {
  const [inputType, setInputType] = useState(inputTypes.PASSWORD);

  const togglePasswordView = () => {
    const nextType = inputType === inputTypes.PASSWORD ? inputTypes.TEXT : inputTypes.PASSWORD;
    setInputType(nextType);
  };

  return (
    <TextField
      {...props}
      fullWidth
      type={inputType}
      name='password'
      InputProps={{
        inputProps: { min: 0 },
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle-password-view'
              onClick={togglePasswordView}
            >
              {inputType === inputTypes.PASSWORD ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default PasswordField;
