import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  clearInvalidStorage,
  deleteAuthorizationToken,
  parseJWT,
  setAuthorizationToken
} from 'src/common/functions/tools';
import { privateRoutes, publicRoutes } from 'src/common/mainRoutes';
import { storageKeys } from 'src/common/constants';
import queryKeys from 'src/common/queryKeys';

import authState from '../data/stateCreator';
import { login as loginApi } from '../api';

const useAuth = () => {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const [auth, setAuth] = useState(() => authState.createEmpty());

  const logout = () => {
    clearInvalidStorage();
    setAuth(() => authState.createEmpty());
    deleteAuthorizationToken();
    navigate(publicRoutes.LOGIN);
  };

  const login = useMutation({
    mutationKey: queryKeys.LOGIN,
    mutationFn: (requestBody) => loginApi(requestBody),
    onSuccess: (data) => {
      const { accessToken } = data;
      setAuthorizationToken(accessToken);
      const authUser = parseJWT(accessToken);

      setAuth({ isAuthenticated: true, accessToken, authUser: authUser });

      localStorage.setItem(storageKeys.ACCESS_TOKEN, JSON.stringify(accessToken));
      localStorage.setItem(storageKeys.AUTH_USER, JSON.stringify(authUser));

      navigate(privateRoutes.DASHBOARD);
    },
    onError: (error) => {
      enqueueSnackbar('Invalid email or password for selected login type.', {variant: 'error'});
      logout();
    }
  });

  return {
    auth,
    setAuth,
    login,
    logout
  };
};

export default useAuth;
