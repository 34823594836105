import  { useEffect, useState, useContext } from 'react';
import pt from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  Button,
  Card,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table as BaseTable, IconButton, TableFooter
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import QuizIcon from '@material-ui/icons/Quiz';
import FileDownloadIcon from '@material-ui/icons/FileDownload';
import FileDownloadDoneIcon from '@material-ui/icons/FileDownloadDone';
import TablePagination from '@material-ui/core/TablePagination';
import WithCondition from 'src/components/WithCondition';
import { UserQuizzesModal } from '../index';
import axiosInstance from 'src/common/config/api';
import apiRoutes from 'src/common/api/routes';
import Loader from 'src/components/loader';
import ButtonLoader from 'src/components/ButtonLoader';
var fileDownload = require('js-file-download');

const Table = ({ users, ...rest }) => {
  const [userQuizModal, setUserQuizModal] = useState(null);
  const { id: courseId } = useParams();
  const prepareDataForExport = (data = []) => {
    setBLoading(true)
    axiosInstance.get("https://admin-api.safavy.org/course/"+courseId+"/users/export")
    .then(res=>{
      setBLoading(false)
      fileDownload(res.data, 'users.csv');
    })
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [bLoading, setBLoading] = useState(false);
  useEffect(() => {
    setData(users.users)
    }, [])
    useEffect(() => {
      setData(users)
      }, [users])
  const handleChangePage = (event, newPage) => {
    setLoading(true)
    axiosInstance.get(apiRoutes.FETCH_HR_COURSE_USERS(courseId,rowsPerPage,newPage+1)).then(res => {
      setData(res.data)
      setLoading(false)
    });
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setLoading(true)
    axiosInstance.get(apiRoutes.FETCH_HR_COURSE_USERS(courseId,event.target.value,1)).then(res => {
      setData(res.data)
      setLoading(false)
    });
    setPage(0);
  };
  return (
  <div>
    {
      loading?
      <Loader></Loader>
      :
      <Card {...rest}>
     
     <PerfectScrollbar>
       <Box sx={{ minWidth: 1050 }}>
         <BaseTable>
           <TableHead>
             <TableRow>
               <TableCell>
                 Adı
               </TableCell>
               <TableCell>
                 Email
               </TableCell>
               <TableCell>
                 Kursa başlam tarixi
               </TableCell>
               <TableCell>
                 Progress
               </TableCell>
               <TableCell>
                 Quizzes
               </TableCell>
               <TableCell>
                 Setifikat
               </TableCell>
               <TableCell>
                 Kursu bitirmə tarixi
               </TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {data?.users?.map((user) => (
               <TableRow
                 hover
                 key={user.id}
               >
                 <TableCell>
                   {user.fullname}
                 </TableCell>
                 <TableCell>
                   {user.email}
                 </TableCell>
                 <TableCell>
                   {!!user.started_at ? moment(user.started_at).format('DD/MM/YYYY') : '---'}
                 </TableCell>
                 <TableCell>
                   {user.course_progress} %
                 </TableCell>
                 <TableCell>
                   <IconButton
                     onClick={() => setUserQuizModal(user.quizzes)}
                     disabled={!user.quizzes}
                   >
                     <QuizIcon />
                   </IconButton>
                 </TableCell>
                 <TableCell>
                   <Button disabled={!user.certificate_url}>
                     <a target='_blank' download href={user.certificate_url}>
                       <FileDownloadIcon />
                     </a>
                   </Button>
                 </TableCell>
                 <TableCell>
                   {!!user.finished_at ? moment(user.finished_at).format('DD/MM/YYYY') : '---'}
                 </TableCell>
               </TableRow>
             ))}
           </TableBody>
           <TableFooter>
             <TableRow>
               <TableCell>
                 <ButtonLoader 
                 onClick={prepareDataForExport}
                 isDisabled={bLoading}
                 isLoading={bLoading} variant='outlined'>
                   <FileDownloadDoneIcon style={{marginRight: 10}} />
                   Export
                   {/* <CSVLink filename='users.csv' data={prepareDataForExport(users)}>Export to CSV</CSVLink> */}
                 </ButtonLoader>
               </TableCell>
             </TableRow>
           </TableFooter>
         </BaseTable>
         <TablePagination
       rowsPerPageOptions={[10, 25, 100]}
       component="div"
       count={data?.count}
       rowsPerPage={rowsPerPage}
       page={page}
       onPageChange={handleChangePage}
       onRowsPerPageChange={handleChangeRowsPerPage}
     />
       </Box>
     </PerfectScrollbar>
     <WithCondition isDisplay={!!userQuizModal}>
       <UserQuizzesModal
         quizzes={userQuizModal}
         onClose={() => setUserQuizModal(null)}
       />
     </WithCondition>
   </Card>}
  </div>
  );
};

Table.defaultProps = {
  users: []
};

Table.propTypes = {
  users: pt.arrayOf(pt.shape({
    id: pt.oneOfType([pt.string, pt.number]).isRequired,
    fullname: pt.string.isRequired,
    email: pt.string.isRequired,
    started_at: pt.string.isRequired,
    course_progress: pt.number.isRequired,
    quizzes: pt.arrayOf(pt.shape({
      quiz_name: pt.string.isRequired,
      question_count: pt.number.isRequired,
      correct_answers: pt.number.isRequired
    })),
    certificate_url: pt.string.isRequired,
    finished_at: pt.string
  }))
};

export default Table;
