import { useContext } from 'react';
import pt from 'prop-types';
import {
  Box,
  Card,
  CardContent
} from '@material-ui/core';

import View from 'src/components/view';

import { CoursesContext } from 'src/views/Courses/common/context';
import { stepsIndexes } from '../../common/constants';
import CourseFormToolBar from '../blocks/CourseFormToolBar';
import { CourseFormStepper } from '../components';
import CreateVideosStep from '../blocks/CreateVideosStep';
import CourseContentStep from '../blocks/CourseContentStep';
import CourseInformationStep from '../blocks/CourseInformationStep';
import AddQuizzesStep from '../blocks/AddQuizzesStep';

const CourseStepsFactory = ({ step }) => {
  switch (step) {
    case stepsIndexes.COURSE_INFORMATION:
      return <CourseInformationStep />;
    case stepsIndexes.COURSE_CONTENT:
      return <CourseContentStep />;
    case stepsIndexes.CREATE_VIDEOS:
      return <CreateVideosStep />;
    case stepsIndexes.ADD_QUIZ:
      return <AddQuizzesStep />;
    default:
      return null;

  }
};

const CourseFormBody = ({isLoading: isRbacLoading}) => {

  const {
    dataFetching: { fetchCourseInformationQuery },
    create: {
      step,
      setStep,
      stepsForm,
      createCourse,
      createCourseMutation,
      stepsFormValidationFactory,
      addCourseLecturesHandler,
      addCourseLecturesMutation,
      addCourseQuizzesHandler,
      addCourseQuizzesMutation
    },
    update: {
      updateCourseInformationMutation
    }
  } = useContext(CoursesContext);

  return (
    <View isLoading={fetchCourseInformationQuery.isLoading || isRbacLoading}>
      <CourseFormToolBar />
      <Box sx={{ pt: 3 }}>
        <form>
          <CourseFormStepper
            step={step}
            setStep={setStep}
            createCourse={createCourse}
            createCourseMutation={createCourseMutation}
            stepsFormValidationFactory={stepsFormValidationFactory}
            addCourseLecturesHandler={addCourseLecturesHandler}
            addCourseLecturesMutation={addCourseLecturesMutation}
            stepsForm={stepsForm}
            addCourseQuizzesHandler={addCourseQuizzesHandler}
            addCourseQuizzesMutation={addCourseQuizzesMutation}
            updateCourseInformationMutation={updateCourseInformationMutation}
          >
            <Card>
              <CardContent>
                <CourseStepsFactory step={step} />
              </CardContent>
            </Card>
          </CourseFormStepper>
        </form>
      </Box>
    </View>
  );
};

CourseFormBody.defaultProps = {
  isLoading: false
};

CourseFormBody.propTypes = {
  isLoading: pt.bool
};

export default CourseFormBody;
