import React  from 'react';
import {
  Grid,
  TextField
} from '@material-ui/core';

import QuestionsBlock from '../QuestionsBlock';

const FormFactory = ({ selectedSection, quizTitle, setQuizTitle }) => {

  return (
    <>
      <Grid
        item
        md={12}
        xs={12}
      >
        <TextField
          id='quizTitle'
          label='Quiz title'
          variant='outlined'
          fullWidth
          name='quiz_title'
          value={quizTitle}
          onChange={({target}) => setQuizTitle(target.value)}
        />
      </Grid>

      {/*QUESTIONS BLOCK*/}
      <QuestionsBlock
        selectedSection={selectedSection}
        quizTitle={quizTitle}
        setQuizTitle={setQuizTitle}
      />
      {/*QUESTIONS BLOCK*/}
    </>
  );
};

export default FormFactory;
