const commonConstants = {
  API_URL: ''
};

export const storageKeys = {
  ACCESS_TOKEN: 'accessToken',
  AUTH_USER: 'authUser'
};

export default commonConstants;

export const rights = {
  MODERATOR: '1',
  INSTRUCTOR: '2',
  HR: '3',
  PARTNER:'4'
};

export const formOperations = {
  SAVE: 'SAVE',
  SAVE_AND_CONTINUE_CREATE: 'SAVE_AND_CONTINUE_CREATE',
  SAVE_AND_CONTINUE_EDIT: 'SAVE_AND_CONTINUE_EDIT'
};

export const stringLimitations = {
  PHONE_LIMIT: 9,
  PASSWORD_MIN: 6,
  PASSWORD_MAX: 16,
};
