import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import Body from '../../../partials/Body';

import SalesListBody from './partials/SalesListBody';

const SalesList = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR, rights.INSTRUCTOR] });

  return (
    <Body title='Courses'>
      <SalesListBody isLoading={isLoading} />
    </Body>
  );
};

export default SalesList;
