import SalesContext from './SalesContext';

import useSales from './hooks/useSales';

const SalesProvider = ({ children }) => {
  const contextData = useSales();

  return (
    <SalesContext.Provider value={contextData}>
      {children}
    </SalesContext.Provider>
  );
};

export default SalesProvider;
