import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import Body from 'src/views/Blogs/ui/partials/Body';

import BlogsListBody from './partials/BlogsListBody';

const BlogsList = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Blogs'>
      <BlogsListBody isLoading={isLoading} />
    </Body>
  );
};

export default BlogsList;
