import BlogsList from './ui/pages/BlogsList/ui';
import BlogsForm from './ui/pages/BlogsForm/ui';

const blogsRoutes = [
  { path: '/blogs', element: <BlogsList /> },
  { path: '/blogs/form', element: <BlogsForm /> },
  { path: '/blogs/form/:id', element: <BlogsForm /> }
];

export default blogsRoutes;
