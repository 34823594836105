export default {
  createEmptyQuery: () => ({
    page: 0,
    limit: 10,
    orderBy: 'id',
    order: 'desc'
  }),
  createEmptyForm: () => ({
    fullname: '',
    email: '',
    phone_number: '',
    company_name: '',
    signature: '',
    max_user_count: '',
    courses: [],
    assignments: [],
    total_payment: '',
    password: ''
  }),
  createEmptyAssignment: () => ({
    courses: [],
    students: [],
    department: ''
  }),
  createEmptyFormErrors: () => ({
    fullname: {
      error: false,
      message: ''
    },
    email: {
      error: false,
      message: ''
    },
    phone_number: {
      error: false,
      message: ''
    },
    company_name: {
      error: false,
      message: ''
    },
    max_user_count: {
      error: false,
      message: ''
    },
    courses: {
      error: false,
      message: ''
    },
    total_payment: {
      error: false,
      message: ''
    },
    assignments: {
      error: false,
      message: ''
    },
    password: {
      error: false,
      message: ''
    }
  })
};
