import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import Budget from './ui/components/Budget';
import LatestOrders from './ui/components/LatestOrders';
import TasksProgress from './ui/components/TasksProgress';
import TotalCustomers from './ui/components/TotalCustomers';
import TrafficByDevice from './ui/components/TrafficByDevice';
import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';
import queryKeys from '../../common/queryKeys';
import { fetchHRUserStatistics } from './common/api';
import View from '../../components/view';
import TotalProfit from './ui/components/TotalProfit';

const Dashboard = () => {
  const { authUser } = useRBAC({ rights: [rights.MODERATOR, rights.INSTRUCTOR, rights.HR] });

  const fetchHRUserStatisticsQuery = useQuery({
    queryKey: queryKeys.FETCH_HR_USER_STATISTICS,
    queryFn: () => fetchHRUserStatistics(authUser.id)
  });

  return (
    <>
      <Helmet>
        <title>Human Resources Dashboard | Safavy.org</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <View isLoading={fetchHRUserStatisticsQuery.isLoading || !fetchHRUserStatisticsQuery.data}>

            <Grid
              container
              spacing={3}
            >
              {/*header*/}
              <Grid
                item
                lg={4}
                sm={6}
                xl={3}
                xs={12}
              >
                <Budget course_count={fetchHRUserStatisticsQuery.data?.course_count} />
              </Grid>
              <Grid
                item
                lg={4}
                sm={6}
                xl={3}
                xs={12}
              >
                <TotalCustomers user_count={fetchHRUserStatisticsQuery.data?.user_count} />
              </Grid>
              <Grid
                item
                lg={4}
                sm={6}
                xl={3}
                xs={12}
              >
                <TotalProfit total_amount={fetchHRUserStatisticsQuery.data?.total_amount} />
              </Grid>
              {/*header /*/}

              <Grid
                item
                lg={12}
                md={12}
                xl={9}
                xs={12}
              >
                <LatestOrders data={fetchHRUserStatisticsQuery.data} />
              </Grid>
            </Grid>
          </View>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
