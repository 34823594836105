import commonConstants from '../constants';

const apiRoutes = {
  /*authentication*/
  LOGIN: () => `${commonConstants.API_URL}/auth/login`,
  VERIFY_TOKEN: () => `${commonConstants.API_URL}/auth/verify-token`,
  UPDATE_ACCOUNT: () => `${commonConstants.API_URL}/auth/account`,
  CHANGE_PASSWORD: () => `${commonConstants.API_URL}/auth/account/change-password`,
  /*courses*/
  FETCH_COURSES: () => `${commonConstants.API_URL}/course/all`,
  CREATE_COURSE: () => `${commonConstants.API_URL}/course`,
  FETCH_ALL_COURSES_TITLE: () => `${commonConstants.API_URL}/course`,
  FETCH_COURSE_DETAIL: (id) => `${commonConstants.API_URL}/course/${id}`,
  DELETE_COURSE: (id) => `${commonConstants.API_URL}/course/${id}`,
  ADD_COURSE_LECTURES: () => `${commonConstants.API_URL}/course/add-lecture`,
  ADD_COURSE_QUIZZES: (id) => `${commonConstants.API_URL}/course/${id}/quiz`,
  CHANGE_COURSE_STATUS: (id) => `${commonConstants.API_URL}/course/${id}/status`,
  FETCH_COURSE_INFORMATION: (id) => `${commonConstants.API_URL}/course/${id}/information`,
  UPDATE_COURSE_INFORMATION: (id) => `${commonConstants.API_URL}/course/${id}/information`,
  FETCH_CATEGORIES: () => `${commonConstants.API_URL}/categories`,
  /*hr courses*/
  FETCH_HR_COURSES_FLOW: () => `${commonConstants.API_URL}/course/all`,
  FETCH_HR_COURSE_USERS: (id,limit,page) => `${commonConstants.API_URL}/v2/course/${id}/users?page=${page}&limit=${limit}`,
  FETCH_HR_USER_STATISTICS: (id) => `${commonConstants.API_URL}/hr/${id}/user_statistics`,
  /*HR*/
  FETCH_HR_LIST: () => `${commonConstants.API_URL}/hr`,
  CREATE_HR: () => `${commonConstants.API_URL}/hr`,
  UPDATE_HR: (id) => `${commonConstants.API_URL}/hr/${id}`,
  DELETE_HR: (id) => `${commonConstants.API_URL}/hr/${id}`,
  FETCH_HR_DETAIL: (id) => `${commonConstants.API_URL}/hr/${id}`,
  /*instructors*/
  FETCH_INSTRUCTORS: () => `${commonConstants.API_URL}/instructors`,
  FETCH_INSTRUCTOR_DETAIL: (id) => `${commonConstants.API_URL}/instructors/${id}`,
  FETCH_INSTRUCTOR_SALES: (id) => `${commonConstants.API_URL}/instructors/${id}/sales`,
  FETCH_ALL_INSTRUCTORS: () => `${commonConstants.API_URL}/instructors/all`,
  FETCH_INSTRUCTOR_REQUESTS: () => `${commonConstants.API_URL}/instructors/requests`,
  CREATE_INSTRUCTOR: () => `${commonConstants.API_URL}/instructors`,
  UPDATE_INSTRUCTOR: () => `${commonConstants.API_URL}/instructors`,
  DELETE_INSTRUCTOR: () => `${commonConstants.API_URL}/instructors`,
  /*blogs*/
  FETCH_BLOGS: () => `${commonConstants.API_URL}/blogs`,
  CREATE_BLOG: () => `${commonConstants.API_URL}/blogs`,
  FETCH_BLOG_DETAIL: (id) => `${commonConstants.API_URL}/blogs/${id}`,
  UPDATE_BLOG: (id) => `${commonConstants.API_URL}/blogs/${id}`,
  DELETE_BLOG: (id) => `${commonConstants.API_URL}/blogs/${id}`,
  /*sales*/
  FETCH_SALES: () => `${commonConstants.API_URL}/sales`,
  /*feedbacks*/
  FETCH_FEED_BACKS: () => `${commonConstants.API_URL}/feedbacks`,
  /*customers*/
  FETCH_CUSTOMERS: () => `${commonConstants.API_URL}/customers`,
  FETCH_ALL_CUSTOMERS: () => `${commonConstants.API_URL}/customers/all`,
  FETCH_CUSTOMER_COURSES: (id) => `${commonConstants.API_URL}/customers/${id}/courses`,
  /*promo-codes*/
  FETCH_PROMO_CODES: () => `${commonConstants.API_URL}/promo-codes`,
  CREATE_PROMO_CODE: () => `${commonConstants.API_URL}/promo-codes`,
  UPDATE_PROMO_CODE: (id) => `${commonConstants.API_URL}/promo-codes/${id}`,
  DELETE_PROMO_CODE: (id) => `${commonConstants.API_URL}/promo-codes/${id}`,
  FETCH_PROMO_CODE_DETAIL: (id) => `${commonConstants.API_URL}/promo-codes/${id}`,
  FETCH_PROMO_CODE_COURSES: (id) => `${commonConstants.API_URL}/promo-codes/${id}/courses`,
  FETCH_PROMO_CODE_USAGES: (id) => `${commonConstants.API_URL}/promo-codes/${id}/usages`,
  CHANGE_PROMO_CODE_STATUS: (id) => `${commonConstants.API_URL}/promo-codes/${id}/status`,
  FETCH_HR_USERS_ALL:()=> `${commonConstants.API_URL}//hr/hr-all`,
  FETCH_INSTRUCTOR_ALLDETAIL:()=> `https://admin-api.safavy.org/instructors/detail`,
  FETCH_MODERATOR_ALLDETAIL:()=> `https://admin-api.safavy.org/customers/detail`,
  FETCH_MODERATOR_BANNER_UPDATE:()=> `https://admin-api.safavy.org/customers/update-banner?banner_type=`,
  FETCH_MODERATOR_DAILY_SALES:()=> `https://admin-api.safavy.org/sales/today`,
  FETCH_MODERATOR_MONTHLY_SALES:()=> `https://admin-api.safavy.org/sales/monthly`,
  FETCH_MODERATOR_MONTHLY_SALES_INSTRUCTORS:()=> `https://admin-api.safavy.org/sales/monthly`,
  FETCH_MODERATOR_MONTHLY_SALES_COURSE:()=> `https://admin-api.safavy.org/sales/course/monthly`,
  FETCH_MODERATOR_SALES_BY_CATEGORY:()=> `https://admin-api.safavy.org/sales/by-category`,
  FETCH_MODERATOR_SALES_BY_CATEGORY_MONTHLY:()=> `https://admin-api.safavy.org/sales/by-category/monthly`,
  FETCH_MODERATOR_SEARCH_COURSE:()=> `course/course-for-add?title=`,
  FETCH_MODERATOR_SEARCH_USERS:()=> `customers/by-email?email=`,
  FETCH_MODERATOR_ADD_COURSE:()=> `customers/add-course`,
  FETCH_SALES_DETAIL_FOR_INSTRUCTORS: (month=1,year=2023) => `https://admin-api.safavy.org/sales/detail/month/${month}/year/${year}`,
};

export default apiRoutes;
