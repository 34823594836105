import useCourses from './hooks/useCustomers';

import CustomersContext from './CustomersContext';

const InstructorsProvider = ({ children }) => {
  const contextData = useCourses();

  return (
    <CustomersContext.Provider value={contextData}>{children}</CustomersContext.Provider>
  );
};

export default InstructorsProvider;
