import { useState } from 'react';
import { useQuery } from 'react-query';

import queryKeys from 'src/common/queryKeys';

import { fetchInstructorSales } from '../../api';
import stateCreator from '../data/stateCreator';

const useInstructorSales = () => {
  const [instructor, setInstructor] = useState(null);

  const [queryParams, setQueryParams] = useState({ ...stateCreator.createEmptyQuery(), orderBy: 'buyed_at' });

  const fetchInstructorSalesQuery = useQuery({
    queryKey: [queryKeys.FETCH_INSTRUCTOR_SALES, instructor?.id, queryParams],
    queryFn: () => fetchInstructorSales(instructor?.id, queryParams),
    enabled: !!instructor
  });

  return {
    queryParams,
    setQueryParams,
    instructor,
    setInstructor,
    fetchInstructorSalesQuery
  };

};

export default useInstructorSales;
