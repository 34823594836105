import { useState } from 'react';
import { useQuery } from 'react-query';

import queryKeys from 'src/common/queryKeys';

import { fetchCustomerCourses } from '../../api';
import stateCreator from '../data/stateCreator';

const useCustomerCourses = () => {
  const [customer, setCustomer] = useState(null);

  const [queryParams, setQueryParams] = useState({ ...stateCreator.createEmptyQuery(), orderBy: 'buyed_at' });

  const fetchCustomerCoursesQuery = useQuery({
    queryKey: [queryKeys.FETCH_CUSTOMER_COURSES, customer?.id, queryParams],
    queryFn: () => fetchCustomerCourses(customer?.id, queryParams),
    enabled: !!customer
  });

  return {
    queryParams,
    setQueryParams,
    customer,
    setCustomer,
    fetchCustomerCoursesQuery
  };

};

export default useCustomerCourses;
