import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import {
  Avatar,
  Box, Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow, Tooltip,
  Typography
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Trash as TrashIcon
} from 'react-feather';

import { privateRoutes } from 'src/common/mainRoutes';
import getInitials from 'src/common/functions/getInitials';
import WithCondition from 'src/components/WithCondition';

import { BlogsContext } from 'src/views/Blogs/common/context';
import { BlogContentModal, DeleteBlogModal } from '../../components';

const BlogsListResults = ({ data = { count: 0, blogs: [] }, ...rest }) => {
  const {
    dataFetching: { queryParams, setQueryParams },
    delete: {
      deleteBlogHandler,
      deleteBlogMutation
    },
  } = useContext(BlogsContext);

  const [selectedBlogIds, setSelectedBlogIds] = useState([]);
  const [isOpenBlogModal, setIsOpenBlogModal] = useState(null);
  const [blogForDelete, setBlogForDelete] = useState(null);

  const handleSelectAll = (event) => {
    let newSelectedBlogIds;

    if (event.target.checked) {
      newSelectedBlogIds = data.blogs.map(blogItem => blogItem.id);
    } else {
      newSelectedBlogIds = [];
    }

    setSelectedBlogIds(newSelectedBlogIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBlogIds.indexOf(id);
    let newSelectedBlogIds = [];

    if (selectedIndex === -1) {
      newSelectedBlogIds = newSelectedBlogIds.concat(selectedBlogIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBlogIds = newSelectedBlogIds.concat(selectedBlogIds.slice(1));
    } else if (selectedIndex === selectedBlogIds.length - 1) {
      newSelectedBlogIds = newSelectedBlogIds.concat(selectedBlogIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBlogIds = newSelectedBlogIds.concat(
        selectedBlogIds.slice(0, selectedIndex),
        selectedBlogIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBlogIds(newSelectedBlogIds);
  };

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedBlogIds.length === data.blogs.length}
                    color='primary'
                    indeterminate={
                      selectedBlogIds.length > 0
                      && selectedBlogIds.length < data.blogs.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Başıq
                </TableCell>
                <TableCell>
                  Şəkil
                </TableCell>
                <TableCell>
                  Mətn
                </TableCell>
                <TableCell>
                  Yaradılma tarixi
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Əməliyyatlar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.blogs.slice(0, queryParams.limit).map((blog) => (
                <TableRow
                  hover
                  key={blog.id}
                  selected={selectedBlogIds.indexOf(blog.id) !== -1}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedBlogIds.indexOf(blog.id) !== -1}
                      onChange={(event) => handleSelectOne(event, blog.id)}
                      value='true'
                    />
                  </TableCell>
                  <TableCell>
                    {blog.title}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={blog.p_image}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(blog.title)}
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Tooltip style={{ maxWidth: '300px' }} title={blog.blog}>
                      <Button onClick={() => setIsOpenBlogModal(blog.blog)}>
                        {`${blog.blog.slice(0, 50)}...`}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {moment(blog.created_at).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {blog.status ? 'Aktiv' : 'Deaktiv'}
                  </TableCell>
                  <TableCell>
                    <Link to={`${privateRoutes.BLOGS_FORM}/${blog.id}`}>
                      <IconButton aria-label='edit'>
                        <EditIcon strokeWidth={1.5} size={18} />
                      </IconButton>
                    </Link>
                    <IconButton
                      onClick={() => setBlogForDelete(blog.id)}
                      disabled={deleteBlogMutation.isLoading}
                      aria-label='delete'
                    >
                      <TrashIcon strokeWidth={1.5} size={18} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <WithCondition isDisplay={!!blogForDelete}>
        <DeleteBlogModal
          open={!!blogForDelete}
          closeModal={() => setBlogForDelete(null)}
          onDelete={() => deleteBlogHandler(blogForDelete)}
        />
      </WithCondition>
      <WithCondition isDisplay={!!isOpenBlogModal}>
        <BlogContentModal
          onClose={() => setIsOpenBlogModal(null)}
          instructorRequest={isOpenBlogModal}
        />
      </WithCondition>
    </Card>
  );
};

BlogsListResults.defaultProps = {
  data: {
    blogs: [],
    count: 0
  }
};

BlogsListResults.propTypes = {
  data: PropTypes.object.isRequired
};

export default BlogsListResults;
