import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { Edit as EditIcon, Trash as TrashIcon } from 'react-feather';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const QuestionsListTable = ({ questions, deleteQuestion, passQuestionForUpdate }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Question title</TableCell>
            <TableCell>Correct answer</TableCell>
            <TableCell>Variant count</TableCell>
            <TableCell>Operations</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.map((question) => (
            <TableRow key={question.id}>
              <TableCell component='th' scope='row'>{question.title}</TableCell>
              <TableCell>{question.variants.find(variant => variant.is_correct === true)?.title}</TableCell>
              <TableCell>{question.variants.length}</TableCell>
              <TableCell>
                <IconButton
                  onClick={() => passQuestionForUpdate(question)}
                  aria-label='edit'
                >
                  <EditIcon strokeWidth={1.5} size={18} />
                </IconButton>
                <IconButton
                  onClick={() => deleteQuestion(question)}
                  aria-label='delete'
                >
                  <TrashIcon strokeWidth={1.5} size={18} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuestionsListTable;
