import useCourses from './hooks/useCourses';

import CoursesContext from './CoursesContext';

const CoursesProvider = ({ children }) => {
  const contextData = useCourses();

  return (
    <CoursesContext.Provider value={contextData}>{children}</CoursesContext.Provider>
  );
};

export default CoursesProvider;
