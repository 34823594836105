import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import stateCreator from 'src/views/PromoCodes/common/context/data/stateCreator';
import ViewList from './blocks/ViewList';

const CoursesListModal = ({
                            promoCodeCourses: {
                              queryParams,
                              setQueryParams,
                              promoCode,
                              setPromoCode,
                              fetchPromoCodeCoursesQuery
                            }
                          }) => {
  const handleClose = () => {
    setQueryParams(() => stateCreator.createEmptyQuery());
    setPromoCode(null);
  };


  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby='promoCodeListModal'
      aria-describedby='promoCodeListModalDescription'
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle id='promoCodeListModal'>{`Courses of ${promoCode?.promoCode}` || 'Promo code courses'}</DialogTitle>
      <DialogContent dividers={true}>
        <ViewList
          query={fetchPromoCodeCoursesQuery}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CoursesListModal;
