import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import Body from '../../../partials/Body';
import HRFormBody from './partials/HRFormBody';

const InstructorForm = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Create Human Resource'>
      <HRFormBody isLoading={isLoading} />
    </Body>
  );
};

export default InstructorForm;
