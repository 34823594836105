import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Fab, Grid, Input, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';

import WithCondition from '../../../../../../../../../../components/WithCondition';
import { CoursesContext } from '../../../../../../../../common/context';
import { steps } from '../../../../../common/constants';

const SectionsBlock = () => {
  const { create: { stepsForm, setStepsForm, stepsFormErrors } } = useContext(CoursesContext);

  const maxSort = Math.max(...stepsForm[steps.COURSE_CONTENT].sections.map(section => parseInt(section.sort)));

  const [section, setSection] = useState('');
  const [sort, setSort] = useState(isFinite(maxSort) && maxSort ? maxSort + 1 : '');
  const [selectedSectionForEdit, setSelectedSectionForEdit] = useState(null);

  const courseContentStep = stepsForm[steps.COURSE_CONTENT];

  const setSections = (sections) => {
    setStepsForm({
      ...stepsForm,
      [steps.COURSE_CONTENT]: { ...stepsForm[steps.COURSE_CONTENT], sections }
    });
  };

  const addSection = ({ section, sort }) => {
    sort = parseInt(sort);
    const sections = stepsForm[steps.COURSE_CONTENT].sections;
    const alreadyExistsBySort = sections.find(section => parseInt(section.sort) === sort);

    if (!!alreadyExistsBySort) {
      const autoIncrementedSort = Math.max(...stepsForm[steps.COURSE_CONTENT].sections.map(section => parseInt(section.sort))) + 1;
      const addedSections = [...sections.map(section => parseInt(section.sort) === parseInt(alreadyExistsBySort.sort) ? {
        ...alreadyExistsBySort,
        sort: autoIncrementedSort
      } : section), { id: uuidv4(), title: section, sort: alreadyExistsBySort.sort }];

      setSections(addedSections);
      resetSectionInput({ lastAddedSectionSort: autoIncrementedSort, mutatedSections: addedSections });
    } else {
      const addedSections = [...sections, { id: uuidv4(), title: section, sort }];
      setSections(addedSections);
      resetSectionInput({ lastAddedSectionSort: sort, mutatedSections: addedSections });
    }
  };

  const updateSection = ({ section, sort }) => {
    sort = parseInt(sort);
    const sections = stepsForm[steps.COURSE_CONTENT].sections;
    const alreadyExistsBySort = sections.find(sectionItem => parseInt(sectionItem.sort) === parseInt(sort));
    const findSectionForUpdate = sections.find(sectionItem => sectionItem.id === selectedSectionForEdit.id);

    if (!!alreadyExistsBySort) {
      const autoIncrementedSort = Math.max(...stepsForm[steps.COURSE_CONTENT].sections.map(section => parseInt(section.sort)));
      const updatedSections = sections.map(sectionItem => {
        if (sectionItem.id === alreadyExistsBySort.id && sectionItem.id !== selectedSectionForEdit.id) {
          return {
            ...sectionItem,
            sort: findSectionForUpdate.sort
          };
        }

        if (sectionItem.id === findSectionForUpdate.id) {
          return {
            ...sectionItem,
            title: section,
            sort
          };
        }

        return sectionItem;
      });

      setSections(updatedSections);
      resetSectionInput({ lastAddedSectionSort: autoIncrementedSort, mutatedSections: updatedSections });
    } else {
      const updatedSections = sections.map(sectionItem => sectionItem.id === selectedSectionForEdit.id ? {
        id: selectedSectionForEdit.id,
        title: section,
        sort
      } : sectionItem);

      setSections(updatedSections);
      resetSectionInput({ lastAddedSectionSort: sort, mutatedSections: updatedSections });
    }
  };

  const deleteSection = (section) => {
    const mutatedSections = stepsForm[steps.COURSE_CONTENT].sections.filter(sectionItem => sectionItem.id !== section.id);
    const autoIncrementedSort = Math.max(...mutatedSections.map(sectionItem => sectionItem.sort));

    setSections(mutatedSections);
    resetSectionInput({ lastAddedSectionSort: autoIncrementedSort, mutatedSections });
  };

  const resetSectionInput = ({ lastAddedSectionSort, mutatedSections }) => {
    const maxSort = Math.max(...[...mutatedSections.map(section => parseInt(section.sort)), lastAddedSectionSort]);
    setSection('');
    setSort((isFinite(maxSort) && !!maxSort) ? maxSort + 1 : '');
    setSelectedSectionForEdit(null);
  };

  const formErrors = stepsFormErrors[steps.COURSE_CONTENT];

  return (
    <Grid
      item
      md={6}
      xs={12}
    >
      <TextField
        id='sections'
        label='Add section'
        variant='outlined'
        fullWidth
        value={section}
        onChange={(e) => setSection(e.target.value)}
        error={formErrors.sections.error}
        helperText={formErrors.sections.message}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment
                disablePointerEvents={!section}
                position='end'
              >
                <Input
                  disabled={!section}
                  placeholder='Order num'
                  type='number'
                  value={sort}
                  onChange={e => setSort(e.target.value)}
                />
              </InputAdornment>
              <WithCondition isDisplay={!selectedSectionForEdit}>
                <InputAdornment
                  disablePointerEvents={!section || !sort}
                  onClick={(e) => addSection({ section, sort })}
                  position='end'
                >
                  <Fab disabled={!section || !sort} size='small' color='primary' aria-label='add'>
                    <AddIcon />
                  </Fab>
                </InputAdornment>
              </WithCondition>
              <WithCondition isDisplay={!!selectedSectionForEdit}>
                <InputAdornment
                  disablePointerEvents={!section}
                  onClick={(e) => updateSection({ section, sort })}
                  position='end'
                >
                  <Fab disabled={!section} size='small' color='primary' aria-label='add'>
                    <DoneIcon />
                  </Fab>
                </InputAdornment>
                <InputAdornment
                  disablePointerEvents={!section}
                  onClick={(e) => resetSectionInput({
                    lastAddedSectionSort: maxSort,
                    mutatedSections: stepsForm[steps.COURSE_CONTENT].sections
                  })}
                  position='end'
                >
                  <Fab disabled={!section} size='small' color='primary' aria-label='add'>
                    <ClearIcon />
                  </Fab>
                </InputAdornment>
              </WithCondition>
            </>
          )
        }}
      />
      {
        courseContentStep.sections.map(section => {
          return (
            <TextField
              key={section.id}
              id='sections'
              label='Add section'
              variant='outlined'
              margin='normal'
              fullWidth
              value={section.title}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment
                      disablePointerEvents={!section}
                      position='end'
                    >
                      <Input disabled={true} value={section.sort} placeholder='Order num' />
                    </InputAdornment>
                    <InputAdornment
                      disablePointerEvents={!section}
                      position='end'
                      onClick={() => {
                        setSelectedSectionForEdit(section);
                        setSort(section.sort);
                        setSection(section.title);
                      }}
                    >
                      <Fab disabled={!section} size='small' color='primary' aria-label='add'>
                        <EditIcon />
                      </Fab>
                    </InputAdornment>
                    <InputAdornment
                      position='end'
                      onClick={() => deleteSection(section)}
                    >
                      <Fab size='small' color='secondary' aria-label='add'>
                        <RemoveIcon />
                      </Fab>
                    </InputAdornment>
                  </>
                )
              }}
            />
          );
        })
      }
    </Grid>
  );

};

export default SectionsBlock;
