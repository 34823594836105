import { useContext } from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { HRContext } from 'src/views/HR/common/context';
import HRListToolbar from '../blocks/HRListToolBar';
import HRListResults from '../blocks/HRListResults';

const HRListBody = ({ isLoading }) => {
  const {
    dataFetching: { fetchHRListQuery }
  } = useContext(HRContext);

  return (
    <View>
      <HRListToolbar />
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={null}>
          <HRListResults data={fetchHRListQuery.data} />
        </ErrorBoundary>
      </Box>
    </View>
  );
};

HRListBody.defaultProps = {
  isLoading: false
};

HRListBody.propTypes = {
  isLoading: pt.bool
};

export default HRListBody;
