import { useState } from 'react';
import { useQuery } from 'react-query';

import queryKeys from 'src/common/queryKeys';

import stateCreator from '../data/stateCreator';
import { fetchSales } from '../../api';

const useSales = () => {
  const [queryParams, setQueryParams] = useState(() => stateCreator.createEmptyQuery());

  const fetchSalesQuery = useQuery({
    queryKey: [queryKeys.FETCH_SALES, queryParams],
    queryFn: () => fetchSales(queryParams)
  });

  return {
    dataFetching: {
      queryParams,
      setQueryParams,
      fetchSalesQuery
    }
  };

};

export default useSales;
