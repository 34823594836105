import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import {privateRoutes} from 'src/common/mainRoutes';

const InstructorsListToolbar = (props) => (
  <Box {...props}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <Link to={privateRoutes.INSTRUCTORS_FORM}>
        <Button
          color='primary'
          variant='contained'
        >
          Add instructor
        </Button>
      </Link>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon
                      fontSize='small'
                      color='action'
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder='Search instructors'
              variant='outlined'
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
);

export default InstructorsListToolbar;
