import React, { useState, useContext } from 'react';

import stateCreator from '../../../../../../../../common/context/data/stateCreator';
import { CoursesContext } from '../../../../../../../../common/context';
import { steps } from '../../../../../common/constants';
import VariantsListBlock from '../VariantsListBlock';
import VariantFormBlock from '../VariantFormBlock';
import QuestionHeaderBlock from '../QuestionHeaderBlock';
import VariantsListFooterBlock from '../VariantsListFooterBlock';
import QuestionsListBlock from '../../../QuestionsListBlock';

const QuestionsBlock = ({ selectedSection, quizTitle, setQuizTitle }) => {
  const { create: { stepsForm, stepsFormController } } = useContext(CoursesContext);
  const [questionTitle, setQuestionTitle] = useState('');
  const [variantForm, setVariantForm] = useState(() => stateCreator.createEmptyVariantForm());
  const [selectedVariantForEdit, setSelectedVariantForEdit] = useState(null);
  const [selectedQuestionForEdit, setSelectedQuestionForEdit] = useState(null);

  const [variants, setVariants] = useState([]);

  const onChangeHandler = (fieldObject) => {
    setVariantForm({ ...variantForm, ...fieldObject });
  };

  const resetAfterAddQuestion = () => {
    setSelectedVariantForEdit(null);
    setSelectedQuestionForEdit(null);
    setVariantForm(() => stateCreator.createEmptyVariantForm());
    setQuestionTitle('');
    setVariants([]);
  };

  const deleteQuestion = (questionItem) => {
    const quizzes = stepsForm[steps.ADD_QUIZ].quizzes;
    const hasQuiz = quizzes.find(quiz => quiz.sectionId = selectedSection.id);
    if (!!hasQuiz) {
      const mutatedQuizzes = quizzes.map(quiz => {
        if (quiz.id === hasQuiz.id) {
          quiz.questions = quiz.questions.filter(question => question.id !== questionItem.id);
        }

        return quiz;
      });
      stepsFormController({ stepName: steps.ADD_QUIZ, fieldObject: { quizzes: mutatedQuizzes } }, true);
    }
  };

  const passQuestionForUpdate = (question) => {
    setVariants(question.variants);
    setQuestionTitle(question.title);
    setSelectedQuestionForEdit(question);
  };

  return (
    <>
      <QuestionHeaderBlock
        questionTitle={questionTitle}
        setQuestionTitle={setQuestionTitle}
      />
      <VariantFormBlock
        variantForm={variantForm}
        onChangeHandler={onChangeHandler}
        selectedVariantForEdit={selectedVariantForEdit}
        variants={variants}
        setVariants={setVariants}
        setVariantForm={setVariantForm}
        setSelectedVariantForEdit={setSelectedVariantForEdit}
        questionTitle={questionTitle}
      />
      <VariantsListBlock
        variants={variants}
        setVariants={setVariants}
        setSelectedVariantForEdit={setSelectedVariantForEdit}
        onChangeHandler={onChangeHandler}
        setVariantForm={setVariantForm}
      />
      <VariantsListFooterBlock
        stepsForm={stepsForm}
        stepsFormController={stepsFormController}
        selectedSection={selectedSection}
        variants={variants}
        questionTitle={questionTitle}
        resetAfterAddQuestion={resetAfterAddQuestion}
        selectedQuestionForEdit={selectedQuestionForEdit}
        setQuizTitle={setQuizTitle}
        quizTitle={quizTitle}
      />
      <QuestionsListBlock
        selectedSection={selectedSection}
        stepsForm={stepsForm}
        deleteQuestion={deleteQuestion}
        passQuestionForUpdate={passQuestionForUpdate}
      />
    </>
  );
};

export default QuestionsBlock;
