import { useContext } from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { HRCoursesContext } from 'src/views/Courses/ui/pages/HRCourses/common/context';
import { HRCourseDetailToolbar, HRCourseUsersTable } from '../../blocks';


const HRCourseDetailBody = ({ isLoading: isRBACLoading }) => {
  const {
    dataFetching: {
      fetchCourseDetailQuery,
      fetchHRCourseUsersQuery
    }
  } = useContext(HRCoursesContext);

  return (
    <View isLoading={fetchHRCourseUsersQuery.isLoading || fetchHRCourseUsersQuery.isIdle || isRBACLoading}>
      <HRCourseDetailToolbar course={fetchCourseDetailQuery.data} />
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={fetchHRCourseUsersQuery.error || fetchCourseDetailQuery.error}>
          <HRCourseUsersTable data={fetchHRCourseUsersQuery.data} />
        </ErrorBoundary>
      </Box>
    </View>
  );

};

HRCourseDetailBody.propTypes = {
  isLoading: pt.bool
};

export default HRCourseDetailBody;
