import { useContext } from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { CustomersContext } from 'src/views/Customers/common/context';
import CustomersListToolBar from '../blocks/CustomersListToolBar';
import CustomersListResults from '../blocks/CustomersListResults';

const CustomersListBody = ({ isLoading: isRbacLoading }) => {
  const {
    dataFetching: { fetchCustomersQuery }
  } = useContext(CustomersContext);

  return (
    <View>
      <CustomersListToolBar />
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={null}>
          <CustomersListResults data={fetchCustomersQuery.data} />
        </ErrorBoundary>
      </Box>
    </View>
  );
};

CustomersListBody.defaultProps = {
  isLoading: false
};
CustomersListBody.propTypes = {
  isLoading: pt.bool
};

export default CustomersListBody;
