import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import Body from '../../../partials/Body';
import InstructorFormBody from './partials/InstructorFormBody';

const InstructorForm = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Create Instructor'>
      <InstructorFormBody isLoading={isLoading} />
    </Body>
  );
}

export default InstructorForm;
