import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';
import Body from 'src/views/Courses/ui/partials/Body';

import CourseFormBody from './partials/CourseFormBody';

const CourseForm = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Create Course'>
      <CourseFormBody isLoading={isLoading}/>
    </Body>
  );
};

export default CourseForm;
