import React from 'react';
import { Fab, FormControl, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';

const VariantsListBlock = ({ variants, setSelectedVariantForEdit, onChangeHandler, setVariantForm, setVariants }) => {
  const deleteVariant = (variantId) => {
    setVariants(variants.filter(variant => variant.id !== variantId));
  };

  return (
    variants.map(variant => {
      return (
        <React.Fragment key={variant.id}>
          <Grid
            item
            md={3}
            xs={3}
          >
            <FormControl fullWidth={true} variant='outlined'>
              <Select
                labelId='isCorrect'
                id='isCorrect'
                name='is_correct'
                value={variant.is_correct}
                fullWidth={true}
                disabled={true}
                onChange={({ target }) => onChangeHandler({ [target.name]: target.value })}
              >
                <MenuItem value={false}>Yanlış</MenuItem>
                <MenuItem value={true}>Doğru</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            md={9}
            xs={9}
          >
            <TextField
              id='variant'
              label='Add question variants'
              variant='outlined'
              fullWidth
              name='variant_title'
              value={variant.title}
              disabled={true}
              onChange={({ target }) => onChangeHandler({ [target.name]: target.value })}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment>
                      <Fab
                        size='small'
                        color='primary'
                        aria-label='add'
                        style={{ marginRight: '5px' }}
                        onClick={() => {
                          setSelectedVariantForEdit(variant);
                          setVariantForm({
                            title: variant.title,
                            is_correct: variant.is_correct
                          });
                        }}
                      >
                        <EditIcon />
                      </Fab>
                    </InputAdornment>
                    <InputAdornment>
                      <Fab
                        size='small'
                        color='secondary'
                        aria-label='edit'
                        onClick={() => deleteVariant(variant.id)}
                      >
                        <RemoveIcon />
                      </Fab>
                    </InputAdornment>
                  </>
                )
              }}
            />
          </Grid>
        </React.Fragment>
      );
    })
  );
};

export default VariantsListBlock;
