import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

import queryKeys from 'src/common/queryKeys';
import { privateRoutes } from 'src/common/mainRoutes';
import { formOperations } from 'src/common/constants';

import stateCreator from '../data/stateCreator';
import usePromoCodeCourses from './usePromoCodeCourses';
import usePromoCodeUsages from './usePromoCodeUsages';
import {
  createPromoCode,
  deletePromoCode,
  fetchPromoCodes,
  fetchPromoCodeDetail,
  updatePromoCode,
  changePromoCodeStatus
} from '../../api';

import { promoCodeTypes, promoCodeTypesList } from '../../constants';

const usePromoCodes = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const promoCodeCourses = usePromoCodeCourses();
  const promoCodeUsages = usePromoCodeUsages();

  const [queryParams, setQueryParams] = useState(() => stateCreator.createEmptyQuery());
  const [promoCodesForm, setPromoCodesForm] = useState(() => stateCreator.createEmpty());
  const [promoCodesFormErrors, setPromoCodesFormErrors] = useState(() => stateCreator.createEmptyFormErrors());

  const promoCodesFormController = (formObject) => setPromoCodesForm({ ...promoCodesForm, ...formObject });

  const fetchPromoCodesQuery = useQuery([queryKeys.FETCH_PROMO_CODES, queryParams], () => fetchPromoCodes(queryParams));

  const fetchPromoCodeDetailQuery = useQuery({
    queryKey: [queryKeys.FETCH_PROMO_CODE_DETAIL, id],
    queryFn: () => fetchPromoCodeDetail(id),
    onSuccess: (data) => {
      if (!!data) {
        setPromoCodesForm({ ...data, type: promoCodeTypesList.find(item => item.id === data.type) });
      }
    },
    enabled: !!id,
    cacheTime: 0,
    staleTime: 0
  });

  const changePromoCodeStatusMutation = useMutation({
    mutationKey: queryKeys.CHANGE_PROMO_CODE_STATUS,
    mutationFn: changePromoCodeStatus,
    onSuccess: data => enqueueSnackbar('Promo Code status updated was successfully', { variant: 'success' }),
    onError: data => enqueueSnackbar('An error occurred.', { variant: 'error' }),
});

  const createPromoCodeMutation = useMutation({
    mutationKey: queryKeys.CREATE_PROMO_CODE,
    mutationFn: createPromoCode
  });

  const updatePromoCodeMutation = useMutation({
    mutationKey: queryKeys.UPDATE_PROMO_CODE,
    mutationFn: updatePromoCode
  });

  const savePromoCodeFormFactory = ({ operation, savedCourseId }) => {
    switch (operation) {
      case formOperations.SAVE:
        setTimeout(() => navigate(privateRoutes.PROMO_CODES), 100);
        break;
      case formOperations.SAVE_AND_CONTINUE_CREATE:
        setPromoCodesForm(() => stateCreator.createEmpty());
        break;
      case formOperations.SAVE_AND_CONTINUE_EDIT:
        setTimeout(() => navigate(`${privateRoutes.PROMO_CODES_FORM}/${savedCourseId}`), 100);
        break;
      default:
        return false;
    }
  };

  const isValidPromoCodesForm = () => {
    const { type, promoCode, discount, expiredAt, primaryKeys } = promoCodesForm;
    const errorsObject = stateCreator.createEmptyFormErrors();

    errorsObject.type.error = !type;
    errorsObject.type.message = errorsObject.type.error ? 'Type can not be blank' : '';

    errorsObject.promoCode.error = !promoCode || promoCode.trim() === '';
    errorsObject.promoCode.message = errorsObject.promoCode.error ? 'Promo code can not be blank' : '';

    errorsObject.discount.error = !discount;
    errorsObject.discount.message = errorsObject.discount.error ? 'Discount can not be blank' : '';

    errorsObject.expiredAt.error = !expiredAt;
    errorsObject.expiredAt.message = errorsObject.expiredAt.error ? 'Expired at can not be blank' : '';

    if (type?.id !== promoCodeTypes.ALL) {
      errorsObject.primaryKeys.error = !primaryKeys || (_.isArray(primaryKeys) && primaryKeys.length === 0);
      errorsObject.primaryKeys.message = errorsObject.primaryKeys.error ? 'Sources at can not be blank' : '';
    }

    setPromoCodesFormErrors({ ...promoCodesFormErrors, ...errorsObject });

    return Object.values(errorsObject).find(field => field.error === true) === undefined;
  };

  const getPromoCodesFormData = () => {
    const { type, promoCode, discount, expiredAt, primaryKeys, usageLimit } = promoCodesForm;

    return {
      type: type.id,
      promoCode,
      discount,
      usageLimit,
      expiredAt,
      primaryKeys: !!primaryKeys ? (_.isArray(primaryKeys) ? primaryKeys.map(item => item.id) : [primaryKeys.id]) : null
    };
  };

  const savePromoCodeFormHandler = ({ operation, callback = null }) => {
    if (!isValidPromoCodesForm()) return false;

    if (!!id) {
      updatePromoCodeMutation.mutate({ id, requestData: getPromoCodesFormData() }, {
        onSuccess: data => {
          enqueueSnackbar('Promo Code updated was successfully', { variant: 'success' });
          savePromoCodeFormFactory({ operation });
          !!callback && callback();
        },
        onError: error => {
          enqueueSnackbar('An error occurred.', { variant: 'error' });
        }
      });
      return false;
    }

    createPromoCodeMutation.mutate(getPromoCodesFormData(), {
      onSuccess: data => {
        enqueueSnackbar('Promo code created was successfully', { variant: 'success' });
        savePromoCodeFormFactory({ operation, savedCourseId: data.id });
        !!callback && callback();
      },
      onError: error => {
        enqueueSnackbar('An error occurred.', { variant: 'error' });
      }
    });
  };

  const deletePromoCodeMutation = useMutation({
    mutationKey: queryKeys.DELETE_PROMO_CODE,
    mutationFn: deletePromoCode,
    onSuccess: data => {
      enqueueSnackbar('Promo code deleted was successfully', { variant: 'success' });
      queryClient.invalidateQueries(queryKeys.FETCH_PROMO_CODES);
    },
    onError: error => {
      enqueueSnackbar('An error occurred.', { variant: 'error' });
    }
  });

  const deletePromoCodeHandler = (id) => deletePromoCodeMutation.mutate(id);

  return {
    isNewRecord: !id,
    dataFetching: {
      fetchPromoCodesQuery,
      queryParams,
      setQueryParams,
      fetchPromoCodeDetailQuery
    },
    create: {
      promoCodesForm,
      setPromoCodesForm,
      promoCodesFormController,
      savePromoCodeFormHandler,
      createPromoCodeMutation,
      promoCodesFormErrors
    },
    update: {
      updatePromoCodeMutation,
      changePromoCodeStatusMutation
    },
    delete: {
      deletePromoCodeMutation,
      deletePromoCodeHandler
    },
    promoCodeCourses,
    promoCodeUsages
  };
};

export default usePromoCodes;
