export const courseSectionTypes = [
  {
    id: 'video',
    name: 'Video'
  },
  {
    id: 'pdf',
    name: 'Pdf'
  }
];
