export const PUBLIC_PATH = '';
export const PRIVATE_PATH = '/app';

export const publicRoutes = {
  LOGIN: `${PUBLIC_PATH}/authentication/login`,
  REGISTER: `${PUBLIC_PATH}/authentication/register`,
  PAGE_404: `${PUBLIC_PATH}/404`,
  ROOT: `${PUBLIC_PATH}/`
};

export const privateRoutes = {
  ACCOUNT: `${PRIVATE_PATH}/account`,
  COURSES: `${PRIVATE_PATH}/courses`,
  HR_COURSES: `${PRIVATE_PATH}/hr/courses`,
  COURSES_FORM: `${PRIVATE_PATH}/courses/form`,
  HR: `${PRIVATE_PATH}/hr`,
  HR_FORM: `${PRIVATE_PATH}/hr/form`,
  INSTRUCTORS: `${PRIVATE_PATH}/instructors`,
  INSTRUCTORS_FORM: `${PRIVATE_PATH}/instructors/form`,
  BLOGS: `${PRIVATE_PATH}/blogs`,
  BLOGS_FORM: `${PRIVATE_PATH}/blogs/form`,
  SALES: `${PRIVATE_PATH}/sales`,
  PROMO_CODES: `${PRIVATE_PATH}/promo-codes`,
  PROMO_CODES_FORM: `${PRIVATE_PATH}/promo-codes/form`,
  CUSTOMERS: `${PRIVATE_PATH}/customers`,
  DASHBOARD: `${PRIVATE_PATH}/dashboard`,
  PRODUCTS: `${PRIVATE_PATH}/products`,
  SETTINGS: `${PRIVATE_PATH}/settings`,
  PARTNER: `${PRIVATE_PATH}/statistics`,
};
