import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';

import { Autocomplete, Fab, Grid, InputAdornment, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import WithCondition from 'src/components/WithCondition';
import { preventSpecialChars } from 'src/common/functions/tools';

import stateCreator from 'src/views/HR/common/context/data/stateCreator';

const HRFormAssignment = ({ courses, customers, formValues, setFormValues }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [assignment, setAssignment] = useState(() => stateCreator.createEmptyAssignment());
  const [selectedAssignmentForEdit, setSelectedAssignmentForEdit] = useState(null);

  const addAssignment = () => {
    setFormValues(prevState => ({
      ...prevState,
      assignments: [...prevState.assignments, { id: uuidv4(), ...assignment }]
    }));
    setAssignment(() => stateCreator.createEmptyAssignment());
  };

  const updateAssignment = () => {
    if (!selectedAssignmentForEdit) return false;
    setFormValues(prevState => ({
      ...prevState,
      assignments: prevState.assignments.map(assignmentItem => {
        if (assignmentItem.id === assignment.id) return { id: selectedAssignmentForEdit.id, ...assignment };
        return assignmentItem;
      })
    }));
    resetAssignmentForm();
  };

  const deleteAssignment = id => setFormValues(prevState => ({
    ...prevState,
    assignments: prevState.assignments.filter(item => item.id !== id)
  }));

  const changeAssignmentFormHandler = (fieldObject) => {
    setAssignment(prevState => ({ ...prevState, ...fieldObject }));
  };

  const resetAssignmentForm = () => {
    setSelectedAssignmentForEdit(null);
    setAssignment(() => stateCreator.createEmptyAssignment());
  };

  const assignmentFormDisabled = assignment.students.length === 0 || assignment.courses.length === 0 || !assignment.department;
  const selectedStudentsCount = formValues.assignments.reduce((x, y) => x + y.students.length, 0);
  const addIconIsDisabled = selectedStudentsCount >= formValues.max_user_count;

  const allowedCustomers = () => {
    const alreadySelectedCustomers = formValues.assignments.map(assignmentItem => assignmentItem.students.map(item => item.id)).flat();
    let allowedCustomers = customers.filter(customerItem => alreadySelectedCustomers.indexOf(customerItem.id) === -1);

    if (!!selectedAssignmentForEdit) {
      const editedAssignmentCustomers = selectedAssignmentForEdit.students.map(item => customers.find(customerItem => customerItem.id === item.id));
      allowedCustomers = [...allowedCustomers, ...editedAssignmentCustomers];
    }

    return allowedCustomers;
  };

  const isUserMaxCountLimitOverLapping = (addedUsers = []) => {
    const addedUsersLength = addedUsers.length;
    const overLappingErrorMessage = `You can choose up to ${formValues.max_user_count} students. You need to change the 'Max user count' to get more options.`;

    if (!!selectedAssignmentForEdit) {
      const anotherAssignmentsStudentsCount = formValues.assignments.filter(assignmentItem =>
        assignmentItem.id !== selectedAssignmentForEdit.id).reduce((x, y) => x + y.students.length, 0);
      if (anotherAssignmentsStudentsCount + addedUsersLength > formValues.max_user_count) {
        enqueueSnackbar(overLappingErrorMessage, { variant: 'error' });
        return true;
      }
      return false;
    } else {
      if (addedUsersLength + selectedStudentsCount > formValues.max_user_count) {
        enqueueSnackbar(overLappingErrorMessage, { variant: 'error' });
        return true;
      }
      return false;
    }
  };

  return (
    <>
      <Grid
        item
        md={4}
        xs={12}
      >
        <Autocomplete
          multiple
          limitTags={1}
          id='students'
          disabled={addIconIsDisabled && !selectedAssignmentForEdit}
          options={allowedCustomers()}
          getOptionLabel={(option) => `${option.fullname} / ${option.email}`}
          value={assignment.students}
          onChange={(e, T) => {
            if (!isUserMaxCountLimitOverLapping(T)) {
              return changeAssignmentFormHandler({
                'students': T
              });
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label='Students' placeholder='Students' />
          )}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <Autocomplete
          multiple
          limitTags={1}
          id='courses'
          disabled={addIconIsDisabled && !selectedAssignmentForEdit}
          options={formValues.courses}
          value={assignment.courses}
          getOptionLabel={(option) => `${option.title} / ${option.instructor_name}`}
          onChange={(e, T) => changeAssignmentFormHandler({
            'courses': T
          })}
          renderInput={(params) => (
            <TextField {...params} label='Courses' placeholder='Courses' />
          )}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <TextField
          fullWidth
          placeholder='Department'
          name='department'
          value={assignment.department}
          disabled={addIconIsDisabled && !selectedAssignmentForEdit}
          onChange={e => changeAssignmentFormHandler({
            [e.target.name]: preventSpecialChars(e.target.value)
          })}
          InputProps={{
            endAdornment: (
              <>
                <WithCondition isDisplay={!selectedAssignmentForEdit}>
                  <InputAdornment position='end' disablePointerEvents={addIconIsDisabled || assignmentFormDisabled}>
                    <Fab
                      disabled={addIconIsDisabled || assignmentFormDisabled}
                      size='small'
                      color='primary'
                      aria-label='add'
                      onClick={addAssignment}
                    >
                      <AddIcon />
                    </Fab>
                  </InputAdornment>
                </WithCondition>
                <WithCondition isDisplay={!!selectedAssignmentForEdit}>
                  <InputAdornment
                    position='end'
                    disablePointerEvents={assignmentFormDisabled}
                  >
                    <Fab
                      disabled={assignmentFormDisabled}
                      size='small' color='primary' aria-label='add'
                      onClick={() => updateAssignment()}
                    >
                      <DoneIcon />
                    </Fab>
                  </InputAdornment>
                  <InputAdornment
                    position='end'
                  >
                    <Fab
                      onClick={resetAssignmentForm}
                      size='small' color='primary' aria-label='add'
                    >
                      <ClearIcon />
                    </Fab>
                  </InputAdornment>
                </WithCondition>
              </>
            )
          }}
        />
      </Grid>
      {
        formValues.assignments.map(assignmentItem => (
          <React.Fragment key={assignmentItem.id}>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Autocomplete
                multiple
                limitTags={1}
                id='students'
                disabled={true}
                options={customers}
                getOptionLabel={(option) => `${option.fullname} / ${option.email}`}
                value={assignmentItem.students}
                onChange={(e, T) => changeAssignmentFormHandler({
                  'students': T
                })}
                renderInput={(params) => (
                  <TextField {...params} label='Students' placeholder='Students' />
                )}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Autocomplete
                multiple
                limitTags={1}
                disabled={true}
                id='courses'
                options={courses}
                value={assignmentItem.courses}
                getOptionLabel={(option) => option.title}
                onChange={(e, T) => changeAssignmentFormHandler({
                  'courses': T
                })}
                renderInput={(params) => (
                  <TextField {...params} label='Courses' placeholder='Courses' />
                )}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                placeholder='Department'
                name='department'
                value={assignmentItem.department}
                onChange={e => changeAssignmentFormHandler({
                  [e.target.name]: e.target.value
                })}
                disabled={true}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment
                        position='end'
                        disablePointerEvents={!!selectedAssignmentForEdit}
                      >
                        <Fab
                          size='small'
                          color='primary'
                          aria-label='edit'
                          disabled={!!selectedAssignmentForEdit}
                          onClick={() => {
                            setSelectedAssignmentForEdit(assignmentItem);
                            setAssignment(assignmentItem);
                          }}
                        >
                          <EditIcon />
                        </Fab>
                      </InputAdornment>
                      <InputAdornment
                        position='end'
                        disablePointerEvents={!!selectedAssignmentForEdit}
                      >
                        <Fab
                          size='small'
                          color='secondary'
                          aria-label='delete'
                          disabled={!!selectedAssignmentForEdit}
                          onClick={() => deleteAssignment(assignmentItem.id)}
                        >
                          <RemoveIcon />
                        </Fab>
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </Grid>
          </React.Fragment>
        ))
      }
    </>
  );
};

export default HRFormAssignment;
