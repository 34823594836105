import { steps } from '../../../ui/pages/CourseForm/common/constants';

export default {
  createEmpty: () => [],
  createEmptyQuery: () => ({
    page: 0,
    limit: 10,
    orderBy: 'id',
    order: 'desc',
    title: "",
    fromDate:"",
    toDate:""
  }),
  createEmptyStepsForm: () => ({
    [steps.COURSE_INFORMATION]: {
      language: null,
      instructor: null,
      title: '',
      subtitle: '',
      promo_video: '',
      price: '',
      discount: '',
      cover: null,
      description: '',
      
    },
    [steps.COURSE_CONTENT]: {
      categories: [],
      learnings: [],
      requirements: [],
      benefits: [],
      sections: []
    },
    [steps.CREATE_VIDEOS]: {
      courseId: null,
      sectionNames: [],
      sections: []
    },
    [steps.ADD_QUIZ]: {
      quizzes: []
    }
  }),
  createEmptyStepsFormErrors: () => ({
    [steps.COURSE_INFORMATION]: {
      language: { error: false, message: '' },
      instructor: { error: false, message: '' },
      title: { error: false, message: '' },
      subtitle: { error: false, message: '' },
      promo_video: { error: false, message: '' },
      price: { error: false, message: '' },
      cover: { error: false, message: '' },
      description: { error: false, message: '' }
    },
    [steps.COURSE_CONTENT]: {
      categories: { error: false, message: '' },
      learnings: { error: false, message: '' },
      requirements: { error: false, message: '' },
      benefits: { error: false, message: '' },
      sections: { error: false, message: '' }
    },
    [steps.CREATE_VIDEOS]: {
      courseId: null,
      sections: { error: false, message: '' }
    }
  }),
  createEmptyVideoSection: () => ({
    url: '',
    title: '',
    type: null,
    duration: '',
    description: ''
  }),
  createEmptyVariantForm: () => ({
    title: '',
    is_correct: false
  })
};
