import pt from 'prop-types';

import useAuth from './hooks/useAuth';
import AuthContext from './AuthContext';

const AuthProvider = ({ children }) => {
  const contextData = useAuth();

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: pt.any.isRequired
};

export default AuthProvider;
