import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import SettingsPassword from 'src/components/settings/SettingsPassword';
import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

const SettingsView = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR, rights.INSTRUCTOR]});

  return (
    <>
      <Helmet>
        <title>Settings | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ pt: 3 }}>
            <SettingsPassword />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default SettingsView;
