import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import * as yup from 'yup';
import queryKeys from '../../common/queryKeys';
import { updateAccount } from '../../views/Account/common/api';
import ButtonLoader from '../ButtonLoader';
import { useSnackbar } from 'notistack';

const schema = yup.object({
  email: yup.string().email().max(32).required('Email is required'),
  professional: yup.string().max(32).required('Profession is required'),
  fullname: yup.string().max(16).required('Full name is required')
});

const AccountProfileDetails = ({ auth, setAuth, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { authUser } = auth;

  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fullname: authUser.fullname,
      email: authUser.email,
      professional: authUser.professional
    }
  });

  const updateAccountMutation = useMutation({
    mutationKey: queryKeys.UPDATE_ACCOUNT,
    mutationFn: updateAccount,
    onSuccess: data => {
      setAuth(prevAuth => ({ ...prevAuth, authUser: { ...authUser, ...data } }));
      enqueueSnackbar('Your account changed was successfully', { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  });

  const submitFormHandler = (data) => updateAccountMutation.mutate(data);

  return (
    <form
      autoComplete='off'
      noValidate
      onSubmit={handleSubmit(submitFormHandler)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader='The information can be edited'
          title='Profile'
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Controller
                control={control}
                name='fullname'
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.fullname}
                    helperText={errors.fullname?.message}
                    fullWidth
                    label='Full name'
                    margin='normal'
                    name='fullname'
                    variant='outlined'
                  />
                )}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Controller
                control={control}
                name='email'
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    fullWidth
                    label='Email Address'
                    margin='normal'
                    name='email'
                    type='email'
                    variant='outlined'
                  />
                )}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Controller
                control={control}
                name='professional'
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.professional}
                    helperText={errors.professional?.message}
                    fullWidth
                    label='Profession'
                    margin='normal'
                    name='professional'
                    variant='outlined'
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <ButtonLoader
            color='primary'
            variant='contained'
            type='submit'
            disabled={updateAccountMutation.isLoading}
            isLoading={updateAccountMutation.isLoading}
          >
            Save details
          </ButtonLoader>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
