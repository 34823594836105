import pt from 'prop-types';

const WithCondition = ({ children, isDisplay }) => {
  if (!isDisplay) return null;

  return children;
};

WithCondition.defaultProps = {
  isDisplay: false
};

WithCondition.propTypes = {
  isDisplay: pt.bool.isRequired,
  children: pt.any.isRequired
};

export default WithCondition;
