import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import queryKeys from 'src/common/queryKeys';
import { privateRoutes } from 'src/common/mainRoutes';
import { formOperations } from 'src/common/constants';

import stateCreator from '../data/stateCreator';
import {
  createBlog,
  updateBlog,
  deleteBlog,
  fetchBlogs,
  fetchBlogDetail,
} from '../../api';

const useBlogs = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [queryParams, setQueryParams] = useState(stateCreator.createEmptyQuery());

  const fetchAllBlogsQuery = useQuery([queryKeys.FETCH_BLOGS, queryParams], () => fetchBlogs(queryParams));

  const fetchBlogDetailQuery = useQuery({
    queryKey: [queryKeys.FETCH_BLOG_DETAIL, id],
    queryFn: () => fetchBlogDetail(id),
    enabled: !!id,
    cacheTime: 0,
    staleTime: 0
  });

  const createBlogMutation = useMutation({
    mutationKey: queryKeys.CREATE_BLOG,
    mutationFn: createBlog
  });

  const updateBlogMutation = useMutation({
    mutationKey: queryKeys.UPDATE_BLOG,
    mutationFn: updateBlog
  });

  const saveBlogFormFactory = ({ operation, data, reset }) => {
    switch (operation) {
      case formOperations.SAVE:
        setTimeout(() => navigate(privateRoutes.BLOGS), 100);
        break;
      case formOperations.SAVE_AND_CONTINUE_CREATE:
        reset();
        break;
      case formOperations.SAVE_AND_CONTINUE_EDIT:
        setTimeout(() => navigate(`${privateRoutes.BLOGS_FORM}/${data.id}`), 100);
        break;
      default:
        return false;
    }
  };

  const saveBlogHandler = ({ requestData, operation, reset, callback = null }) => {
    const { title, p_image, blog, status } = requestData;
    const formData = new FormData();

    formData.append('title', title);
    formData.append('p_image', p_image[0]);
    formData.append('blog', blog);
    formData.append('status', status);

    if (!!id) {
      formData.append('id', id);
      updateBlogMutation.mutate({id, requestData: formData}, {
        onSuccess: data => {
          enqueueSnackbar('Blog updated was successfully', { variant: 'success' });
          saveBlogFormFactory({ operation, data, reset });
          !!callback && callback();
        },
        onError: error => {
          enqueueSnackbar('An error occurred.', { variant: 'error' });
        }
      });
      return false;
    }

    createBlogMutation.mutate(formData, {
      onSuccess: data => {
        enqueueSnackbar('Blog created was successfully', { variant: 'success' });
        saveBlogFormFactory({ operation, data, reset });
        !!callback && callback();
      },
      onError: error => {
        enqueueSnackbar('An error occurred.', { variant: 'error' });
      }
    });
  };

  const deleteBlogMutation = useMutation({
    mutationKey: queryKeys.DELETE_BLOG,
    mutationFn: deleteBlog,
    onSuccess: data => {
      enqueueSnackbar('Blog deleted was successfully', { variant: 'success' });
      queryClient.invalidateQueries(queryKeys.FETCH_BLOGS);
    },
    onError: error => {
      enqueueSnackbar('An error occurred.', { variant: 'error' });
    }
  });

  const deleteBlogHandler = (id) => deleteBlogMutation.mutate({ id });

  return {
    isNewRecord: !id,
    dataFetching: {
      fetchAllBlogsQuery,
      queryParams,
      setQueryParams,
      fetchBlogDetailQuery
    },
    create: {
      saveBlogHandler,
      createBlogMutation,
    },
    update: {
      updateBlogMutation
    },
    delete: {
      deleteBlogMutation,
      deleteBlogHandler
    }
  };
};

export default useBlogs;
