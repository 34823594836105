import { useContext } from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { InstructorRequestsContext } from 'src/views/InstructorRequests/common/context';

import InstructorRequestsListResults from '../blocks/InstructorRequestsListResults';
import InstructorRequestsListToolbar from '../blocks/InstructorRequestsListToolBar';


const InstructorRequestsListBody = ({isLoading: isRbacLoading}) => {
  const {
    dataFetching: {
      fetchInstructorRequestsQuery,
      queryParams,
      setQueryParams
    }
  } = useContext(InstructorRequestsContext);

  return (
    <View isLoading={fetchInstructorRequestsQuery.isLoading || isRbacLoading}>
      <InstructorRequestsListToolbar queryParams={queryParams} setQueryParams={setQueryParams} />
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={fetchInstructorRequestsQuery.error}>
          <InstructorRequestsListResults data={fetchInstructorRequestsQuery.data} />
        </ErrorBoundary>
      </Box>
    </View>
  );
};

InstructorRequestsListBody.defaultProps = {
  isLoading: false
};

InstructorRequestsListBody.propTypes = {
  isLoading: pt.bool
};

export default InstructorRequestsListBody;
