import axiosInstance from '../../../common/config/api';
import apiRoutes from '../../../common/api/routes';

export const fetchAllInstructors = (requestData) =>
  axiosInstance.get(apiRoutes.FETCH_ALL_INSTRUCTORS(), { params: requestData }).then(res => res.data);

export const fetchInstructorDetail = (id) =>
  axiosInstance.get(apiRoutes.FETCH_INSTRUCTOR_DETAIL(id)).then(res => res.data);

export const createInstructor = (requestData) =>
  axiosInstance.post(apiRoutes.CREATE_INSTRUCTOR(), requestData).then(res => res.data);

export const updateInstructor = (requestData) =>
  axiosInstance.put(apiRoutes.UPDATE_INSTRUCTOR(), requestData).then(res => res.data);

export const deleteInstructor = (requestData) =>
  axiosInstance.delete(apiRoutes.DELETE_INSTRUCTOR(), requestData).then(res => res.data);

export const fetchInstructorSales = (id, requestData) =>
  axiosInstance.get(apiRoutes.FETCH_INSTRUCTOR_SALES(id), {params: requestData}).then(res => res.data);
