import React from 'react';
import pt from 'prop-types';
import { Grid } from '@material-ui/core';

import ButtonLoader from 'src/components/ButtonLoader';
import WithCondition from 'src/components/WithCondition';
import { formOperations } from 'src/common/constants';

const PromoCodesFormActions = ({ isLoading, isDisableActions, savePromoCodeForm, isNewRecord }) => {

  return (
    <Grid
      item
      md={12}
      xs={12}
    >
      <ButtonLoader
        color='primary'
        variant='contained'
        isDisabled={isDisableActions}
        isLoading={isLoading}
        onClick={() => savePromoCodeForm({ operation: formOperations.SAVE })}
      >Save and exit</ButtonLoader>
      <WithCondition isDisplay={isNewRecord}>
        <ButtonLoader
          color='secondary'
          variant='contained'
          isDisabled={isDisableActions}
          isLoading={isLoading}
          onClick={() => savePromoCodeForm({ operation: formOperations.SAVE_AND_CONTINUE_CREATE })}
        >Save and continue create</ButtonLoader>
        <ButtonLoader
          color='inherit'
          variant='contained'
          isDisabled={isDisableActions}
          isLoading={isLoading}
          onClick={() => savePromoCodeForm({ operation: formOperations.SAVE_AND_CONTINUE_EDIT })}
        >Save and continue edit</ButtonLoader>
      </WithCondition>
    </Grid>
  );
};

PromoCodesFormActions.defaultProps = {
  isLoading: false,
  isNewRecord: true,
  isDisableActions: true
};

PromoCodesFormActions.propTypes = {
  isLoading: pt.bool,
  savePromoCodeForm: pt.func.isRequired,
  isNewRecord: pt.bool.isRequired,
  isDisableActions: pt.bool.isRequired
};

export default PromoCodesFormActions;
