import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import Body from '../../../partials/Body';
import HRListBody from './partials/HRListBody';

const InstructorsList = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Human Resources'>
      <HRListBody isLoading={isLoading} />
    </Body>
  );
};

export default InstructorsList;
