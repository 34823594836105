import { Button, Divider, Select, Spin, Table, Tag } from 'antd';
import React, { useState } from 'react';
import apiRoutes from 'src/common/api/routes';
import axiosInstance from 'src/common/config/api';

export default function DetailSalesForInstructorsAndCourses() {
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [data, setData] = useState([]);
  const columns = [
    
    {
      title: 'Ad',
      dataIndex: 'fullname',
      key: 'fullname',
      render: (text) => <Tag color="blue">{text}</Tag>
    },
    {
        title: 'Məbləğ',
        dataIndex: 'price',
        key: 'price',
        render: (text) => <Tag color="red">{text} &nbsp;Azn</Tag>
      },
    
  ];
  const months = [
    'Yanvar',
    'Fevral',
    'Mart',
    'Aprel',
    'May',
    'Iyun',
    'Iyul',
    'Avqust',
    'Sentyabr',
    'Oktyabr',
    'Noyabr',
    'Dekabr'
  ];
  const years = [2018, 2019, 2020, 2021, 2022, 2023, 2024];
  const onChangeMonth = (value) => {
    setSelectedMonth(value);
  };
  const onChangeYear = (value) => {
    setSelectedYear(value);
  };
  const searchData = () => {
    console.log(selectedYear, selectedMonth);
    axiosInstance.get(apiRoutes.FETCH_SALES_DETAIL_FOR_INSTRUCTORS(selectedMonth,selectedYear)).then((res) => {
        setData(res?.data?.result);
      });
  };
  return (
    <Spin spinning={loading}>
        <Divider orientation='left'>İl və ay seçiminə görə instruktor satışları</Divider>
      <div style={{ display: 'flex', gap:"5px" }}>
        <Select
          showSearch
          placeholder="Ay seçin"
          optionFilterProp="children"
          onChange={onChangeMonth}
          style={{ width: '100%' }}
          allowClear
        >
          {months.map((item, index) => (
            <Option key={index} value={index}>
              {item}
            </Option>
          ))}
        </Select>
        <Select
          showSearch
          placeholder="İl seçin"
          optionFilterProp="children"
          onChange={onChangeYear}
          style={{ width: '100%' }}
          allowClear
        >
          {years.map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
        </Select>
        <Button type="primary" onClick={searchData}>
          Axtar
        </Button>
      </div>
      <div>
      <Table
        columns={columns}
        pagination={{
          pageSize: 5
        }}
        dataSource={data}
      />
      </div>
    </Spin>
  );
}
