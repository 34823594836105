import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomersContext } from 'src/views/Customers/common/context';
import {privateRoutes} from 'src/common/mainRoutes';
import { useContext, useState } from 'react';
import { DatePicker } from 'antd';
import axiosInstance from 'src/common/config/api';
const { RangePicker } = DatePicker;
const CustomersListToolbar = (props) => {
  const {
    dataFetching: { queryParams, setQueryParams },
    customerCourses
  } = useContext(CustomersContext);

  const [searchData, setSearchData] = useState('');
  const [date, setDate] = useState([]);
  const changeSearch = (e) => {
    setSearchData(e.target.value);
  };
  const searchBytitle = (e) => {
    if (date?.length > 0) {
      setQueryParams({
        ...queryParams,
        fullname: searchData,
        fromDate: date[0],
        toDate: date[1]
      });
    } else {
      setQueryParams({ ...queryParams, searchField: searchData });
      console.log(searchData)
    }
  };
  const downloadReport = () => {
    let url="" 
    // http://admin-api.safavy.org/customers/export?toDate=2023-10-07&fromDate=2023-05-05&searchField=rufat
    if(date?.length===2)
    {
       url='customers/export?fromDate='+date[0]+"&toDate="+date[1]+"&searchField="+searchData
    }
    else{
       url='customers/export?searchField='+searchData
    }
    axiosInstance
    .get(url)
    .then((res) => {
      const linkSource = `data:application/vnd.ms-excel;base64,${res?.data}`;
      const downloadLink = document.createElement('a');
      const fileName = `customers.xlsx`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
  })}
  const changeDate = (val, val2) => {
    setDate(val2);
  };
  return (
    <Box {...props}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
        <div style={{ display: 'flex' }}>
              <Box style={{ width: '100%' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ display: 'flex', gap:"5px" }}>
                    <TextField
                    value={searchData}
                      onChange={changeSearch}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search hr"
                      variant="outlined"
                    />
                    <RangePicker style={{width:"400px"}} onChange={changeDate} />
                    <Button onClick={searchBytitle}> Axtar </Button>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Button
                    onClick={downloadReport}
                    >
                      Export
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
        </CardContent>
      </Card>
    </Box>
  </Box>
  )
}

export default CustomersListToolbar;
