import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import {privateRoutes} from 'src/common/mainRoutes';
import WithCondition from 'src/components/WithCondition';

import { HRContext } from 'src/views/HR/common/context';
import { DeleteHRModal } from '../../components';
import { useContext, useState } from 'react';
import { DatePicker } from 'antd';
import axiosInstance from 'src/common/config/api';
const { RangePicker } = DatePicker;
const HRListToolbar = (props) => {
  const {
    dataFetching: { queryParams, setQueryParams },
    delete: {
      deleteHRHandler,
      deleteHRMutation
    }
  } = useContext(HRContext);
  const [searchData, setSearchData] = useState('');
  const [date, setDate] = useState([]);
  const changeSearch = (e) => {
    setSearchData(e.target.value);
  };
  const searchBytitle = (e) => {
    if (date?.length > 0) {
      setQueryParams({
        ...queryParams,
        fullname: searchData,
        fromDate: date[0],
        toDate: date[1]
      });
    } else {
      setQueryParams({ ...queryParams, fullname: searchData });
      console.log(searchData)
    }
  };
  const downloadReport = () => {
    let url="" 
    if(date?.length===2)
    {
       url='hr/export?fromDate='+date[0]+"&toDate="+date[1]
    }
    else{
       url='hr/export/'
    }
    axiosInstance
    .get(url)
    .then((res) => {
      const linkSource = `data:application/vnd.ms-excel;base64,${res?.data}`;
      const downloadLink = document.createElement('a');
      const fileName = `hrexport.xlsx`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
  })}
  const changeDate = (val, val2) => {
    setDate(val2);
  };
  return(
    <Box {...props}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <Link to={privateRoutes.HR_FORM}>
        <Button
          color='primary'
          variant='contained'
        >
          Add Human Resource
        </Button>
      </Link>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
        <div style={{ display: 'flex' }}>
              <Box style={{ width: '100%' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ display: 'flex', gap:"5px" }}>
                    <TextField
                    value={searchData}
                      onChange={changeSearch}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search hr"
                      variant="outlined"
                    />
                    <RangePicker style={{width:"400px"}} onChange={changeDate} />
                    <Button onClick={searchBytitle}> Axtar </Button>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Button
                    onClick={downloadReport}
                    >
                      Export
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
        </CardContent>
      </Card>
    </Box>
  </Box>
  )
}

export default HRListToolbar;
