import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import { set } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import axiosInstance from 'src/common/config/api';
import { AuthContext } from 'src/common/context/auth';

export default function Statistic(props) {
  const { auth } = useContext(AuthContext);

  const [clickDetail, setClickDetail] = useState();
  const [paymentDetail, setPaymentDetail] = useState([]);
  const [paymentCount, setPaymentCount] = useState();
  const getClickDetail = () => {
    // auth?.authUser?.id
    axiosInstance
      .get('customers/by-influencer/click-detail/' + auth?.authUser?.id)
      .then((res) => {
        setClickDetail(res?.data?.data[0]);
      });
  };
  const getPaymentDetail = () => {
    axiosInstance
      .get('customers/by-influencer/payment-detail/' + auth?.authUser?.id)
      .then((res) => {
        setPaymentDetail(res?.data?.data);
      });
  };
  const getPaymentCount = () => {
    axiosInstance
      .get('customers/by-influencer/payment-count/' + auth?.authUser?.id)
      .then((res) => {
        setPaymentCount(res?.data?.data[0]?.count_pament);
      });
  };
  useEffect(() => {
    getClickDetail();
    getPaymentDetail();
    getPaymentCount();
  }, []);

  return (
    <>
      <Grid
        container
        // spacing={3}
        // style={{ display: hasRole(rights.MODERATOR) ? '' : 'none' }}
      >
        <Card
          style={{ padding: '10px', marginTop: '10px', marginLeft: '10px' }}
          {...props}
        >
          <CardContent>
            <Grid
              container
              spacing={5}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  Ümumi keçid sayı
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {clickDetail?.click_count?clickDetail?.click_count:0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  sx={{
                    backgroundColor: green[600],
                    height: 56,
                    width: 56
                  }}
                >
                  <PeopleIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                pt: 2
              }}
            ></Box>
          </CardContent>
        </Card>
        <Card
          style={{ padding: '10px', marginTop: '10px', marginLeft: '10px' }}
          {...props}
        >
          <CardContent>
            <Grid
              container
              spacing={5}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  Alınan kurs sayı
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {paymentCount}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  sx={{
                    backgroundColor: green[600],
                    height: 56,
                    width: 56
                  }}
                >
                  <PeopleIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                pt: 2
              }}
            ></Box>
          </CardContent>
        </Card>
      </Grid>
      <Box sx={{ minWidth: 1050 }}>
        <h2 style={{color:"#404040", marginLeft:"10px",padding:"10px"}}>Alınmış kursların siyahısı</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ad, Soyad</TableCell>
              <TableCell>Faiz</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Qiymet</TableCell>
              <TableCell>Kurs adı</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentDetail?.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell>{item?.fullname}</TableCell>

                <TableCell>{item?.influencer_percent}</TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell>{item?.price}</TableCell>
                <TableCell>{item?.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
