import useRBAC from 'src/common/hooks/useRBAC';
import { rights } from 'src/common/constants';

import Body from 'src/views/Blogs/ui/partials/Body';
import BlogsFormBody from './partials/BlogsFormBody';

const BlogsForm = () => {
  const { isLoading } = useRBAC({ rights: [rights.MODERATOR] });

  return (
    <Body title='Create Blogs'>
      <BlogsFormBody isLoading={isLoading} />
    </Body>
  );
}

export default BlogsForm;
