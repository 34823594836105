export const EDITOR_INITIALIZATION = {
  menubar: true,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount'
  ],
  toolbar: 'undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
};

export const formOperations = {
  SAVE: 'SAVE',
  SAVE_AND_CONTINUE_CREATE: 'SAVE_AND_CONTINUE_CREATE',
  SAVE_AND_CONTINUE_EDIT: 'SAVE_AND_CONTINUE_EDIT'
};
