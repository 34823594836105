import { Button, Divider, Select, Spin, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import apiRoutes from 'src/common/api/routes';
import axiosInstance from 'src/common/config/api';

export default function SalesCategoryMonthly() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const years = [
    'Yanvar',
    'Fevral',
    'Mart',
    'Aprel',
    'May',
    'Iyun',
    'Iyul',
    'Avqust',
    'Sentyabr',
    'Oktyabr',
    'Noyabr',
    'Dekabr'
  ];
  const columns = [
    {
      title: 'Kategoriya adı',
      dataIndex: 'category',
      key: 'category',
      render: (text) => <Tag color="blue">{text}</Tag>
    },
    {
      title: 'Məbləğ',
      dataIndex: 'price',
      key: 'price',
      render: (text) => <Tag color="red">{text} &nbsp;Azn</Tag>
    },
    {
      title: 'Ay',
      dataIndex: 'month',
      key: 'month',
      render: (text) => <Tag color="blue">{years[Number(text) - 1]}</Tag>
    },
    {
      title: 'Il',
      dataIndex: 'year',
      key: 'year'
    }
  ];
  useEffect(() => {
    axiosInstance
      .get(apiRoutes.FETCH_MODERATOR_SALES_BY_CATEGORY_MONTHLY())
      .then((res) => {
        console.log('test', res);
        setData(res?.data);
      });
  }, []);
  return (
    <Spin spinning={loading}>
      <Divider orientation='left'>Tarixə görə satılan kursların kateqoriyaları</Divider>
      <div>
        <Table
          columns={columns}
          pagination={{
            pageSize: 5
          }}
          dataSource={data}
        />
      </div>
    </Spin>
  );
}
