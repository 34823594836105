import { useContext } from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { PromoCodesContext } from 'src/views/PromoCodes/common/context';
import PromoCodesListToolBar from '../blocks/PromoCodesListToolBar';
import PromoCodesListResults from '../blocks/PromoCodesListResults';

const PromoCodesListBody = ({isLoading: isRbacLoading}) => {
  const {
    dataFetching: { fetchPromoCodesQuery }
  } = useContext(PromoCodesContext);

  return (
    <View isLoading={fetchPromoCodesQuery.isLoading || isRbacLoading}>
      <PromoCodesListToolBar />
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={null}>
          <PromoCodesListResults data={fetchPromoCodesQuery.data} />
        </ErrorBoundary>
      </Box>
    </View>
  );
};

PromoCodesListBody.defaultProps = {
  isLoading: false
};

PromoCodesListBody.propTypes = {
  isLoading: pt.bool
};

export default PromoCodesListBody;
