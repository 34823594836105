import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import {
  Box,
  Card,
  Checkbox, Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Trash as TrashIcon,
  BookOpen as BookOpenIcon,
  BarChart2 as BarChart2Icon
} from 'react-feather';

import { privateRoutes } from 'src/common/mainRoutes';
import WithCondition from 'src/components/WithCondition';

import { PromoCodesContext } from 'src/views/PromoCodes/common/context';
import { promoCodeTypes } from 'src/views/PromoCodes/common/constants';
import { DeletePromoCodeModal, CoursesListModal, UsagesListModal } from '../../components';

const promoCodesListResults = ({ data = { count: 0, promoCodes: [] }, ...rest }) => {
  const {
    dataFetching: { queryParams, setQueryParams },
    delete: {
      deletePromoCodeHandler,
      deletePromoCodeMutation
    },
    update: {
      changePromoCodeStatusMutation
    },
    promoCodeCourses,
    promoCodeUsages
  } = useContext(PromoCodesContext);

  const [selectedPromoCodeIds, setSelectedPromoCodeIds] = useState([]);
  const [promoCodeForDelete, setPromoCodeForDelete] = useState(null);

  const handleSelectAll = (event) => {
    let newSelectedPromoCodeIds;

    if (event.target.checked) {
      newSelectedPromoCodeIds = data.promoCodes.map(promoCodeItem => promoCodeItem.id);
    } else {
      newSelectedPromoCodeIds = [];
    }

    setSelectedPromoCodeIds(newSelectedPromoCodeIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedPromoCodeIds.indexOf(id);
    let newSelectedPromoCodeIds = [];

    if (selectedIndex === -1) {
      newSelectedPromoCodeIds = newSelectedPromoCodeIds.concat(selectedPromoCodeIds, id);
    } else if (selectedIndex === 0) {
      newSelectedPromoCodeIds = newSelectedPromoCodeIds.concat(selectedPromoCodeIds.slice(1));
    } else if (selectedIndex === selectedPromoCodeIds.length - 1) {
      newSelectedPromoCodeIds = newSelectedPromoCodeIds.concat(selectedPromoCodeIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPromoCodeIds = newSelectedPromoCodeIds.concat(
        selectedPromoCodeIds.slice(0, selectedIndex),
        selectedPromoCodeIds.slice(selectedIndex + 1)
      );
    }

    setSelectedPromoCodeIds(newSelectedPromoCodeIds);
  };

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedPromoCodeIds.length === data.promoCodes.length}
                    color='primary'
                    indeterminate={
                      selectedPromoCodeIds.length > 0
                      && selectedPromoCodeIds.length < data.promoCodes.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Promo kod
                </TableCell>
                <TableCell>
                  Tip
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Endirim
                </TableCell>
                <TableCell>
                  Limiti / İstifadə edilib
                </TableCell>
                <TableCell>
                  Bitmə tarixi
                </TableCell>
                <TableCell>
                  Kurslar
                </TableCell>
                <TableCell>
                  Statistika
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Əməliyyatlar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.promoCodes.slice(0, queryParams.limit).map((promoCode) => (
                <TableRow
                  hover
                  key={promoCode.id}
                  selected={selectedPromoCodeIds.indexOf(promoCode.id) !== -1}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedPromoCodeIds.indexOf(promoCode.id) !== -1}
                      onChange={(event) => handleSelectOne(event, promoCode.id)}
                      value='true'
                    />
                  </TableCell>
                  <TableCell>
                    {promoCode.promoCode}
                  </TableCell>
                  <TableCell>
                    {promoCode.type}
                  </TableCell>
                  <TableCell>
                    {promoCode.status ? 'Aktiv' : 'Deaktiv'}
                  </TableCell>
                  <TableCell>
                    {promoCode.discount} %
                  </TableCell>
                  <TableCell>
                    <strong>{`${promoCode.usageLimit} / ${promoCode.usedCount}`}</strong>
                  </TableCell>
                  <TableCell>
                    {moment(promoCode.expiredAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => promoCodeCourses.setPromoCode(promoCode)}
                      disabled={promoCodeCourses.fetchPromoCodeCoursesQuery.isLoading || promoCode.type !== promoCodeTypes.COURSE_SPECIFIC}
                    >
                      <BookOpenIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => promoCodeUsages.setPromoCode(promoCode)}
                      disabled={promoCodeCourses.fetchPromoCodeCoursesQuery.isLoading}
                    >
                      <BarChart2Icon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={!!promoCode.status}
                      onChange={() => changePromoCodeStatusMutation.mutate(promoCode.id)}
                      color='primary'
                      disabled={changePromoCodeStatusMutation.isLoading}
                    />
                  </TableCell>
                  <TableCell>
                    <Link to={`${privateRoutes.PROMO_CODES_FORM}/${promoCode.id}`}>
                      <IconButton aria-label='edit'>
                        <EditIcon strokeWidth={1.5} size={18} />
                      </IconButton>
                    </Link>
                    <IconButton
                      onClick={() => setPromoCodeForDelete(promoCode)}
                      disabled={deletePromoCodeMutation.isLoading}
                      aria-label='delete'
                    >
                      <TrashIcon strokeWidth={1.5} size={18} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <WithCondition isDisplay={!!promoCodeForDelete}>
        <DeletePromoCodeModal
          open={!!promoCodeForDelete}
          closeModal={() => setPromoCodeForDelete(null)}
          onDelete={() => deletePromoCodeHandler(promoCodeForDelete?.id)}
        />
      </WithCondition>
      <WithCondition isDisplay={!!promoCodeCourses.promoCode}>
        <CoursesListModal promoCodeCourses={promoCodeCourses} />
      </WithCondition>
      <WithCondition isDisplay={!!promoCodeUsages.promoCode}>
        <UsagesListModal promoCodeUsages={promoCodeUsages} />
      </WithCondition>
    </Card>
  );
};

promoCodesListResults.defaultProps = {
  data: {
    promoCodes: [],
    count: 0
  }
};

promoCodesListResults.propTypes = {
  data: PropTypes.object.isRequired
};

export default promoCodesListResults;
