import { useContext } from 'react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  Container, FormControl, InputLabel,
  Link, MenuItem, Select,
  TextField,
  Typography
} from '@material-ui/core';

import { AuthContext } from '../../common/context/auth';
import { privateRoutes } from '../../common/mainRoutes';

import ButtonLoader from '../../components/ButtonLoader';
import View from '../../components/view';

const schema = yup.object({
  email: yup.string().email().required('Email is required'),
  password: yup.string().min(6).max(16).required('Password is required')
});

const Login = () => {
  const { login, auth } = useContext(AuthContext);

  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      loginType: 'moderator'
    }
  });

  if (auth.isAuthenticated && auth.accessToken) {
    if(auth?.authUser?.role[0]==4)
    {
       return <Navigate to={privateRoutes.PARTNER} />;
    }
     return <Navigate to={privateRoutes.DASHBOARD} />;
  }

  return (
    <View isLoading={auth.isAuthenticated}>
      <Helmet>
        <title>Login | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth='sm'>
          <form onSubmit={handleSubmit(data => login.mutate(data))}>
            <Box sx={{ mb: 3 }}>
              <Typography
                color='textPrimary'
                variant='h2'
              >
                Sign in
              </Typography>
              <Typography
                color='textSecondary'
                gutterBottom
                variant='body2'
              >
                Sign in on the internal platform
              </Typography>
            </Box>
            <Controller
              control={control}
              name='email'
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                  label='Email Address'
                  margin='normal'
                  name='email'
                  type='email'
                  variant='outlined'
                />
              )}
            />
            <Controller
              control={control}
              name='password'
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  fullWidth
                  label='Password'
                  margin='normal'
                  name='password'
                  type='password'
                  variant='outlined'
                />
              )}
            />
            <Controller
              control={control}
              name='loginType'
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <FormControl margin='normal' fullWidth variant='outlined'>
                    <InputLabel id='loginTypeLabelId'>Login type</InputLabel>
                    <Select
                      labelId='loginTypeLabelId'
                      id='loginType'
                      label='Login type'
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      <MenuItem value='moderator'>Moderator</MenuItem>
                      <MenuItem value='instructor'>Instructor</MenuItem>
                      <MenuItem value='hr'>HR</MenuItem>
                      <MenuItem value='influencer'>Partner</MenuItem>
                    </Select>
                  </FormControl>
                );
              }}
            />
            <Box sx={{ py: 2 }}>
              <ButtonLoader
                color='primary'
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                isLoading={login.isLoading}
              >
                Sign in now
              </ButtonLoader>
            </Box>
            <Typography
              color='textSecondary'
              variant='body1'
            >
              Don&apos;t have an account?
              {' '}
              <Link
                component={RouterLink}
                to='/register'
                variant='h6'
              >
                Forgot password
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </View>
  );
};

export default Login;
