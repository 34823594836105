import InstructorRequestsContext from './InstructorRequestsContext';

import useInstructorRequests from './hooks/useInstructorRequests';

const InstructorRequestsProvider = ({ children }) => {
  const contextData = useInstructorRequests();

  return (
    <InstructorRequestsContext.Provider value={contextData}>
      {children}
    </InstructorRequestsContext.Provider>
  );
};

export default InstructorRequestsProvider;
