import React from 'react';
import moment from 'moment';
import pt from 'prop-types';
import {
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import WithCondition from 'src/components/WithCondition';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { DollarSign } from 'react-feather';

const ViewList = ({ query, queryParams, setQueryParams }) => {

  if (query.isLoading) return (
    <>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation='wave' />
    </>
  );

  if (query.data?.sales?.length === 0) {
    return (
      <Paper elevation={0}>
        <Grid container spacing={2}>
          <Grid md={1} xs={12} item>
            <Typography align='center' variant='h1'><DollarSign size='100px' /></Typography>
          </Grid>
          <Grid flex display='flex' flexDirection='column' justifyContent='center' md={11} xs={12} item>
            <Typography variant='h1'>You have not made any sales yet!</Typography>
            <Typography variant='body' marginY={1} maxWidth='60%'>
              We hope you will break a good sales record very recently.
              You know you are not this generic or basic. You’re a capable guy or gal who has a lot to offer and there
              are genuine reasons behind your decision to apply.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <WithCondition isDisplay={!!query.data}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Kurs adı
            </TableCell>
            <TableCell>
              Məbləğ
            </TableCell>
            <TableCell>
              Tələbə adı
            </TableCell>
            <TableCell>
              Balans istifadəsi
            </TableCell>
            <TableCell>
              Endirim
            </TableCell>
            <TableCell>
              Status
            </TableCell>
            <TableCell>
              Əməliyyat tarixi
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {query.data.sales.slice(0, queryParams.limit).map((sale) => (
            <TableRow
              hover
              key={sale.id}
            >
              <TableCell>
                {sale.course_name}
              </TableCell>
              <TableCell>
                {sale.price} AZN
              </TableCell>
              <TableCell>
                {sale.student_name}
              </TableCell>
              <TableCell>
                {sale.balance_usage}
              </TableCell>
              <TableCell>
                {sale.course_discount}
              </TableCell>
              <TableCell>
                {sale.status}
              </TableCell>
              <TableCell>
                {moment(sale.buyed_at).format('LLLL')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={query.data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </WithCondition>
  );
};

ViewList.propTypes = {
  query: pt.object.isRequired,
  queryParams: pt.object.isRequired
};

export default ViewList;
