import { Button, Form, Input, InputNumber, Modal, message } from 'antd';
import { useEffect } from 'react';
import axiosInstance from 'src/common/config/api';
const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 18
  }
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!'
  },
  number: {
    range: '${label} must be between ${min} and ${max}'
  }
};
/* eslint-enable no-template-curly-in-string */

export default function InfluencerAdd  ({addVisible, setAddVisible, editData, getInfluencer})  {
    const onCancel = () => {
    setAddVisible(false)
}
const [form] = Form.useForm();

useEffect(() => {
  if(editData)
  {
    console.log("first",editData)
    form.setFieldsValue({
      ["fullname"]: editData?.fullname,
      ["email"]: editData?.email,
      ["percent"]: editData?.percent,
  });
  }
}, [addVisible])


const onFinish = (values) => {
  if(editData){
    values.id=editData?.id
    axiosInstance.put('customers/influencer',values).then((res) => {
      setAddVisible(false)
      message.success("Influencer was updated successfully")
  }).catch(err=>{
      message.error(err.message)
  });
  }
  else{
    axiosInstance.post('customers/influencer',values).then((res) => {
      setAddVisible(false)
      message.success("Influencer was added successfully")
  }).catch(err=>{
      message.error("Xəta baş verdi"+ err?.message)
  });
  }
};
return ( 
    <Modal open={addVisible} footer={null} onCancel={onCancel}>
    <Form
    form={form}
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      style={{
        maxWidth: 600
      }}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="fullname"
        label="Full name"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: 'email',
            required:true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: editData?false:true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="percent"
        label="Percent"
        rules={[
          {
            required: true
          }
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          offset: 8
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  </Modal>
)    
}

