import PromoCodesList from './ui/pages/PromoCodesList/ui';
import PromoCodesForm from './ui/pages/PromoCodesForm/ui';

const promoCodesRoutes = [
  { path: '/promo-codes', element: <PromoCodesList /> },
  { path: '/promo-codes/form', element: <PromoCodesForm /> },
  { path: '/promo-codes/form/:id', element: <PromoCodesForm /> }
];

export default promoCodesRoutes;
