import { useContext } from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

import View from 'src/components/view';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { SalesContext } from 'src/views/Sales/common/context';

import SalesListResults from '../blocks/SalesListResults';
import SalesListToolbar from '../blocks/SalesListToolBar';

const SalesListBody = ({isLoading: isRbacLoading}) => {
  const {
    dataFetching: {
      fetchSalesQuery,
      queryParams,
      setQueryParams
    }
  } = useContext(SalesContext);

  return (
    <View >
      <SalesListToolbar queryParams={queryParams} setQueryParams={setQueryParams} />
      <Box sx={{ pt: 3 }}>
        <ErrorBoundary error={fetchSalesQuery.error}>
          <SalesListResults data={fetchSalesQuery.data} />
        </ErrorBoundary>
      </Box>
    </View>
  );
};

SalesListBody.defaultProps = {
  isLoading: false
};

SalesListBody.propTypes = {
  isLoading: pt.bool
};

export default SalesListBody;
