import React from 'react';
import pt from 'prop-types';
import { Grid } from '@material-ui/core';

import ButtonLoader from 'src/components/ButtonLoader';
import WithCondition from 'src/components/WithCondition';

import { formOperations } from 'src/common/constants';

const HRFormActions = ({ isLoading, saveHRForm, isNewRecord}) => {
  return (
    <Grid
      item
      md={12}
      xs={12}
    >
      <ButtonLoader
        color='primary'
        variant='contained'
        isLoading={isLoading}
        onClick={() => saveHRForm(formOperations.SAVE)}
      >Save and exit</ButtonLoader>
      <WithCondition isDisplay={isNewRecord}>
        <ButtonLoader
          color='secondary'
          variant='contained'
          isLoading={isLoading}
          onClick={() => saveHRForm(formOperations.SAVE_AND_CONTINUE_CREATE)}
        >Save and continue create</ButtonLoader>
        <ButtonLoader
          color='inherit'
          variant='contained'
          isLoading={isLoading}
          onClick={() => saveHRForm(formOperations.SAVE_AND_CONTINUE_EDIT)}
        >Save and continue edit</ButtonLoader>
      </WithCondition>
    </Grid>
  );
};

HRFormActions.defaultProps = {
  isLoading: false,
  isNewRecord: true
};

HRFormActions.propTypes = {
  isLoading: pt.bool,
  saveHRForm: pt.func.isRequired,
  isNewRecord: pt.bool.isRequired,
};

export default HRFormActions;
