import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const BlogContentModal = ({ instructorRequest, onClose }) => {

  const handleClose = () => onClose();

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby='instructorRequestModalLabelledBy'
        aria-describedby='instructorRequestModalDescribedBy'
      >
        <DialogTitle id='instructorRequestModalTitle'>Blog Content</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id='instructorRequestModalId'
            tabIndex={-1}
          >
            {instructorRequest}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BlogContentModal;
