import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Switch
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import {
  Edit as EditIcon,
  Trash as TrashIcon
} from 'react-feather';

import { privateRoutes } from 'src/common/mainRoutes';
import WithCondition from 'src/components/WithCondition';
import getInitials from 'src/common/functions/getInitials';

import { CoursesContext } from 'src/views/Courses/common/context';

import { DeleteCourseModal } from '../../components';
import { Button } from 'antd';

const CoursesListResults = ({ data = { count: 0, courses: [] }, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    changeStatus,
    dataFetching: { queryParams, setQueryParams },
    delete: { deleteCourseMutation, deleteCourseHandler }
  } = useContext(CoursesContext);

  const [selectedCourseIds, setSelectedCourseIds] = useState([]);
  const [courseForDelete, setCourseForDelete] = useState(null);

  const handleSelectAll = (event) => {
    let newSelectedCourseIds;

    if (event.target.checked) {
      newSelectedCourseIds = data.courses.map((courseItem) => courseItem.id);
    } else {
      newSelectedCourseIds = [];
    }

    setSelectedCourseIds(newSelectedCourseIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCourseIds.indexOf(id);
    let newSelectedCourseIds = [];

    if (selectedIndex === -1) {
      newSelectedCourseIds = newSelectedCourseIds.concat(selectedCourseIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCourseIds = newSelectedCourseIds.concat(selectedCourseIds.slice(1));
    } else if (selectedIndex === selectedCourseIds.length - 1) {
      newSelectedCourseIds = newSelectedCourseIds.concat(selectedCourseIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCourseIds = newSelectedCourseIds.concat(
        selectedCourseIds.slice(0, selectedIndex),
        selectedCourseIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCourseIds(newSelectedCourseIds);
  };

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedCourseIds.length === data.courses.length}
                    color='primary'
                    indeterminate={
                      selectedCourseIds.length > 0
                      && selectedCourseIds.length < data.courses.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Başlıq
                </TableCell>
                <TableCell>
                  Təlimçi
                </TableCell>
                <TableCell>
                  Tələbə sayı
                </TableCell>
                <TableCell>
                  Kurs saatı
                </TableCell>
                <TableCell>
                  Rating
                </TableCell>
                <TableCell>
                  Dil
                </TableCell>
                <TableCell>
                  Endirim
                </TableCell>
                <TableCell>
                  Qiymət
                </TableCell>
                <TableCell>
                  Quiz sayı
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Əməliyyatlar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.courses.slice(0, queryParams.limit).map((course) => (
                <TableRow
                  hover
                  key={course.id}
                  selected={selectedCourseIds.indexOf(course.id) !== -1}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedCourseIds.indexOf(course.id) !== -1}
                      onChange={(event) => handleSelectOne(event, course.id)}
                      value='true'
                    />
                  </TableCell>
                  <TableCell>
                    {course.title}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={course.avatarUrl}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(course.instructor_name)}
                      </Avatar>
                      <Typography
                        color='textPrimary'
                        variant='body1'
                      >
                        {course.instructor_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {course.student_count}
                  </TableCell>
                  <TableCell>
                    {course.duration}
                  </TableCell>
                  <TableCell>
                    {course.rating_count}
                  </TableCell>
                  <TableCell>
                    {course.language}
                  </TableCell>
                  <TableCell>
                    {course.discount} %
                  </TableCell>
                  <TableCell>
                    {course.price} AZN
                  </TableCell>
                  <TableCell>
                    {course.quiz_count} 
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={!!course.status}
                      onChange={() => changeStatus.mutate(course.id)}
                      color='primary'
                      disabled={changeStatus.isLoading}
                    />
                  </TableCell>
                  <TableCell>
                    <WithCondition isDisplay={!course.status}>
                      <Link to={`${privateRoutes.COURSES_FORM}/${course.id}`}>
                        <IconButton aria-label='edit'>
                          <EditIcon strokeWidth={1.5} size={18} />
                        </IconButton>
                      </Link>
                    </WithCondition>
                    <WithCondition isDisplay={!!course.status}>
                      <IconButton
                        onClick={() => enqueueSnackbar('It is possible to update only inactive courses. Please deactivate the status to update the course.', { variant: 'error' })}
                        aria-label='edit'>
                        <EditIcon strokeWidth={1.5} size={18} />
                      </IconButton>
                    </WithCondition>
                    <IconButton
                      aria-label='delete'
                      onClick={() => {
                        if (!!course.status) {
                          enqueueSnackbar('It is possible to delete only inactive courses. Please deactivate the status to update the course.', { variant: 'error' });
                          return false;
                        }
                        setCourseForDelete(course.id);
                      }}
                      disabled={deleteCourseMutation.isLoading}
                    >
                      <TrashIcon strokeWidth={1.5} size={18} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <WithCondition isDisplay={!!courseForDelete}>
        <DeleteCourseModal
          open={!!courseForDelete}
          closeModal={() => setCourseForDelete(null)}
          onDelete={() => deleteCourseHandler(courseForDelete)}
        />
      </WithCondition>
      <TablePagination
        component='div'
        count={data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

CoursesListResults.defaultProps = {
  data: {
    courses: [],
    count: 0
  }
};

CoursesListResults.propTypes = {
  data: PropTypes.object.isRequired
};

export default CoursesListResults;
