import { rights } from 'src/common/constants';
import useRBAC from 'src/common/hooks/useRBAC';

import { HRCoursesBody } from '../../../partials';
import HRCoursesListBody from './partials/HRCoursesListBody';

const HRCoursesList = () => {
  const { isLoading } = useRBAC({ rights: [rights.HR] });

  return (
    <HRCoursesBody title='Human Resource Courses'>
      <HRCoursesListBody isLoading={isLoading} />
    </HRCoursesBody>
  );
};

export default HRCoursesList;
