import pt from 'prop-types';
import {
  Box,
  Card,
  CardContent, Grid,
  Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import QuizIcon from '@material-ui/icons/Quiz';

const HRCourseDetailToolbar = ({ course, ...rest }) => {
  return (
    <Box {...rest}>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box>
              <Grid
                container
                sx={{ justifyContent: 'space-between' }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundImage: `url(${course.cover_image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    height: '350px',
                    marginBottom: '30px'
                  }}
                >
                </Grid>
              </Grid>
              <Typography align='center' variant='h2'>{course.title}</Typography>
              <Typography align='center' style={{marginTop: '10px'}} variant='h4'>{course.instructor_name}</Typography>
              <Box sx={{ p: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <AccessTimeIcon color='action' />
                    <Typography
                      color='textSecondary'
                      display='inline'
                      sx={{ pl: 1 }}
                      variant='body2'
                    >
                      {course.duration}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <QuizIcon color='action' />
                    <Typography
                      color='textSecondary'
                      display='inline'
                      sx={{ pl: 1 }}
                      variant='body2'
                    >
                      {course.quiz_count}
                      {' '}
                      Quizzes
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

HRCourseDetailToolbar.propTypes = {
  course: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]).isRequired,
    title: pt.string.isRequired,
    cover_image: pt.string.isRequired,
    instructor_name: pt.string.isRequired,
    quiz_count: pt.number.isRequired,
    duration: pt.string.isRequired
  })
};

export default HRCourseDetailToolbar;
