import { useState } from 'react';
import { useQuery } from 'react-query';

import queryKeys from 'src/common/queryKeys';

import stateCreator from '../data/stateCreator';
import { fetchInstructorRequests } from '../../api';

const useInstructorRequests = () => {
  const [queryParams, setQueryParams] = useState(() => stateCreator.createEmptyQuery());

  const fetchInstructorRequestsQuery = useQuery({
    queryKey: [queryKeys.FETCH_INSTRUCTOR_REQUESTS, queryParams],
    queryFn: () => fetchInstructorRequests(queryParams),
    cacheTime: 0,
    staleTime: 0
  });

  return {
    dataFetching: {
      queryParams,
      setQueryParams,
      fetchInstructorRequestsQuery
    }
  };

};

export default useInstructorRequests;
