import { Button, Divider, Select, Spin, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import apiRoutes from 'src/common/api/routes';
import axiosInstance from 'src/common/config/api';

export default function SalesForCategory() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const columns = [
    {
      title: 'Kategoriya adı',
      dataIndex: 'category',
      key: 'category',
      render: (text) => <Tag color="blue">{text}</Tag>
    },
    {
      title: 'Məbləğ',
      dataIndex: 'price',
      key: 'price',
      render: (text) => <Tag color="red">{text} &nbsp;Azn</Tag>
    }
  ];
  useEffect(() => {
    axiosInstance
      .get(apiRoutes.FETCH_MODERATOR_SALES_BY_CATEGORY())
      .then((res) => {
        setData(res?.data);
      });
  }, []);
  return (
    <Spin spinning={loading}>
      <Divider orientation='left'>Kateqoriyalara görə ümumi satışlar</Divider>
      <div>
        <Table
          columns={columns}
          pagination={{
            pageSize: 5
          }}
          dataSource={data}
        />
      </div>
    </Spin>
  );
}
