import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container
} from '@material-ui/core';

import { Button, Col, Form, Input, Radio, Row, Upload, message } from 'antd';
import apiRoutes from 'src/common/api/routes';
import axiosInstance from 'src/common/config/api';
import { useSnackbar } from 'notistack';
const { TextArea } = Input;
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const BannerUpdate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fileList, setFileList] = useState([]);
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  
  const submit = async (values) => {
    let temp;
    if (values?.options) {
      temp = values?.options;
    } else {
      temp = 'banner_log_in';
    }
    const base64 = await getBase64(values?.photo[0]?.originFileObj);
    let data={
      header:values?.header,
      link:values?.link,
      filename:values?.photo[0]?.name,
      image:base64.split(",")[1],
      title:values?.title
    }
    axiosInstance
      .post(apiRoutes.FETCH_MODERATOR_BANNER_UPDATE() + temp, data)
      .then((res) => {
        enqueueSnackbar('Banner uğurla dəyişdirildi.', { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar('Xəta baş verdi.', { variant: 'error' });
      });
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  return (
    <Card>
      <CardHeader subheader="Update banner" title="Banner" />
      <CardContent>
        <Box
          sx={{
            backgroundColor: 'white',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth="lg" style={{ background: 'white' }}>
            <Box sx={{ pt: 3 }}>
              <Form layout="vertical" onFinish={submit}>
                <Row>
                  <Col span={10} offset={4}>
                    <Form.Item name="title" label="description">
                      <TextArea rows={4} style={{ maxWidth: '400px' }} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Upload"
                      name="photo"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        action="/upload.do"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleChange}
                        maxCount={1}
                      >
                        <button
                          style={{
                            border: 0,
                            background: 'none'
                          }}
                          type="button"
                        >
                          <PlusOutlined />
                          <div
                            style={{
                              marginTop: 8
                            }}
                          >
                            Upload
                          </div>
                        </button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={10} offset={4}>
                    <Form.Item name="header" label="Başlıq">
                      <Input style={{ maxWidth: '400px' }} />
                    </Form.Item>
                  </Col>
               
                  <Col span={10} offset={4}>
                    <Form.Item
                      name="options"
                      label="Dəyişdirmək istədiyiniz banner"
                    >
                      <Radio.Group defaultValue="banner_log_in">
                        <Radio value="banner_log_in"> Banner Login </Radio>
                        <Radio value="banner_without_log_in">
                          Banner without login
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={14} offset={4}>
                    <Form.Item name="link" label="Link">
                      <Input style={{ maxWidth: '400px' }} />
                    </Form.Item>
                  </Col>
                  <Col span={14} offset={4}>
                    <Form.Item name="buttonText" label="Button Text">
                      <Input style={{ maxWidth: '400px' }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Col span={14} offset={4}>
                  <Form.Item>
                    <Button htmlType="submit ">Təsdiqlə</Button>
                  </Form.Item>
                </Col>
              </Form>
            </Box>
          </Container>
        </Box>
      </CardContent>
    </Card>
  );
};
export default BannerUpdate;
