import InstructorsList from './ui/pages/InstructorsList/ui';
import InstructorForm from './ui/pages/InstructorForm/ui';

const instructorsRoutes = [
  { path: '/instructors', element: <InstructorsList /> },
  { path: '/instructors/form', element: <InstructorForm /> },
  { path: '/instructors/form/:id', element: <InstructorForm /> }
];

export default instructorsRoutes;
