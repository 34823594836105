import { useContext, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Autocomplete, Grid, TextField, Button, CardHeader, Divider } from '@material-ui/core';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { CoursesContext } from '../../../../../../common/context';
import { languages, steps } from '../../../common/constants';

import styles from './CourseInformationSteps.module.css';
import WithCondition from '../../../../../../../../components/WithCondition';

const CourseInformationStep = () => {
  const editorRef = useRef(null);
  const {
    isNewRecord,
    additionallyQueries: { fetchInstructors },
    create: { stepsFormController, stepsForm, stepsFormErrors }
  } = useContext(CoursesContext);

  const onChangeHandler = ({ fieldName, fieldValue }) => {
    stepsFormController({
      stepName: steps.COURSE_INFORMATION,
      fieldObject: { fieldName, fieldValue }
    });
  };

  const instructorsProps = {
    options: fetchInstructors.data || [],
    getOptionLabel: (option) => option.name,
    onChange: (e, T) => onChangeHandler({ fieldName: 'instructor', fieldValue: T })
  };

  const languagesProps = {
    options: languages,
    getOptionLabel: (option) => option.name,
    onChange: (e, T) => onChangeHandler({ fieldName: 'language', fieldValue: T })

  };

  const formData = stepsForm[steps.COURSE_INFORMATION];
  const formErrors = stepsFormErrors[steps.COURSE_INFORMATION];

  return (
    <>
      <CardHeader subheader='Course general information definition' title='Create course information' />
      <Divider />
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <Autocomplete
            {...languagesProps}
            debug='true'
            value={formData.language}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Choose Language'
                margin='normal'
                error={formErrors.language.error}
                helperText={formErrors.language.message}
              />
            )}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <Autocomplete
            {...instructorsProps}
            debug='true'
            loading={fetchInstructors.isLoading}
            disabled={fetchInstructors.isLoading}
            loadingText={'Loading...'}
            value={formData.instructor}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Choose Instructor'
                margin='normal'
                error={formErrors.instructor.error}
                helperText={formErrors.instructor.message}
              />
            )}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            id='title'
            label='Title'
            variant='outlined'
            fullWidth
            name='title'
            value={formData.title}
            error={formErrors.title.error}
            helperText={formErrors.title.message}
            onChange={({ target: { name, value } }) => onChangeHandler({ fieldName: name, fieldValue: value })}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            id='subtitle'
            label='Subtitle'
            variant='outlined'
            fullWidth
            name='subtitle'
            value={formData.subtitle}
            error={formErrors.subtitle.error}
            helperText={formErrors.subtitle.message}
            onChange={({ target: { name, value } }) => onChangeHandler({ fieldName: name, fieldValue: value })}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            id='promo_video'
            label='Promotional video url'
            variant='outlined'
            fullWidth
            name='promo_video'
            value={formData.promo_video}
            error={formErrors.promo_video.error}
            helperText={formErrors.promo_video.message}
            onChange={({ target: { name, value } }) => onChangeHandler({ fieldName: name, fieldValue: value })}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            id='price'
            label='AZN'
            variant='outlined'
            type='number'
            fullWidth
            name='price'
            value={formData.price}
            error={formErrors.price.error}
            helperText={formErrors.price.message}
            onChange={({ target: { name, value } }) => onChangeHandler({ fieldName: name, fieldValue: value })}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            id='discount'
            label='Discount'
            variant='outlined'
            type='number'
            fullWidth
            name='discount'
            value={formData.discount}
            onChange={({ target: { name, value } }) => onChangeHandler({ fieldName: name, fieldValue: value })}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <Button
            variant='contained'
            component='label'
            color={formErrors.cover.error ? 'secondary' : 'primary'}
            startIcon={<CloudUploadIcon />}
          >
            Cover Image
            <input
              type='file'
              name='cover'
              hidden
              onChange={({ target: { name, files } }) => onChangeHandler({ fieldName: name, fieldValue: files[0] })}
            />
          </Button>
          <WithCondition isDisplay={!isNewRecord}>
            <a className={styles.uploadedCover} href={formData.uploadedCover} target='_blank'>See cover</a>
          </WithCondition>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <Editor
            onEditorChange={(editorContent) => onChangeHandler({ fieldName: 'description', fieldValue: editorContent })}
            onInit={(evt, editor) => editorRef.current = editor}
            value={formData.description}
            apiKey='yyh0vrmw7mw24cafluttia9lllkxg6mdq1ywjpffuqee2mwb'
            init={{
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CourseInformationStep;
