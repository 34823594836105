import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import {
  Avatar,
  Box, Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {
  Edit as EditIcon,
  Trash as TrashIcon
} from 'react-feather';

import { privateRoutes } from 'src/common/mainRoutes';
import getInitials from 'src/common/functions/getInitials';
import WithCondition from 'src/components/WithCondition';

import { InstructorContext } from 'src/views/Instructors/common/context';
import { DeleteInstructorModal, SalesListModal } from '../../components';
import { SocialAccountsModal } from '../../../../../components';

const InstructorsListResults = ({ data = { count: 0, instructors: [] }, ...rest }) => {
  const {
    dataFetching: { queryParams, setQueryParams },
    delete: {
      deleteInstructorHandler,
      deleteInstructorMutation
    },
    instructorSales
  } = useContext(InstructorContext);

  const [socialAccountModalData, setSocialAccountModalData] = useState(null);

  const [selectedInstructorIds, setSelectedInstructorIds] = useState([]);
  const [instructorForDelete, setInstructorForDelete] = useState(null);

  const handleSelectAll = (event) => {
    let newSelectedInstructorIds;

    if (event.target.checked) {
      newSelectedInstructorIds = data.instructors.map(instructorItem => instructorItem.id);
    } else {
      newSelectedInstructorIds = [];
    }

    setSelectedInstructorIds(newSelectedInstructorIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedInstructorIds.indexOf(id);
    let newSelectedInstructorIds = [];

    if (selectedIndex === -1) {
      newSelectedInstructorIds = newSelectedInstructorIds.concat(selectedInstructorIds, id);
    } else if (selectedIndex === 0) {
      newSelectedInstructorIds = newSelectedInstructorIds.concat(selectedInstructorIds.slice(1));
    } else if (selectedIndex === selectedInstructorIds.length - 1) {
      newSelectedInstructorIds = newSelectedInstructorIds.concat(selectedInstructorIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedInstructorIds = newSelectedInstructorIds.concat(
        selectedInstructorIds.slice(0, selectedIndex),
        selectedInstructorIds.slice(selectedIndex + 1)
      );
    }

    setSelectedInstructorIds(newSelectedInstructorIds);
  };

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedInstructorIds.length === data.instructors.length}
                    color='primary'
                    indeterminate={
                      selectedInstructorIds.length > 0
                      && selectedInstructorIds.length < data.instructors.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Ad
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Peşə
                </TableCell>
                <TableCell>
                  Sosial media hesabı
                </TableCell>
                <TableCell>
                  Üzvolma tarixi
                </TableCell>
                <TableCell>
                  Tələbə sayı
                </TableCell>
                <TableCell>
                  Ümumi satış məbləği
                </TableCell>
                <TableCell>
                  Satışlar
                </TableCell>
                <TableCell>
                  Əməliyyatlar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.instructors.slice(0, queryParams.limit).map((instructor) => (
                <TableRow
                  hover
                  key={instructor.id}
                  selected={selectedInstructorIds.indexOf(instructor.id) !== -1}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedInstructorIds.indexOf(instructor.id) !== -1}
                      onChange={(event) => handleSelectOne(event, instructor.id)}
                      value='true'
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={instructor.photo}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(instructor.fullname)}
                      </Avatar>
                      <Typography
                        color='textPrimary'
                        variant='body1'
                      >
                        {instructor.fullname}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {instructor.email}
                  </TableCell>
                  <TableCell>
                    {instructor.profession}
                  </TableCell>
                  <TableCell>
                    <Button
                      size='small'
                      onClick={() => setSocialAccountModalData(instructor.socialAccounts)}
                    >
                      Social accounts
                    </Button>
                  </TableCell>
                  <TableCell>
                    {moment(instructor.created_at).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {instructor.total_student_count}
                  </TableCell>
                  <TableCell>
                    {instructor.total_price} AZN
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => instructorSales.setInstructor(instructor)}
                      disabled={instructorSales.fetchInstructorSalesQuery.isLoading}
                    >
                      <AttachMoneyIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Link to={`${privateRoutes.INSTRUCTORS_FORM}/${instructor.id}`}>
                      <IconButton aria-label='edit'>
                        <EditIcon strokeWidth={1.5} size={18} />
                      </IconButton>
                    </Link>
                    <IconButton
                      onClick={() => setInstructorForDelete(instructor.id)}
                      disabled={deleteInstructorMutation.isLoading}
                      aria-label='delete'
                    >
                      <TrashIcon strokeWidth={1.5} size={18} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <WithCondition isDisplay={!!instructorForDelete}>
        <DeleteInstructorModal
          open={!!instructorForDelete}
          closeModal={() => setInstructorForDelete(null)}
          onDelete={() => deleteInstructorHandler(instructorForDelete)}
        />
      </WithCondition>
      <WithCondition isDisplay={!!instructorSales.instructor}>
        <SalesListModal instructorSales={instructorSales} />
      </WithCondition>
      <WithCondition isDisplay={!!socialAccountModalData}>
        <SocialAccountsModal
          isReadOnly={true}
          readOnlyData={socialAccountModalData}
          onClose={() => setSocialAccountModalData(null)}
        />
      </WithCondition>
    </Card>
  );
};

InstructorsListResults.defaultProps = {
  data: {
    instructors: [],
    count: 0
  }
};

InstructorsListResults.propTypes = {
  data: PropTypes.object.isRequired
};

export default InstructorsListResults;
