import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { UserQuizzesModalTable } from '../index';

const UserQuizzesModal = ({ quizzes, onClose }) => {
  if (!quizzes) return null;

  return (
    <div>
      <Dialog maxWidth='md' open={!!quizzes} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Quiz nəticələri</DialogTitle>
        <DialogContent>
          <UserQuizzesModalTable quizzes={quizzes} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Geri
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserQuizzesModal;
