import axios from 'axios';

const detectApiUrl = () => {
  const { REACT_APP_ENVIRONMENT } = process.env;

  switch (REACT_APP_ENVIRONMENT) {
    case 'development':
      return 'https://admin-api.safavy.org/';
    case 'beta':
      return 'http://localhost:9090/';
    case 'production':
      return 'https://admin-api.safavy.org/';
    default:
      return '';
  }
};

const axiosInstance = axios.create({
  baseURL: detectApiUrl(),
  timeout: 100000,

  
  headers: { 'X-Authorization': 'true' }
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  });

export default axiosInstance;
