import moment from 'moment';
import pt from 'prop-types';
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import { DollarSign } from 'react-feather';

import WithCondition from 'src/components/WithCondition';
import getInitials from '../../../../../../../../../../common/functions/getInitials';

const ViewList = ({ query, queryParams, setQueryParams }) => {

  if (query.isLoading) return (
    <>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation='wave' />
    </>
  );

  if (query.data?.courses?.length === 0) {
    return (
      <Paper elevation={0}>
        <Grid container spacing={2}>
          <Grid md={1} xs={12} item>
            <Typography align='center' variant='h1'><DollarSign size='100px' /></Typography>
          </Grid>
          <Grid flex display='flex' flexDirection='column' justifyContent='center' md={11} xs={12} item>
            <Typography variant='h1'>You have not made any courses yet!</Typography>
            <Typography variant='body' marginY={1} maxWidth='60%'>
              We hope you will soon take advantage of our excellent courses and build your career with our excellent
              courses! From the cradle to the grave - Learning is Good!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <WithCondition isDisplay={!!query.data}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Kurs adı
            </TableCell>
            <TableCell>
              Məbləğ
            </TableCell>
            <TableCell>
              Balans istifadəsi
            </TableCell>
            <TableCell>
              Əməliyyat tarixi
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {query.data.courses.slice(0, queryParams.limit).map((course) => (
            <TableRow
              hover
              key={course.id}
            >
              <TableCell>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <Avatar
                    src={course.cover_image}
                    sx={{ mr: 2 }}
                  >
                    {getInitials(course.fullname)}
                  </Avatar>
                  <Typography
                    color='textPrimary'
                    variant='body1'
                  >
                    {course.title}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                {course.price} AZN
              </TableCell>
              <TableCell>
                {course.balance_usage}
              </TableCell>
              <TableCell>
                {moment(course.buyed_at).format('DD/MM/YYYY')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={query.data.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={queryParams.page}
        rowsPerPage={queryParams.limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </WithCondition>
  );
};

ViewList.propTypes = {
  query: pt.object.isRequired,
  queryParams: pt.object.isRequired
};

export default ViewList;
