import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import { SalesContext } from 'src/views/Sales/common/context';
import moment from 'moment';

const SalesListResults = ({ data = { count: 0, sales: [] }, ...rest }) => {
  const {
    dataFetching: {
      queryParams,
      setQueryParams
    }
  } = useContext(SalesContext);

  const [selectedSaleIds, setSelectedSaleIds] = useState([]);

  const handleSelectAll = (event) => {
    let newSelectedSaleIds;

    if (event.target.checked) {
      newSelectedSaleIds = data.sales.map((saleItem) => saleItem.id);
    } else {
      newSelectedSaleIds = [];
    }

    setSelectedSaleIds(newSelectedSaleIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedSaleIds.indexOf(id);
    let newSelectedSaleIds = [];

    if (selectedIndex === -1) {
      newSelectedSaleIds = newSelectedSaleIds.concat(selectedSaleIds, id);
    } else if (selectedIndex === 0) {
      newSelectedSaleIds = newSelectedSaleIds.concat(selectedSaleIds.slice(1));
    } else if (selectedIndex === selectedSaleIds.length - 1) {
      newSelectedSaleIds = newSelectedSaleIds.concat(selectedSaleIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedSaleIds = newSelectedSaleIds.concat(
        selectedSaleIds.slice(0, selectedIndex),
        selectedSaleIds.slice(selectedIndex + 1)
      );
    }

    setSelectedSaleIds(newSelectedSaleIds);
  };

  const handleLimitChange = ({ target }) => {
    setQueryParams({ ...queryParams, limit: target.value });
  };

  const handlePageChange = (event, page) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card style={{overflow: 'auto'}} {...rest}>
      <Box sx={{ minWidth: 1050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  checked={selectedSaleIds.length === data.sales.length}
                  color='primary'
                  indeterminate={
                    selectedSaleIds.length > 0
                    && selectedSaleIds.length < data.sales.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>
                Təlimçi
              </TableCell>
              <TableCell>
                Kurs adı
              </TableCell>
              <TableCell>
                Məbləğ
              </TableCell>
              <TableCell>
                Tələbə adı
              </TableCell>
              <TableCell>
                Tələbə e-poçtu
              </TableCell>
              <TableCell>
                Balans istifadəsi
              </TableCell>
              <TableCell>
                Endirim
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Əməliyyat tarixi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.sales.slice(0, queryParams.limit).map((sale) => (
              <TableRow
                hover
                key={sale.id}
                selected={selectedSaleIds.indexOf(sale.id) !== -1}
              >
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selectedSaleIds.indexOf(sale.id) !== -1}
                    onChange={(event) => handleSelectOne(event, sale.id)}
                    value='true'
                  />
                </TableCell>
                <TableCell>
                  {sale.instructor}
                </TableCell>

                <TableCell>
                  {sale.course_name}
                </TableCell>
                <TableCell>
                  {sale.price} AZN
                </TableCell>
                <TableCell>
                  {sale.student_name}
                </TableCell>
                <TableCell>
                  {sale.student_email}
                </TableCell>
                <TableCell>
                  {sale.balance_usage}
                </TableCell>
                <TableCell>
                  {sale.course_discount} %
                </TableCell>
                <TableCell>
                  {sale.status}
                </TableCell>
                <TableCell>
                  {moment(sale.buyed_at).format('DD/MM/YYYY')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={data.count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={queryParams.page}
          rowsPerPage={queryParams.limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Box>
    </Card>
  );
};

SalesListResults.defaultProps = {
  data: {
    sales: [],
    count: 0
  }
};

SalesListResults.propTypes = {
  data: PropTypes.object.isRequired
};

export default SalesListResults;
